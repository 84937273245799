import { useState, useEffect, useContext } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Eye, EyeOff, User } from 'lucide-react'
import { AuthContext } from '../../contexts/authContext'
import { useHistory, useLocation } from 'react-router-dom';
import { completeNewPasswordChallenge, signInWithEmail } from '@/libs/cognito'
import { useCustomToast } from '@/components/ui/toaster'
import { getReviewers, getReviewerTeam, updateReviewerInfo, updateUserLastLogin } from '@/libs/backend'
import { Label } from '@/components/ui/label'
import { UserRole, UserTeam } from '@/contexts/authContext'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { CircularProgress } from '@mui/material'

interface PasswordRequirementsProps {
    isVisible: boolean;
    passwordRequirements: {
        minLength: boolean;
        hasUpperCase: boolean;
        hasLowerCase: boolean;
        hasNumber: boolean;
        hasSpecialChar: boolean;
    };
}

export const PasswordRequirements = ({ isVisible, passwordRequirements }: PasswordRequirementsProps) => {
    if (!isVisible) return null;

    return (
        <div className="text-sm space-y-1 mt-2">
            <ul className="space-y-1 text-gray-600">
                <li className={`flex items-center gap-2 ${passwordRequirements.minLength ? "text-green-600" : "text-red-600"}`}>
                    {passwordRequirements.minLength ? "✓" : "✗"} Minimum 8 characters
                    </li>
                    <li className={`flex items-center gap-2 ${passwordRequirements.hasUpperCase ? "text-green-600" : "text-red-600"}`}>
                        {passwordRequirements.hasUpperCase ? "✓" : "✗"} At least one uppercase letter
                    </li>
                    <li className={`flex items-center gap-2 ${passwordRequirements.hasLowerCase ? "text-green-600" : "text-red-600"}`}>
                        {passwordRequirements.hasLowerCase ? "✓" : "✗"} At least one lowercase letter
                    </li>
                    <li className={`flex items-center gap-2 ${passwordRequirements.hasNumber ? "text-green-600" : "text-red-600"}`}>
                        {passwordRequirements.hasNumber ? "✓" : "✗"} At least one number
                    </li>
                    <li className={`flex items-center gap-2 ${passwordRequirements.hasSpecialChar ? "text-green-600" : "text-red-600"}`}>
                        {passwordRequirements.hasSpecialChar ? "✓" : "✗"} At least one special character
                    </li>
                </ul>
        </div>
    );
};


export const ResetPassword = () => {
    // username states
    const [username, setUsername] = useState('')
    const [userNameDisabled, setUserNameDisabled] = useState(false)
    const [isNewUser, setIsNewUser] = useState(false)

    // password states
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    // password visibility states
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    // !
    const [showPasswords, setShowPasswords] = useState({
        current: false,
        new: false,
        confirm: false
    });

    // Add new handlers
    const handlePasswordChange = (field: string, value: string) => {
        switch(field) {
            case 'currentPassword':
                setOldPassword(value);
                break;
            case 'newPassword':
                setNewPassword(value);
                checkPasswordRequirements(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
        }
    };
    const handleTogglePassword = (field: string) => {
        setShowPasswords(prev => ({
            ...prev,
            [field as keyof typeof prev]: !prev[field as keyof typeof prev]
        }));
    };
    // password validation
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    })

    // focus states for password fields
    const [newPasswordFocused, setNewPasswordFocused] = useState(false)
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false)

    const checkPasswordRequirements = (password: string) => {
        setPasswordRequirements({
            minLength: password.length >= 8,
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
        })
    }

    // user meta data
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [team, setTeam] = useState('')
    const [role, setRole] = useState('')

    const [initialUserAttributes, setInitialUserAttributes] = useState<any>({})
    
    // * already existing
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const auth = useContext(AuthContext);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false)


    // location state
    const location = useLocation<{challengeSession: any, comingFrom: string}>();
    const comingFrom = location.state?.comingFrom
    const { showToast } = useCustomToast()

    const setFormData = () => {
        const username = localStorage.getItem('tempUsername')
        setUsername(username || '')
        
        const password = localStorage.getItem('tempPassword')
        
        if (comingFrom === 'autoAuth') {
            setIsNewUser(true)
            // setUserNameDisabled(true)
            setOldPassword(password || '')
        }


        const userAttributes = JSON.parse(localStorage.getItem('userAttributes') || '{}');
        if (userAttributes) {
            setFirstName(userAttributes.given_name)
            setLastName(userAttributes.family_name)
            setMiddleName(userAttributes.middle_name)
            setRole(userAttributes['custom:role'])
            setInitialUserAttributes(userAttributes)
            fetchReviewerTeam(userAttributes.email)
        }
    }

    const fetchReviewerTeam = async (username: string) => {
        const reviewerTeam = await getReviewerTeam(username, auth.sessionInfo?.accessToken || '')
        setTeam(reviewerTeam.data)
    }
    
    useEffect(() => {
        setFormData()
    }, [])
    
    const handleSetPassword = async () => {

        setIsLoading(true)
        try {
            const trimmedConfirmPassword = confirmPassword.trim();

            const cognitoSignIn = await authContext.signInWithEmail(username, oldPassword);
            await completeNewPasswordChallenge(cognitoSignIn, trimmedConfirmPassword);
            const session = await authContext.getSession()
            await updateUserLastLogin(session.accessToken.jwtToken)

            // remove temp items
            localStorage.removeItem('tempUsername');
            localStorage.removeItem('tempPassword');
            localStorage.removeItem('challengeSession');

            const currentUserAttributes = {
                given_name: firstName,
                family_name: lastName,
                middle_name: middleName,
                'custom:role': role,
            };

            const hasAttributesChanged = Object.keys(currentUserAttributes).some(key => 
                currentUserAttributes[key as keyof typeof currentUserAttributes] !== initialUserAttributes[key as keyof typeof initialUserAttributes]
            );

            if (hasAttributesChanged) {
                console.log('attributes changed', currentUserAttributes)
                // const updateReviewerInfoResponse = await updateReviewerInfo(currentUserAttributes, session.accessToken);
                // console.log('updateReviewerInfoResponse', updateReviewerInfoResponse)
            }
            
            // showToast({
            //     type: 'warning',
            //     title: 'Password has been set successfully',
            //     description: 'You can now sign in with your new password.'
            // })
            window.location.href = '/dashboard';
        } catch (error) {
            console.error('Error setting password:', error);
        } finally {
            setIsLoading(false)
        }
    }

    const isValid = 
        firstName.length > 0 &&
        lastName.length > 0 &&
        role.length > 0 &&
        username.length > 0 &&
        (isNewUser || oldPassword.length > 0) && 
        newPassword.length > 0 && 
        confirmPassword.length > 0 &&
        newPassword === confirmPassword && 
        Object.values(passwordRequirements).every(requirement => requirement === true)
    
    return (
        <>
            {isLoading && (
                <div 
                    className="fixed inset-0 flex items-center justify-center z-50"
                    style={{ 
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        pointerEvents: 'none'
                    }}
                >
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center" style={{ pointerEvents: 'auto' }}>
                        <CircularProgress className="h-12 w-12 mb-4" />
                        <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                            Setting password...
                        </p>
                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                            Please wait while we update your credentials
                        </p>
                    </div>
                </div>
            )}
            <div className="min-h-screen flex flex-col bg-background">
            <main className="flex-1 flex items-center justify-center p-4">

                <Card className="w-full max-w-2xl mx-auto">
                    <CardHeader className="space-y-1">
                        <CardTitle className="text-2xl font-bold text-center">
                            {isNewUser ? "Set New Password" : "Change Password"}
                        </CardTitle>
                        <CardDescription className="text-center">
                            Please fill in your information and choose a secure password
                        </CardDescription>
                    </CardHeader>

                    <CardContent className="space-y-4">
                        <div className="space-y-2">
                            <div className="grid grid-cols-3 gap-4">
                                <div>
                                    <Label htmlFor="firstName">First Name</Label>
                                    {/* <div className="relative"> */}
                                        <Input
                                            id="firstName"
                                            type="text"
                                            // placeholder="First name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            // className="pl-10"
                                        />
                                        {/* <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" /> */}
                                    {/* </div> */}
                                </div>

                                <div>
                                    <Label htmlFor="middleName">Middle Name</Label>
                                    {/* <div className="relative"> */}
                                        <Input
                                            id="middleName"
                                            type="text"
                                            // placeholder="Middle name"
                                            value={middleName}
                                            onChange={(e) => setMiddleName(e.target.value)}
                                            // className="pl-10"
                                        />
                                        {/* <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" /> */}
                                    {/* </div> */}
                                </div>

                                <div>
                                    <Label htmlFor="lastName">Last Name</Label>
                                    {/* <div className="relative"> */}
                                        <Input
                                            id="lastName"
                                            type="text"
                                            // placeholder="Last name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            // className="pl-10"
                                        />
                                        {/* <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" /> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="space-y-2">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <Label htmlFor="team">Team</Label>
                                    <div className="col-span-3">
                                        <Select 
                                            value={team}
                                            onValueChange={(value) => setTeam(value as UserTeam)}
                                            disabled={true}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select Team" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.values(UserTeam).map((team) => (
                                                    <SelectItem key={team} value={team}>{team.charAt(0).toUpperCase() + team.slice(1)}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>

                                <div>
                                    <Label htmlFor="role">Role</Label>

                                    <div className="col-span-3">
                                        <Select 
                                            value={role}
                                            onValueChange={(value) => setRole(value as UserRole)}
                                            disabled={true}
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select Role" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {Object.values(UserRole).map((role) => (
                                                    <SelectItem key={role} value={role}>{role.charAt(0).toUpperCase() + role.slice(1)}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="username">Username</Label>
                            {/* <div className="relative"> */}
                                <Input
                                id="username"
                                type="text"
                                // placeholder="Enter your username"
                                value={username}
                                // onChange={(e) => setUsername(e.target.value)}
                                disabled={userNameDisabled}
                                // className="pl-10"
                                />
                                {/* <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" /> */}
                            {/* </div> */}
                        
                        </div>
                        {!isNewUser && (
                            <div className="space-y-2">
                                <Label htmlFor="current-password">Current Password</Label>
                                <div className="relative">
                                <Input
                                    id="current-password"
                                    type={showOldPassword ? "text" : "password"}
                                    // placeholder="Enter current password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    className="pr-10"
                                />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="icon"
                                    className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                >
                                    {showOldPassword ? (
                                        <EyeOff className="h-4 w-4 text-gray-500" />
                                        ) : (
                                            <Eye className="h-4 w-4 text-gray-500" />
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}

                        <div className="space-y-2">
                            <Label htmlFor="new-password">New Password</Label>
                            <div className="relative">
                                <Input
                                    id="new-password"
                                    type={showNewPassword ? "text" : "password"}
                                    // placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                        checkPasswordRequirements(e.target.value)
                                    }}
                                    onFocus={() => setNewPasswordFocused(true)}
                                    onBlur={() => setNewPasswordFocused(false)}
                                    className="pr-10"
                                />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="icon"
                                    className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                >
                                    {showNewPassword ? (
                                        <EyeOff className="h-4 w-4 text-gray-500" />
                                    ) : (
                                        <Eye className="h-4 w-4 text-gray-500" />
                                    )}
                                </Button>
                                
                            </div>
                        </div>
                        <PasswordRequirements 
                            isVisible={newPassword.length > 0}
                            passwordRequirements={passwordRequirements}
                        />
                        
                        <div className="space-y-2">
                            <Label htmlFor="confirm-password">Confirm New Password</Label>
                            <div className="relative">
                                <Input
                                    id="confirm-password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    // placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        setConfirmPassword(e.target.value)
                                        checkPasswordRequirements(e.target.value)
                                    }}
                                    onFocus={() => setConfirmPasswordFocused(true)}
                                    onBlur={() => setConfirmPasswordFocused(false)}
                                    className="pr-10"
                                />
                                <Button
                                    type="button"
                                    variant="ghost"
                                    size="icon"
                                    className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                {showConfirmPassword ? (
                                    <EyeOff className="h-4 w-4 text-gray-500" />
                                ) : (
                                    <Eye className="h-4 w-4 text-gray-500" />
                                )}
                                </Button>
                            </div>
                            {confirmPassword && (
                                <p className={`text-sm mt-1 ${newPassword === confirmPassword ? 'text-green-600' : 'text-red-600'}`}>
                                    {newPassword === confirmPassword ? '✓ Passwords match' : '✗ Passwords do not match'}
                                </p>
                            )}
                        </div>
                    </CardContent>

                    <CardFooter className="flex flex-col space-y-2">
                        <Button 
                            onClick={handleSetPassword} 
                            disabled={!isValid}
                            className="w-full"
                        >
                            {isNewUser ? "Set Password" : "Change Password"}
                        </Button>
                        <Button 
                            variant="outline" 
                            onClick={() => history.push('/')}
                            className="w-full"
                        >
                            Cancel
                        </Button>
                    </CardFooter>
                </Card>
            </main>
            </div>
        </>
    )
}