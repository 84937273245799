import React from 'react'
import './App.css'

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn, AuthStatus } from './contexts/authContext'
import SignIn from './routes/auth/signIn'
import SignUp from './routes/auth/signUp'
import VerifyCode from './routes/auth/verify'
import RequestCode from './routes/auth/requestCode'
import ForgotPassword from './routes/auth/forgotPassword'
import ChangePassword from './routes/auth/changePassword'
import Landing from './routes/landing'
import Home from './routes/home'
import Result from './routes/result'
import NavBar, { SimpleNavBar } from './components/navBar'
import Footer from './components/footer'
import HistoricalView from './routes/historicalViews'
import CaseTracking from './routes/caseTracking'
import { Theme } from '@mui/material/styles';
import Dashboard from './routes/dashboard';
import { useAuth } from './contexts/useAuth'
import { UserRole } from './contexts/authContext'
import { Loader2 } from 'lucide-react'
import UserManagement from './routes/userManagment'
import { ResetPassword } from '@/routes/auth/resetPassword'
import { AutoAuthenticator } from './routes/auth/autoAuthenticator'
import { useInactivityLogout } from './hooks/userInactivityLogout'
import { Toaster } from './components/ui/toaster'
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

let lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#054D5E',
    },
    secondary: {
      main: '#ffff69',
    },
  },
})

const ResetPasswordRoute: React.FunctionComponent = () => (
  <Box display="flex" flexDirection="column" minHeight="100vh">
    <Router>
      <SimpleNavBar />
      <Box flex={1}>
        <Switch>
          {/* <Route path="/auth" component={AutoAuthenticator} /> */}
          <Route path="/resetpassword" component={ResetPassword} />
        </Switch>
      </Box>
      <Footer />
    </Router>
  </Box>
)

const InactivityWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useInactivityLogout(30);
  return <>{children}</>;
};

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }: any) => {
  const auth = useAuth();
  
  if (auth.authStatus === AuthStatus.SignedIn && !auth.role) {
    return <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center">
              <Loader2 className="h-12 w-12 animate-spin text-primary mb-4" />
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">Loading...</p>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">Please wait while we verify your access</p>
            </div>
          </div>
  }
  
  return (
    <Route
      {...rest}
      render={(props) => {
        const isSignedIn = auth.authStatus === AuthStatus.SignedIn;
        const hasRole = allowedRoles.includes(auth.role);

        if (!hasRole || !isSignedIn) {
          console.log('Role check failed. Redirecting to unauthorized.');
          return <Redirect to="/unauthorized" />
        }
        return (
          <InactivityWrapper>
            <Component {...props} />
          </InactivityWrapper>
        )
      }}
    />
  );
};


const SignInRoute: React.FunctionComponent = () => (
  <Box display="flex" flexDirection="column" minHeight="100vh">
    <Router>
      <Box flex={1}>
        <Switch>
          <Route path="/auth" component={AutoAuthenticator} />
          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={SignUp} />
          <Route path="/verify" component={VerifyCode} />
          <Route path="/requestcode" component={RequestCode} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ResetPasswordRoute} />
          <Route path="/" component={Landing} />
        </Switch>
      </Box>
      <Footer />
    </Router>
  </Box>
)

const MainRoute: React.FunctionComponent = () => (
  <Box display="flex" flexDirection="column" minHeight="100vh">
    <Router>
      <NavBar />
      <Box flex={1}>
        <Switch>
          {/* <Route path="/changepassword" component={ChangePassword} />
          <Route path="/results" component={Result} />
          <Route path="/historical" component={HistoricalView} />
          <Route path="/caseTracking" component={CaseTracking} />
          <Route path="/send" component={Home} />
          <Route path="/" component={Dashboard} /> */}

          <ProtectedRoute 
            path="/changepassword" 
            component={ChangePassword} 
            allowedRoles={[UserRole.Admin, UserRole.Nurse]} 
          />
          <ProtectedRoute 
            path="/send" 
            component={Home} 
            allowedRoles={[UserRole.Admin, UserRole.Nurse]} 
          />

          <ProtectedRoute 
            path="/results" 
            component={Result} 
            allowedRoles={[UserRole.Admin, UserRole.Nurse]} 
          />
          <ProtectedRoute 
            path="/historical" 
            component={HistoricalView} 
            allowedRoles={[UserRole.Nurse]} 
          />
          <ProtectedRoute 
            path="/caseTracking" 
            component={CaseTracking} 
            allowedRoles={[UserRole.Admin, UserRole.Nurse]} 
          />

          <ProtectedRoute 
            path="/userManagement" 
            component={UserManagement} 
            allowedRoles={[UserRole.Admin]} 
          />

          <ProtectedRoute 
            path="/" 
            component={Dashboard} 
            allowedRoles={[UserRole.Admin, UserRole.Nurse]} 
          />


          
        </Switch>
      </Box>
      <Footer />
    </Router>
  </Box>
)

const App: React.FunctionComponent = () => (
  <ThemeProvider theme={lightTheme}>
    <CssBaseline />
    <AuthProvider>
      <Toaster />
      <Router>
        <Switch>
          {/* putting AutoAuthenticator route first */}
          {/* <Route path="/auth" component={AutoAuthenticator} /> */}
          
          {/* then the rest of the app */}
          <Route path="/">
            <AuthIsSignedIn>
              <MainRoute />
            </AuthIsSignedIn>
            <AuthIsNotSignedIn>
              <SignInRoute />
            </AuthIsNotSignedIn>
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  </ThemeProvider>
)

export default App