import { useToast } from "@/hooks/use-toast"
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}


type ToastType = 'success' | 'error' | 'warning' | 'info'

interface ToastOptions {
  title?: string
  description: string
  type?: ToastType
  duration?: number
}

export const useCustomToast = () => {
  const { toast } = useToast()

  const showToast = ({ 
    title, 
    description, 
    type = 'info',
    duration = 3000 
  }: ToastOptions) => {
    const variants = {
      success: 'success',
      error: 'destructive',
      warning: 'warning',
      info: 'info'
    } as const

    toast({
      title: title,
      description: description,
      variant: variants[type],
      duration: duration,
    })
  }

  return { showToast }
}