import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box component="footer" sx={{ bgcolor: 'primary.main', py: 2, position: 'fixed', bottom: 0, width: '100%' }}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="white" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://claritycare.ai/">
            ClarityCareAI
          </Link>{' '}
          {currentYear}
          {'.'}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;