import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "../ui/alert-dialog"

import { X } from 'lucide-react'
import React from 'react'

export function SubmitConfirmationAlertDialog({
        isOpen,
        onOpenChange,
        onContinueToCases,
        onContinueToNextCase,
        isNextCaseDisabled
    }: {
        isOpen: boolean
        onOpenChange: (open: boolean) => void
        onContinueToCases: () => void
        onContinueToNextCase: () => void
        isNextCaseDisabled: boolean
    }) {
    return (
        <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
            <AlertDialogContent>
                <AlertDialogCancel className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                    <X className="h-4 w-4" />
                    <span className="sr-only">Close</span>
                </AlertDialogCancel>

                <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure you want to continue?</AlertDialogTitle>
                    
                    <AlertDialogDescription>
                        Please select one of the options below to proceed.
                    </AlertDialogDescription>
                </AlertDialogHeader>

                <AlertDialogFooter className="flex-col sm:flex-row gap-2">
                    <AlertDialogAction onClick={onContinueToCases} className="w-full sm:w-auto bg-secondary hover:bg-secondary/90 text-secondary-foreground">
                        Yes, continue to Cases Page
                    </AlertDialogAction>
                    <AlertDialogAction onClick={onContinueToNextCase} className="w-full sm:w-auto bg-primary hover:bg-primary/90 text-primary-foreground" disabled={isNextCaseDisabled}>
                        Yes, continue to Next Case
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}