import { useEffect } from 'react';
import { History } from 'history';

export const DisableBackButton = (location  : any, history: any) => {

    // console.log("location usePreventBackNavigation", location)
    useEffect(() => {
        const refreshCount = parseInt(sessionStorage.getItem('refreshCount') || '0');
        const MAX_REFRESHES = 19;

        const stateForStorage = {
            ...location.state,
            pdf_data: {
                ...location.state.pdf_data,
                blob: null
            }
        };

        sessionStorage.setItem('resultPageState', JSON.stringify(stateForStorage));
        sessionStorage.setItem('refreshCount', (refreshCount + 1).toString());

        // when result page is loaded, we save the blob to sessionStorage as base64 string
        if (location.state.pdf_data?.blob) {
        const reader = new FileReader();
        reader.onload = () => {
            sessionStorage.setItem('pdfBlob', reader.result as string);
        };
        reader.readAsDataURL(location.state.pdf_data.blob);
        }

        // if user navigates back, pdf blob in state/location is not readable, 
        // since blob data does not presist between navigation,
        // so we have to reconstruct the blob from sessionStorage which we stored as base64 string
        if (!location.state.pdf_data?.blob) {
        const base64Blob = sessionStorage.getItem('pdfBlob');
        if (base64Blob) {
            fetch(base64Blob)
            .then(res => res.blob())
            .then(blob => {
                location.state.pdf_data.blob = new Blob([blob], { type: 'application/pdf' });
                // window.location.reload();
            });
        }
        }
        
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (refreshCount > MAX_REFRESHES) {
                e.preventDefault();
                alert('Maximum page refreshes exceeded. Please navigate normally through the application.');
                return '';
            }

            e.preventDefault();
            return "Are you sure you want to leave? Your progress will be lost.";
        };

        const handleKeydown = (e: KeyboardEvent) => {
            if (refreshCount > MAX_REFRESHES) {
                if (e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {
                    e.preventDefault();
                    alert('Maximum page refreshes exceeded. Please navigate normally through the application.');
                }
            }
        };

        const unblock = (history as unknown as History).block((location: any, action: any) => {
        
        if (action === 'POP') {
            const savedState = sessionStorage.getItem('resultPageState');
            
            const base64Blob = sessionStorage.getItem('pdfBlob');
            
            if (savedState && base64Blob) {
                const parsedState = JSON.parse(savedState);
                fetch(base64Blob)
                .then(res => res.blob())
                .then(blob => {
                    parsedState.pdf_data.blob = new Blob([blob], { type: 'application/pdf' });

                    window.history.replaceState(null, '', '/results');
                    history.replace('/results', parsedState);
                });
            }
            // }
            return false;
        }
        });

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('keydown', handleKeydown);

        return () => {
            unblock();
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('keydown', handleKeydown);
            sessionStorage.removeItem('resultPageState');
            sessionStorage.removeItem('pdfBlob');
        };
    }, [history]);
}