import { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '@/contexts/authContext';
import { useCustomToast } from '@/components/ui/toaster';


export const useInactivityLogout = (timeoutMinutes: number = 30) => {
    const history = useHistory();
    const { signOut } = useContext(AuthContext);
    const { showToast } = useCustomToast();

    useEffect(() => {
        let inactivityTimeout: NodeJS.Timeout;
        let warningTimeout: NodeJS.Timeout;
        let isRefreshing = false;

        const handleTabClose = () => {
            if (!document.hidden) {
                signOut();
                localStorage.clear();
                sessionStorage.clear();
            }
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                const navigationEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
                const navigationType = navigationEntries.length > 0 ? navigationEntries[0].type : null;
                
                if (navigationType !== 'reload' && navigationType !== 'navigate') {
                    handleTabClose();
                }
            }
        };
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            const navigationEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
            const navigationType = navigationEntries.length > 0 ? navigationEntries[0].type : null;

            if (navigationType !== 'reload' && navigationType !== 'navigate') {
                handleTabClose();
            }
        };

        const resetTimer = () => {
            clearTimeout(inactivityTimeout);
            clearTimeout(warningTimeout);
            
            // Show warning 30 seconds before logout
            warningTimeout = setTimeout(() => {
                showToast({
                    title: "Session Expiring",
                    description: "You will be logged out in 30 seconds due to inactivity",
                    type: "warning",
                    duration: 30000,
                });
            }, (timeoutMinutes * 60 * 1000) - 30000);

            inactivityTimeout = setTimeout(() => {
                showToast({
                    title: "Session Expired",
                    description: "You have been logged out due to inactivity",
                    type: "error",
                    duration: 3000,
                });

                signOut();
                history.push('/signin');
            }, timeoutMinutes * 60 * 1000);
        };

        const events = [
            'mousedown',
            'mousemove',
            'keypress',
            'scroll',
            'touchstart',
            'click'
        ];

        events.forEach(event => {
            document.addEventListener(event, resetTimer);
        });

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        resetTimer();

        return () => {
            clearTimeout(inactivityTimeout);
            clearTimeout(warningTimeout);
            events.forEach(event => {
                document.removeEventListener(event, resetTimer);
            });
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };

    }, [history, signOut, timeoutMinutes]);
};