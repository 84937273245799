import { OverviewData } from '../types/summaryTypes'

// Function to generate clinical data based on input parameters
export function generateClinicalData(presentation: string, findings: string, treatmentPlan: string): string {
    return `
## Initial Presentation
${presentation}

## Diagnostic Findings
${findings}

## Treatment Plan
${treatmentPlan}
`;
}

const defaultClinicalData = generateClinicalData(
    "Patient is a 35-year-old non-smoking male. He presents with acute right lower quadrant abdominal pain, accompanied by nausea, vomiting, and low-grade fever (38.2°C). Physical examination and vital signs are consistent with acute inflammatory response.",
    "CT scan confirms diagnosis of acute appendicitis (ICD-10: K35.80). Laboratory studies show elevated WBC count of 14,000 cells/µL and CRP of 45 mg/L. Patient's BMI is 24.5, with no prior surgeries or drug allergies noted.",
    "Patient is scheduled for laparoscopic appendectomy (CPT: 44970). Preoperative antibiotics will include Ceftriaxone and Metronidazole. Expected hospital stay is 1-2 days with standard post-operative care."
);

const ankleCTScanClinicalData = generateClinicalData(
    "Patient is a 35-year-old non-smoking male. He presents with acute right lower quadrant abdominal pain, accompanied by nausea, vomiting, and low-grade fever (38.2°C). Physical examination and vital signs are consistent with acute inflammatory response.",
    "CT scan confirms diagnosis of acute appendicitis (ICD-10: K35.80). Laboratory studies show elevated WBC count of 14,000 cells/µL and CRP of 45 mg/L. Patient's BMI is 24.5, with no prior surgeries or drug allergies noted.",
    "Patient is scheduled for laparoscopic appendectomy (CPT: 44970). Preoperative antibiotics will include Ceftriaxone and Metronidazole. Expected hospital stay is 1-2 days with standard post-operative care."
);

const totalAnkleReplacementClinicalData = generateClinicalData(
    "Patient is a 35-year-old female with chronic ankle pain due to primary osteoarthritis in the right ankle and foot.",
    "X-ray shows severe joint degeneration in the right ankle, consistent with primary osteoarthritis (ICD-10: M19.071). Laboratory studies show normal inflammatory markers.",
    "Patient is scheduled for total ankle replacement (CPT: 27702). Preoperative antibiotics will include Cefazolin. Expected hospital stay is 3-5 days with standard post-operative care."
);

export const overviewData = {
    memberInfo: {
        name: 'John Doe',
        age: 35,
        gender: 'Male',
        id: '123456',
        dob: '01/02/1989',
        contact: '123-456-7890',
        email: 'john.doe@example.com',
    },
    serviceInfo: {
        service: 'Appendectomy',
        type: 'Inpatient Surgery',
        icdCode: 'K35.80',
        icdDescription: 'Unspecified acute appendicitis',
        cptCode: '44970',
        cptDescription: 'Laparoscopic appendectomy',
    },
    providerInfo: {
        name: 'Dr. Patel Khan',
        npi: '123456',
        facility: 'City Hospital',
        facilityId: '123456',
        contact: '123-456-7890',
        address: '123 Hospital Dr, Anytown, 12345',
    },
    caseDetails: {
        guideline: 'MCG - S-5180',
        receiptDate: 'Nov 03, 2024',
        dueDate: 'Nov 05, 2024',
    },
    insuranceInfo: {
        plan: 'Health First',
        planType: 'PPO',
        groupNumber: '123456',
        benefitType: 'Medical',
        policy: 'PPO12345',
        address: '123 Hospital Dr, Anytown, 12345',
    },
    clinicalData: defaultClinicalData
}

export const AnkleCTScan = {
    memberInfo: {
        name: 'Nobody Doe',
        age: 35,
        gender: 'Male',
        id: '123456',
        dob: '01/02/1989',
        contact: '123-456-7890',
        email: 'nobody.doe@example.com',
    },
    serviceInfo: {
        service: 'Ankle CT Scan',
        type: 'Inpatient Surgery',
        icdCode: 'K35.80',
        icdDescription: 'Unspecified acute appendicitis',
        cptCode: '44970',
        cptDescription: 'Laparoscopic appendectomy',
    },
    providerInfo: {
        name: 'Dr. NoName Khan',
        npi: '123456',
        facility: 'City Hospital',
        facilityId: '123456',
        contact: '123-456-7890',
        address: '123 Hospital Dr, Anytown, 12345',
    },
    caseDetails: {
        guideline: 'A-0014 - Ankle CT Scan',
        receiptDate: 'Nov 03, 2024',
        dueDate: 'Nov 05, 2024',
    },
    insuranceInfo: {
        plan: 'Health Second',
        planType: 'PPO',
        groupNumber: '123456',
        benefitType: 'Medical',
        policy: 'PPO12345',
        address: '123 Hospital Dr, Anytown, 12345',
    },
    clinicalData: ankleCTScanClinicalData
}



export const totalAnkleReplacement = {
    memberInfo: {
        name: 'Jane Smith',
        age: 35,
        gender: 'Female',
        id: '123456',
        dob: '03/15/1989',
        contact: '123-456-7890',
        email: 'jane.smith@example.com',
    },
    serviceInfo: {
        service: 'Total Ankle Replacement',
        type: 'Inpatient Surgery',
        icdCode: 'M19.071',
        icdDescription: 'Primary osteoarthritis, right ankle and foot',
        cptCode: '27702',
        cptDescription: 'Under Repair, Revision, and/or Reconstruction Procedures on the Leg (Tibia and Fibula) and Ankle Joint',
    },
    providerInfo: {
        name: 'Dr. Sarah Smith',
        npi: '1234567890',
        facility: 'Northside Hospital',
        facilityId: 'NMH140281',
        contact: '(555) 987-6543',
        address: '251 E Huron St, Chicago, IL 60611',
    },
    caseDetails: {
        guideline: 'HCSC - Total Ankle Replacement',
        receiptDate: 'Nov 03, 2024',
        dueDate: 'Nov 25, 2024',
    },
    insuranceInfo: {
        // plan: 'Blue Cross Blue Shield of Illinois',
        plan: 'Blue Choice',
        planType: 'PPO',
        groupNumber: 'P12345P01',
        benefitType: 'Commercial',
        policy: 'IL234567',
        address: '300 E Randolph St, Chicago, IL 60601',
    },
    clinicalData: totalAnkleReplacementClinicalData
}

function formatDate(dateString: string): string {
    if (!dateString) return '';
    try {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
    } catch {
        return dateString;
    }
}


export function transformDemoData(demo_data: any) {
    // clinical summary must be separated by ". ". there should be 3 sentences
    const clinicalSummarySplit = demo_data.clinical_summary.split(". ")

    return {
        memberInfo: {
            name: demo_data.memberinfo_name || '',
            age: demo_data.memberinfo_age || 0,
            gender: demo_data.memberinfo_gender || '',
            id: demo_data.memberinfo_id || '',
            dob: formatDate(demo_data.memberinfo_dob) || '',
            contact: demo_data.memberinfo_contact || '',
            email: demo_data.memberinfo_email || '',
        },
        serviceInfo: {
            service: demo_data.serviceinfo_service || '',
            type: demo_data.serviceinfo_type || '',
            icdCode: demo_data.serviceinfo_icd_code || '',
            icdDescription: demo_data.serviceinfo_icd_description || '',
            cptCode: demo_data.serviceinfo_cpt_code || '',
            cptDescription: demo_data.serviceinfo_cpt_description || '',
        },
        providerInfo: {
            name: demo_data.providerinfo_name || '',
            npi: demo_data.providerinfo_npi || '',
            facility: demo_data.providerinfo_facility || '',
            facilityId: demo_data.providerinfo_facility_id || '',
            contact: demo_data.providerinfo_contact || '',
            address: demo_data.providerinfo_address || '',
        },
        caseDetails: {
            guideline: demo_data.guideline_name || '',
            receiptDate: formatDate(demo_data.receipt_date) || '',
            dueDate: formatDate(demo_data.decision_date) || '',
        },
        insuranceInfo: {
            plan: demo_data.insuranceinfo_plan || '',
            planType: demo_data.insuranceinfo_plan_type || '',
            groupNumber: demo_data.insuranceinfo_group_number || '',
            benefitType: demo_data.insuranceinfo_benefit_type || '',
            policy: demo_data.insuranceinfo_policy || '',
            address: demo_data.insuranceinfo_address || '',
        },
        clinicalData: generateClinicalData(clinicalSummarySplit[0], clinicalSummarySplit[1], clinicalSummarySplit[2])
    };
}