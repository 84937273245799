import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft, SearchIcon, ChevronUp, ChevronDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
} from '@/components/ui/card';
import { cn } from '@/lib/util';
import { getColorHex } from '@/routes/result';

export interface ClinicalDecisioning {
  bounding_box: {
    x: number;
    y: number;
    width: number;
    height: number;
    page: number;
  };
  id: string;
  is_match: string;
  relevant: string;
  text: string;
  ocr_id: string;
}

export interface Criterion {
  color: string;
  criteria?: Criterion[];
  criteria_id: string;
  description: string;
  operator?: 'AND' | 'OR';
  clinical_decisioning?: ClinicalDecisioning[];
}

interface CriteriaTreeProps {
  data: Criterion;
  onMatchClick: (match: ClinicalDecisioning, color: string, criterionId: string) => void;
  highlightedCriterionId: string | null;
}

interface CriterionNodeProps {
  criterion: Criterion;
  level: number;
  onMatchClick: (match: ClinicalDecisioning, color: string, criterionId: string) => void;
  parentOperator?: 'AND' | 'OR';
  isLastChild: boolean;
  highlightedCriterionId: string | null;
  expandedMatchId: string | null;
  onExpandMatch: (criterionId: string | null) => void;
}

const INDENT_SIZE = 20;
const CHILD_INDENT = 15;

const CriterionNode: React.FC<CriterionNodeProps> = ({ 
  criterion, 
  level, 
  onMatchClick, 
  parentOperator, 
  isLastChild,
  highlightedCriterionId,
  expandedMatchId,
  onExpandMatch,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // ! new changes
  const matchesExpanded = expandedMatchId === criterion.criteria_id;

  const hasChildren = criterion.criteria && criterion.criteria.length > 0;
  const isMatched = criterion.clinical_decisioning && 
    criterion.clinical_decisioning.length > 0 && 
    criterion.clinical_decisioning[0].text !== 'None';

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getResponsiveStyles = () => {
    const base = {
      position: 'relative',
      paddingLeft: windowWidth < 600 
        ? `${INDENT_SIZE / 2 + CHILD_INDENT / 2}px`
        : `${INDENT_SIZE + CHILD_INDENT}px`,
      marginTop: level > 0 ? (windowWidth < 600 ? '0.5rem' : '1rem') : '0'
    };
    return base;
  };

  const currentOperator = criterion.operator;
  const borderStyle = parentOperator === undefined
    ? (currentOperator === 'OR' ? 'dashed' : 'solid')
    : (parentOperator === 'OR' ? 'dashed' : 'solid');

  const isHighlighted = criterion.criteria_id === highlightedCriterionId;
  const getBackgroundColor = () => {
    if (isHighlighted) {
      switch (criterion.color.toLowerCase()) {
        case 'green': return 'bg-green-50';
        case 'red': return 'bg-red-50';
        default: return 'bg-yellow-50';
      }
    }
    return 'bg-white';
  };

  return (
    <div style={getResponsiveStyles() as React.CSSProperties} className="relative">
      {level > 0 && (
        <>
          <div 
            className="absolute w-0.5" 
            style={{
              top: '-15px',
              left: `${CHILD_INDENT}px`,
              height: isLastChild ? '35px' : 'calc(100% + 17px)',
              borderLeft: `2px ${borderStyle} #E0E0E0`
            }}
          />
          <div 
            className="absolute h-0.5"
            style={{
              top: '20px',
              left: `${CHILD_INDENT}px`,
              width: `${INDENT_SIZE}px`,
              borderTop: `2px ${borderStyle} #E0E0E0`,
            }}
          />
        </>
      )}
      
      <Card 
        data-testid="criterion-box"
        data-highlighted={criterion.criteria_id}
        className={cn(
          "transition-colors duration-500",
          getBackgroundColor(),
          "border-2 border-gray-300",
          borderStyle === 'dashed' ? "border-dashed" : "border-solid"
        )}
      >
        <CardContent className={cn(
          "flex items-center justify-between gap-4",
          "p-4",
          windowWidth < 600 ? "p-2" : "p-4"
        )}>
          <div 
            data-testid="color-box"
            className="w-4 h-4 rounded flex-shrink-0"
            style={{ backgroundColor: getColorHex(criterion.color) }}
          />
          
          <p className={cn(
            "flex-grow break-words",
            windowWidth < 600 ? "text-sm" : "text-base"
          )}>
            {criterion.criteria_id} - {criterion.description}
          </p>

          {!hasChildren && isMatched && (
            <Button
              variant="outline"
              size="lg"
              className="ml-2 bg-blue-50 border-blue-200 hover:bg-blue-100 flex items-center gap-2"
              onClick={() => {
                const newExpandedId = matchesExpanded ? null : criterion.criteria_id;
                onExpandMatch(newExpandedId);
                if (!matchesExpanded && criterion.clinical_decisioning?.[0]) {
                  onMatchClick(
                    criterion.clinical_decisioning[0],
                    criterion.color,
                    criterion.criteria_id
                  );
                }
              }}
            >
              <SearchIcon className="w-4 h-4 text-blue-500" />
              {matchesExpanded ? 'Hide Matches' : 'View Matches'}
            </Button>
          )}

            {hasChildren && (
            <Button
              variant="outline"
              size="lg"
              className={cn(
                "ml-2 group flex items-center gap-2",
                level === 0 
                  ? "bg-purple-50 border-purple-200 hover:bg-purple-100" 
                  : "bg-amber-50 border-amber-200 hover:bg-amber-100"
              )}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                level === 0 ? (
                  <ChevronDown className="w-4 h-4 text-slate-500" />
                ) : (
                  <ChevronDown className="w-4 h-4 text-slate-500" />
                )
              ) : (
                level === 0 ? (
                  <ChevronRight className="w-4 h-4 text-purple-500 fill-purple-500" />
                ) : (
                  <ChevronRight className="w-4 h-4 text-amber-500 fill-amber-500" />
                )
              )}
              {level === 0 ? (
                expanded ? <>Hide Rules <span className="text-xs text-slate-500"></span></> : <>View Rules <span className="text-xs text-slate-500"></span></>
              ) : (
                expanded ? 'Hide Sub-Rules' : 'View Sub-Rules'
              )}
            </Button>
          )}
        </CardContent>

        {isMatched && matchesExpanded && criterion.clinical_decisioning && (
          <CardContent className="mt-2 pl-8">
            <p className={windowWidth < 600 ? "text-sm" : "text-base"}>Matches:</p>
            <ul className="space-y-2 mt-2">
              {criterion.clinical_decisioning.map((match, index) => (
                <li
                  key={index}
                  className="flex items-center justify-between"
                >
                  <span className={cn(
                    "flex-grow mr-2",
                    windowWidth < 600 ? "text-sm" : "text-base"
                  )}>
                    - {match.text}
                  </span>
                </li>
              ))}
            </ul>
          </CardContent>
        )}
      </Card>
      
      {hasChildren && expanded && criterion.criteria && (
        <div className={cn(
          "relative",
          windowWidth < 600 ? "mt-2" : "mt-4"
        )}>
          {criterion.criteria.map((child, index, array) => (
            <CriterionNode
              key={child.criteria_id}
              criterion={child}
              level={level + 1}
              onMatchClick={onMatchClick}
              parentOperator={currentOperator}
              isLastChild={index === array.length - 1}
              highlightedCriterionId={highlightedCriterionId}
              expandedMatchId={expandedMatchId}
              onExpandMatch={onExpandMatch}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const CriteriaTree: React.FC<CriteriaTreeProps> = ({ 
  data, 
  onMatchClick, 
  highlightedCriterionId 
}) => {
  const [expandedMatchId, setExpandedMatchId] = useState<string | null>(null);

  return (
    <div data-criteria-id={data.criteria_id}>
      <CriterionNode 
        criterion={data} 
        level={0} 
        onMatchClick={onMatchClick} 
        isLastChild={false}
        highlightedCriterionId={highlightedCriterionId}
        expandedMatchId={expandedMatchId}
        onExpandMatch={setExpandedMatchId}
      />
    </div>
  );
};

export default CriteriaTree;