import { useState, useEffect, useMemo, useRef } from 'react'
import { Bar, BarChart, Line, LineChart, ResponsiveContainer, ScatterChart, Scatter, Cell, ComposedChart, Area, Tooltip, Legend, CartesianGrid, XAxis, YAxis, LabelList, ReferenceLine, Rectangle } from "recharts"
import { BedDouble, Bell, CheckCircle, XCircle, Search, Clock, ClipboardList, BarChart2, Calendar, BarChart3, BarChartHorizontal, Hourglass, LayoutList, FileCheck, DollarSign, Activity, FileText, Users } from "lucide-react"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"

import { Button, ButtonDestructive } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Separator } from "@/components/ui/separator"
import { useHistory } from 'react-router-dom';
import { getSampleJson, getSamplePdf } from '../libs/backend';
import { companyDashboardData } from '../data/companyDashboardData'


//! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ INTERFACE TYPES ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

type RateData = {
    approved: {
        elevated: number
        pended: number
        approved: number
        appealed: number
    }
    denied: {
        denied: number
        elevated: number
        pended: number
        appealed: number
    }
}

type CaseRateData = {
    approved: {
        elevated: number
        pended: number
        approved: number
        appealed: number
    }
    denied: {
        denied: number
        elevated: number
        pended: number
        appealed: number
    }
}

type CaseRatesProps = {
    yearToDate: RateData
    currentMonth: RateData
}

type AverageTimeProps = {
    monthAverage: number;
    yearToDateAverage: number;
}

type BinData = {
    bin: string;
    averageLOS: number;
}

type AverageLOSProps = {
    binData: BinData[];
    yearToDateAverage: number;
}

type YearToDateData = {
    month: string
    casesReviewed: number
}

type WeekdayData = {
    day: string
    averageCases: number
}

type CasesReviewedProps = {
    currentDayCases: number
    yearToDateData: YearToDateData[]
    weekdayData: WeekdayData[]
}

type CPTCodeData = {
    rank: number
    code: string
    description: string
    approvals: number
    averageCost: number
}

type CPTCodesProps = {
    mostExpensive: CPTCodeData[]
    leastExpensive: CPTCodeData[]
}

type OnTimeSubmissionProps = {
    monthPercentage: number;
    yearToDatePercentage: number;
}

// Add new interface for DailyMetrics
type DailyMetricsProps = {
    totalCases: number;
    averageTime: number;
    slaMet: number;
    approvalRate: number;
}

// Add new interfaces for weekly data
type WeeklyMetric = {
    day: string;
    value: number;
}

type WeeklyMetricsData = {
    totalCases: WeeklyMetric[];
    averageTime: WeeklyMetric[];
    slaCompliance: WeeklyMetric[];
    approvalRate: WeeklyMetric[];
}

// Add new interfaces for yearly/quarterly data
type MonthlyData = {
    month: string;
    value: number;
}

type QuarterData = {
    quarter: string;
    value: number;
    months: MonthlyData[];
}

type YearlyMetricsData = {
    totalCases: QuarterData[];
    averageTime: QuarterData[];
    slaCompliance: QuarterData[];
    approvalRate: QuarterData[];
}

interface AdmissionRecord {
    id: string;
    date: Date;
    lengthOfStay: number;
    nurseId: string;
}

// Add new types for nurses
type Department = 'Inpatient' | 'Outpatient' | 'Pharmacy';

interface Nurse {
    id: string;
    name: string;
    department: Department;
    casesReviewed: number;
    approvalRate: number;
    averageTime: number;
    slaCompliance: number;
}

// Add nurse data
export const NURSES: Nurse[] = [
    // Inpatient Nurses
    {
        id: "N001",
        name: "Sarah Johnson",
        department: "Inpatient",
        casesReviewed: 16842,
        approvalRate: 93,      
        averageTime: 7,      
        slaCompliance: 94,   
    },
    {
        id: "N002",
        name: "Michael Chen",
        department: "Inpatient",
        casesReviewed: 17315,
        approvalRate: 95,
        averageTime: 7,
        slaCompliance: 98,
    },
    {
        id: "N003",
        name: "Emily Rodriguez",
        department: "Inpatient",
        casesReviewed: 15698,  
        approvalRate: 91,   
        averageTime: 8,
        slaCompliance: 92,   
    },

    // Outpatient Nurses
    {
        id: "N004",
        name: "David Kim",
        department: "Outpatient",
        casesReviewed: 18725,  
        approvalRate: 92,   
        averageTime: 6,
        slaCompliance: 97,
    },
    {
        id: "N005",
        name: "Lisa Patel",
        department: "Outpatient",
        casesReviewed: 17989,  
        approvalRate: 94,   
        averageTime: 6,
        slaCompliance: 95,
    },
    {
        id: "N006",
        name: "James Wilson",
        department: "Outpatient",
        casesReviewed: 18201,  
        approvalRate: 96,   
        averageTime: 6,
        slaCompliance: 96,
    },
    {
        id: "N007",
        name: "Maria Garcia",
        department: "Outpatient",
        casesReviewed: 17278,  
        approvalRate: 90,   
        averageTime: 7,
        slaCompliance: 93,
    },

    // Pharmacy Nurses
    {
        id: "N008",
        name: "Robert Taylor",
        department: "Inpatient",
        casesReviewed: 19189,  
        approvalRate: 98,   
        averageTime: 6,
        slaCompliance: 98,
    },
    {
        id: "N009",
        name: "Amanda Lee",
        department: "Inpatient",
        casesReviewed: 18967,  
        approvalRate: 99,   
        averageTime: 6,
        slaCompliance: 99,
    },
    {
        id: "N010",
        name: "Thomas Brown",
        department: "Outpatient",
        casesReviewed: 18478,  
        approvalRate: 97,   
        averageTime: 6,
        slaCompliance: 97,
    },
];

const COLORS = ['#9cfca5', '#ffff69', '#ff3948', '#054d5e']

const handlePullCase = async (history: any) => {
    try {
        let reviewerProcessStartTime = 0
        const randomNumber = Math.floor(Math.random() * 3);
        const [jsonResponse, pdfResponse] = await Promise.all([
            getSampleJson(randomNumber),
            getSamplePdf(randomNumber)
        ]);
        history.push('/results', {
            json_data: jsonResponse.data.json_data,
            pdf_data: {
                blob: pdfResponse.data,
                coming_from: "demo"
            },
            reviewer_process_start_time: reviewerProcessStartTime,
            coming_from: "dashboard",
        })
    } catch (error) {
        console.error('Error fetching medical record:', error)
        // maybe later we want to add error handling here, e.g., showing a toast notification
    }
}


//! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ HELPER FUNCTIONS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const calculatePercentages = (data: CaseRateData): DataItem[] => {
    const totalApproved = data.approved.approved + data.approved.elevated + data.approved.pended + data.approved.appealed
    const totalDenied = data.denied.denied + data.denied.elevated + data.denied.pended + data.denied.appealed
    const total = totalApproved + totalDenied

    return [
        {
            name: "Denied",
            percentage: Math.round((totalDenied / total) * 100),
            Initial: Math.round((data.denied.denied / totalDenied) * 100),
            Appealed: Math.round((data.denied.appealed / totalDenied) * 100),
            Pended: Math.round((data.denied.pended / totalDenied) * 100),
            Elevated: Math.round((data.denied.elevated / totalDenied) * 100)
        },
        {
            name: "Approved",
            percentage: Math.round((totalApproved / total) * 100),
            Initial: Math.round((data.approved.approved / totalApproved) * 100),
            Appealed: Math.round((data.approved.appealed / totalApproved) * 100),
            Pended: Math.round((data.approved.pended / totalApproved) * 100),
            Elevated: Math.round((data.approved.elevated / totalApproved) * 100)
        }
    ];
}

const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload
        return (
            <div className="bg-white p-4 rounded shadow-lg border border-gray-200">
                <p className="font-bold">{data.name}: {data.value}%</p>
                <ul className="list-disc pl-5">
                    {data.name === 'Approved' ? (
                        <>
                            <li>Approved: {data.approved}%</li>
                            <li>Elevated: {data.elevated}%</li>
                            <li>Pended: {data.pended}%</li>
                        </>
                    ) : (
                        <>
                            <li>Denied: {data.denied}%</li>
                            <li>Elevated: {data.deniedElevated}%</li>
                            <li>Pended: {data.deniedPended}%</li>
                        </>
                    )}
                </ul>
            </div>
        )
    }
    return null
}

interface DataItem {
    name: string;
    percentage: number;
    Initial: number;
    Appealed: number;
    Pended: number;
    Elevated: number;
}

type Status = 'Initial' | 'Appealed' | 'Pended' | 'Elevated';
type Outcome = 'Approved' | 'Denied';

interface ColorValue {
    Approved: string;
    Denied: string;
}

type PMPMMetrics = {
    pmpmCost: {
        current: number;
        previous: number;
        percentChange: number;
    };
    authsPerPatient: {
        current: number;
        previous: number;
        percentChange: number;
    };
};

interface Colors {
    Initial: ColorValue;
    Appealed: ColorValue;
    Pended: ColorValue;
    Elevated: ColorValue;
}

interface CustomBarProps {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    value?: number;
    fill?: string;
    name?: string;
    dataKey?: string;
    payload?: DataItem;
}

const BarChartAlex: React.FC<{ data: DataItem[] }> = ({ data }) => {
    const colors: Colors = {
        Initial: {
            Denied: "hsl(0, 84%, 60%)",
            Approved: "hsl(142, 76%, 45%)"
        },
        Appealed: {
            Denied: "hsl(0, 84%, 50%)",
            Approved: "hsl(142, 76%, 35%)"
        },
        Pended: {
            Denied: "hsl(0, 84%, 40%)",
            Approved: "hsl(142, 76%, 25%)"
        },
        Elevated: {
            Denied: "hsl(0, 84%, 30%)",
            Approved: "hsl(142, 76%, 15%)"
        }
    };

    const getBarColor = (status: Status, outcome: Outcome): string => {
        return colors[status][outcome];
    };

    const getDifference = (str: string): string =>
        Math.abs(str.split(',').map(Number)[1] - str.split(',').map(Number)[0]).toString();

    const CustomBar: React.FC<CustomBarProps> = (props) => {
        const {
            x = 0,
            y = 0,
            width = 0,
            height = 0,
            value = 0,
            name = "",
            dataKey = "",
            payload
        } = props;

        const fill = payload ? getBarColor(dataKey as Status, payload.name as Outcome) : "";
        const radius = 0;
        const fontSize = 12;
        const difference = parseInt(getDifference(value.toString()));

        const newHeight = height / 2;
        const centerY = y + (height - newHeight) / 2;

        return (
            <g>
                <Rectangle
                    x={x}
                    y={centerY}
                    width={width}
                    height={newHeight}
                    fill={fill}
                    radius={radius}
                />
                {width > 40 && (
                    <text
                        x={x + width / 2}
                        y={difference < 20 ? centerY - 5 : centerY + newHeight / 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill={difference < 20 ? "#000000" : "#ffffff"}
                        fontSize={fontSize}
                    >
                        {`${dataKey} ${difference}%`}
                    </text>
                )}
            </g>
        );
    };

    return (

        <ResponsiveContainer width="100%" height="100%">

            <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 30, bottom: 5 }}
            >
                <XAxis type="number" tickFormatter={(value: number) => `${value}%`} hide />
                <YAxis
                    type="category"
                    dataKey="name"
                    tickFormatter={(value: string) =>
                        `${value} (${data.find(item => item.name === value)?.percentage}%)`
                    }
                    width={120}
                />
                <Tooltip
                    formatter={(value: number, name: string) => [`${value}%`, name]}
                    labelFormatter={(value: string) => `Category: ${value}`}
                />
                {Object.keys(colors).map((key) => (
                    <Bar
                        key={key}
                        dataKey={key}
                        stackId="a"
                        name={key}
                        shape={<CustomBar />}
                    />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
};

//! ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ COMPONENTS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const CaseRatesCard: React.FC<CaseRatesProps> = ({ yearToDate, currentMonth }) => {
    const yearToDateData = calculatePercentages(yearToDate)
    const currentMonthData = calculatePercentages(currentMonth)


    return (
        <Card className="w-full h-full bg-gradient-to-br from-gray-100 to-white dark:from-gray-900 dark:to-black flex flex-col">
            <CardHeader className="pb-25">
                <CardTitle className="text-2xl font-bold text-center text-gray-800 dark:text-gray-200 flex items-center justify-center">
                    <LayoutList className="mr-2" />
                    Case Rates
                </CardTitle>
            </CardHeader>
            <CardContent className="flex-1 flex flex-col justify-between pt-6">
                <h3 className="text-lg font-semibold text-orange-600 dark:text-orange-300">Current Month (October 2024)</h3>
                <BarChartAlex data={currentMonthData} />
                <Separator />
                <h3 className="text-lg font-semibold text-orange-600 dark:text-orange-300">Year to Date 2024</h3>
                <BarChartAlex data={yearToDateData} />
                {/* {renderChart(currentMonthData, "Current Month (October 2024)")}
                {renderChart(yearToDateData, "Year to Date 2024")} */}
            </CardContent>
        </Card>
    )
}

const AverageTimeCard: React.FC<AverageTimeProps> = ({ monthAverage, yearToDateAverage }) => {
    const formatTime = (minutes: number) => {
        const hours = Math.floor(minutes / 60)
        const mins = minutes % 60
        return `${hours}h ${mins}m`
    }

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white flex flex-col items-center">
            <CardHeader className="w-full text-center">
                <CardTitle className="text-2xl font-bold text-center text-gray-800 flex items-center justify-center">
                    <Hourglass className="mr-2 text-gray-800" />
                    Average Time Spent per Case
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-8 w-full flex flex-col items-center">
                <div className="space-y-2 w-full flex flex-col items-center">
                    <h3 className="text-lg font-semibold text-center text-gray-800">
                        October 2024
                    </h3>
                    <p className="text-5xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                        {formatTime(monthAverage)}
                    </p>
                </div>

                <div className="space-y-2 w-full flex flex-col items-center">
                    <h3 className="text-lg font-semibold text-center text-gray-800">
                        Year to Date 2024
                    </h3>
                    <p className="text-5xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                        {formatTime(yearToDateAverage)}
                    </p>
                </div>
            </CardContent>
        </Card>
    )
}


const AverageLOSCard: React.FC<AverageLOSProps> = ({ binData, yearToDateAverage }) => {
    // New helper function to format bin labels
    const formatBinLabel = (bin: string) => {
        // Extract the upper bound from the range (e.g., "1001-2000" -> "2000")
        return bin.split('-')[1];
    };

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
            <CardHeader>
                <CardTitle className="text-2xl font-bold text-center text-gray-800 flex items-center justify-center">
                    <BedDouble className="mr-2 text-gray-800" />
                    Average LOS per 1000 Admissions
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-1">
                <div className="text-center">
                    <p className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                        {yearToDateAverage.toFixed(2)}
                    </p>
                    <p className="text-sm text-gray-600 mt-1">days</p>
                </div>


            </CardContent>
        </Card>
    )
}

const CasesReviewedCard: React.FC<CasesReviewedProps> = ({ currentDayCases, yearToDateData, weekdayData }) => {
    const renderYearToDateChart = () => (
        <div className="mb-12">
            <h3 className="text-lg font-semibold mb-6 text-gray-800 dark:text-gray-300 flex items-center border-b border-gray-200 dark:border-gray-800 pb-2">
                <Calendar className="mr-2" size={20} />
                Year to date 2024 - total cases reviewed
            </h3>
            <ResponsiveContainer width="100%" height={250}>
                <ComposedChart data={yearToDateData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="month" stroke="#666666" />
                    <YAxis stroke="#666666" />
                    <Tooltip
                        contentStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', border: 'none', borderRadius: '4px', color: '#ffffff' }}
                        formatter={(value: number) => [`${value} cases`, 'Cases Reviewed']}
                    />
                    <Bar dataKey="casesReviewed" fill="url(#blackGradient)">
                        <LabelList dataKey="casesReviewed" position="top" fill="#666666" formatter={(value: number) => Math.round(value)} />
                    </Bar>
                    <Line
                        type="monotone"
                        dataKey="casesReviewed"
                        stroke="#333333"
                        strokeWidth={2}
                        dot={{ fill: "#333333", r: 4 }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )

    const renderWeekdayChart = () => (
        <div className="mb-12">
            <h3 className="text-lg font-semibold mb-6 text-gray-800 dark:text-gray-300 flex items-center">
                <BarChart2 className="mr-2" size={20} />
                Year to date 2024 - average cases per weekday
            </h3>
            <ResponsiveContainer width="100%" height={250}>
                <BarChart
                    data={weekdayData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    barGap={20}
                >
                    <XAxis dataKey="day" stroke="#666666" axisLine={false} tickLine={false} />
                    <YAxis stroke="#666666" axisLine={false} tickLine={false} />
                    <Tooltip
                        cursor={false}
                        formatter={(value: number) => [`${value} cases`, 'Average Cases']}
                    />
                    <Bar dataKey="averageCases" fill="#666666" name="Average Cases" barSize={40}>
                        <LabelList
                            dataKey="averageCases"
                            position="top"
                            fill="#666666"
                            formatter={(value: number) => Math.round(value)}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <defs>
                <linearGradient id="blackGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#333333" />
                    <stop offset="100%" stopColor="#666666" />
                </linearGradient>
            </defs>
        </div>
    )

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white dark:from-gray-900 dark:to-black">
            <CardHeader>
                <CardTitle className="text-2xl font-bold text-center text-gray-800 dark:text-gray-200 flex items-center justify-center">
                    <ClipboardList className="mr-2" />
                    Number of Cases Reviewed
                </CardTitle>
            </CardHeader>

            <CardContent>
                <div className="mb-8 text-center bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md">
                    <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-300 flex items-center justify-center">
                        <FileCheck className="mr-2" size={20} />Cases Reviewed Today
                    </h3>
                    <p className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-600 to-gray-800">
                        {currentDayCases}
                    </p>
                </div>
                {renderWeekdayChart()}
                {renderYearToDateChart()}

                <defs>
                    <linearGradient id="blackGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#333333" />
                        <stop offset="100%" stopColor="#666666" />
                    </linearGradient>
                </defs>
            </CardContent>
        </Card>
    )
}

const CPTCodesCard: React.FC<CPTCodesProps> = ({ mostExpensive, leastExpensive }) => {
    const renderTable = (data: CPTCodeData[]) => (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead className="w-[50px]">Rank</TableHead>
                    <TableHead className="text-right">Approvals</TableHead>
                    <TableHead>CPT Code</TableHead>
                    <TableHead>Description</TableHead>
                    <TableHead className="text-right">Avg. Cost</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data.map((item) => (
                    <TableRow key={item.rank}>
                        <TableCell>{item.rank}</TableCell>
                        <TableCell className="text-left">{item.approvals}</TableCell>
                        <TableCell>{item.code}</TableCell>
                        <TableCell title={item.description}>
                            {item.description.length > 40
                                ? `${item.description.substring(0, 40)}...`
                                : item.description}
                        </TableCell>
                        <TableCell className="text-right">${item.averageCost.toFixed(2)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Card className="w-full bg-gradient-to-br from-gray-100 to-white dark:from-gray-900 dark:to-black">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-center text-gray-800 dark:text-gray-200 flex items-center justify-center">
                        <DollarSign />
                        <DollarSign />
                        <DollarSign className="mr-2" />
                        Most Expensive
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="overflow-x-auto">
                        {renderTable(mostExpensive)}
                    </div>
                </CardContent>
            </Card>

            <Card className="w-full bg-gradient-to-br from-gray-100 to-white dark:from-gray-900 dark:to-black">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold text-center text-gray-800 dark:text-gray-200 flex items-center justify-center">
                        <DollarSign className="mr-2" />
                        Least Expensive
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="overflow-x-auto">
                        {renderTable(leastExpensive)}
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

const OnTimeSubmissionCard: React.FC<OnTimeSubmissionProps> = ({ monthPercentage, yearToDatePercentage }) => {
    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
            <CardHeader>
                <CardTitle className="text-2xl font-bold text-center text-gray-800 flex items-center justify-center">
                    <Clock className="mr-2 text-gray-800" />
                    On-time Submission
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-8 w-full flex flex-col items-center">
                <div className="space-y-2 w-full flex flex-col items-center">
                    <h3 className="text-lg font-semibold text-center text-gray-800">
                        October 2024
                    </h3>
                    <p className="text-5xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                        {monthPercentage}%
                    </p>
                </div>

                <div className="space-y-2 w-full flex flex-col items-center">
                    <h3 className="text-lg font-semibold text-center text-gray-800">
                        Year to Date 2024
                    </h3>
                    <p className="text-5xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-900">
                        {yearToDatePercentage}%
                    </p>
                </div>
            </CardContent>
        </Card>
    )
}

// New DailyMetrics component
const DailyMetricsCard: React.FC<{
    metric: string;
    value: string | number;
    weeklyAverage: number;
    icon: React.ReactNode;
    valueFormatter?: (value: number) => string;
    comparisonLabel?: string;
    description?: string;
}> = ({ metric, value, weeklyAverage, icon, valueFormatter = (value) => `${value}`, comparisonLabel = "Weekly Avg", description }) => {
    const numericValue = typeof value === 'string' ? parseFloat(value) : value;
    const difference = numericValue - weeklyAverage;
    const percentChange = ((difference / weeklyAverage) * 100).toFixed(1);
    const isPositive = difference > 0;

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
            <CardContent className="pt-6">
                <div className="flex flex-col items-center space-y-2">
                    <div className="p-2 bg-gray-100 rounded-full">
                        {icon}
                    </div>
                    <h3 className="text-sm font-medium text-gray-600">{metric}</h3>
                    <p className="text-3xl font-bold text-gray-900">
                        {value}
                    </p>
                    <p className={`text-sm ${isPositive ? 'text-green-600' : 'text-red-600'} flex items-center gap-1 whitespace-nowrap`}>
                        {isPositive ? '↑' : '↓'} {Math.abs(Number(percentChange))}% vs {comparisonLabel}
                        <span className="text-gray-500">
                            ({valueFormatter(weeklyAverage)})
                        </span>
                    </p>
                </div>
            </CardContent>
        </Card>
    );
};

// Add a metrics configuration object at the top level
const METRICS_CONFIG = {
    totalCases: {
        icon: <ClipboardList className="h-6 w-6 text-gray-600" />,
        color: "#4f46e5", // Indigo
        title: "Total Cases Completed"
    },
    averageTime: {
        icon: <Clock className="h-6 w-6 text-gray-600" />,
        color: "#059669", // Emerald
        title: "Average Time per Case"
    },
    slaCompliance: {
        icon: <CheckCircle className="h-6 w-6 text-gray-600" />,
        color: "#0891b2", // Cyan
        title: "TAT Compliance"
    },
    approvalRate: {
        icon: <BarChart2 className="h-6 w-6 text-gray-600" />,
        color: "#7c3aed", // Violet
        title: "Initial Approval Rate"
    },
    admissionRate: {
        icon: <BedDouble className="h-6 w-6 text-gray-600" />,
        color: "#ea580c", // Orange
        title: "Admission Rate"
    },
    lengthOfStay: {
        icon: <Calendar className="h-6 w-6 text-gray-600" />,
        color: "#0891b2", // Cyan
        title: "Length of Stay"
    },
    readmissionRate: {
        icon: <BarChart3 className="h-6 w-6 text-gray-600" />,
        color: "#7c3aed", // Violet
        title: "Readmission Rate"
    },
    appealRate: {
        icon: <BarChart2 className="h-6 w-6 text-gray-600" />,
        color: "#2563eb", // Blue
        title: "Appeal Rate"
    },
    casePathway: {
        icon: <LayoutList className="h-6 w-6 text-gray-600" />,
        color: "#0891b2", // Cyan
        title: "Approved Care Pathways"
    },
    utilizationRate: {
        icon: <Activity className="h-6 w-6 text-gray-600" />,
        color: "#0d9488", // Teal
        title: "Utilization Rate"
    },
    elevatedRate: {
        icon: <BedDouble className="h-6 w-6 text-gray-600" />,
        color: "#0d9488", // Teal
        title: "Elevated Rate"
    },
};

// Update WeeklyMetricChart to include icon
const WeeklyMetricChart: React.FC<{
    data: (WeeklyMetric & { isPrediction?: boolean })[];
    title: string;
    valueFormatter?: (value: number) => string;
    color?: string;
    icon?: React.ReactNode;
}> = ({ data, title, valueFormatter = (value) => `${value}`, color = "#666666", icon }) => {
    const average = calculateAverage(data);

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
            <CardHeader>
                <CardTitle className="text-lg font-semibold flex items-center gap-2">
                    {icon}
                    {title}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={data} margin={{ top: 20, right: 35, left: 10, bottom: 20 }}>
                        <XAxis
                            dataKey="day"
                            stroke="#666666"
                            tick={{ fontSize: 12 }}
                        />
                        <YAxis
                            stroke="#666666"
                            tick={{ fontSize: 12 }}
                            tickFormatter={valueFormatter}
                        />
                        <Tooltip
                            formatter={(value: number) => [valueFormatter(value), title]}
                            contentStyle={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                border: '1px solid #ccc',
                                borderRadius: '4px'
                            }}
                        />
                        <Bar dataKey="value" fill={color}>
                            {data.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`}
                                    fill={entry.isPrediction ? `${color}80` : color}
                                />
                            ))}
                            <LabelList
                                dataKey="value"
                                position="top"
                                formatter={valueFormatter}
                                style={{ fontSize: '11px' }}
                            />
                        </Bar>
                        <ReferenceLine
                            y={average}
                            stroke="#666666"
                            strokeDasharray="3 3"
                            label={{
                                value: `${valueFormatter(average)}`,
                                position: 'right',
                                fill: '#666666',
                                fontSize: 12
                            }}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

// Update QuarterlyMetricChart to include view toggle
const QuarterlyMetricChart: React.FC<{
    data: QuarterData[];
    title: string;
    valueFormatter?: (value: number) => string;
    color?: string;
    icon?: React.ReactNode;
}> = ({ data, title, valueFormatter = (value) => `${value}`, color = "#666666", icon }) => {
    const [viewType, setViewType] = useState < 'quarter' | 'month' > ('quarter');

    // Transform data based on view type
    const chartData = useMemo(() => {
        if (viewType === 'quarter') {
            return data.map(quarter => ({
                ...quarter,
                value: quarter.value
            }));
        } else {
            return data.flatMap(quarter => quarter.months);
        }
    }, [data, viewType]);

    // Custom tooltip component
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const quarterData = viewType === 'quarter'
                ? data.find(q => q.quarter === label)
                : null;

            let totalQuarter = 0;
            for (const month of quarterData?.months || []) {
                totalQuarter += month.value;
            }
            return (
                <div className="bg-white p-4 rounded shadow-lg border border-gray-200">
                    <p className="font-bold mb-2">{label}</p>
                    <p className="text-sm">
                        {title}: {valueFormatter(payload[0].value)}
                    </p>

                    {/* Show monthly breakdown for quarter view */}
                    {viewType === 'quarter' && quarterData && (
                        <div className="mt-2 border-t pt-2">
                            <p className="text-xs font-semibold mb-1">Monthly Breakdown:</p>
                            {quarterData.months.map((month, idx) => (
                                <div key={idx} className="text-xs flex justify-between">
                                    <span>{month.month}:</span>
                                    <span>{valueFormatter(month.value)}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            );
        }
        return null;
    };

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
            <CardHeader>
                <div className="flex justify-between items-center">
                    <CardTitle className="text-lg font-semibold flex items-center gap-2">
                        {icon}
                        {title}
                    </CardTitle>
                    <Select
                        value={viewType}
                        onValueChange={(value: 'quarter' | 'month') => setViewType(value)}
                    >
                        <SelectTrigger className="w-[120px]">
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="quarter">Quarterly</SelectItem>
                            <SelectItem value="month">Monthly</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </CardHeader>
            <CardContent>
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart
                        data={chartData}
                        margin={{ top: 20, right: 10, left: 10, bottom: 20 }}
                    >
                        <XAxis
                            dataKey={viewType === 'quarter' ? 'quarter' : 'month'}
                            stroke="#666666"
                            tick={{ fontSize: 12 }}
                        />
                        <YAxis
                            stroke="#666666"
                            tick={{ fontSize: 12 }}
                            tickFormatter={valueFormatter}
                        />
                        <Tooltip
                            content={<CustomTooltip />}
                        />
                        <Bar dataKey="value" fill={color}>
                            <LabelList
                                dataKey="value"
                                position="top"
                                formatter={valueFormatter}
                                style={{ fontSize: '11px' }}
                            />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

// Add helper function to calculate average
const calculateAverage = (data: WeeklyMetric[]): number => {
    const sum = data.reduce((acc, curr) => acc + curr.value, 0);
    return Number((sum / data.length).toFixed(2));
};

// Add new component for Healthcare Metrics
const HealthcareMetricCard: React.FC<{
    title: string;
    currentValue: number;
    previousValue: number;
    icon: React.ReactNode;
    suffix?: string;
    trend?: 'up' | 'down';
    description: string;
}> = ({ title, currentValue, previousValue, icon, suffix = '%', trend, description }) => {
    const difference = currentValue - previousValue;
    const percentageChange = ((difference / previousValue) * 100).toFixed(1);
    const isPositive = difference > 0;
    const isGood = (trend === 'up' && isPositive) || (trend === 'down' && !isPositive);

    // Format current value based on whether there's a suffix

    return (
        <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
            <CardContent className="pt-6">
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            {icon}
                            <h3 className="text-sm font-medium text-gray-600">{title}</h3>
                        </div>
                        <div
                            className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${isGood ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                }`}
                        >
                            {isPositive ? '↑' : '↓'} {Math.abs(Number(percentageChange))}%
                        </div>
                    </div>

                    <div className="flex flex-col space-y-1">
                        <p className="text-3xl font-bold text-gray-900">
                            {currentValue.toFixed(1)}{suffix}
                        </p>
                        <p className="text-sm text-gray-500">
                            {description}
                        </p>
                    </div>

                    <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500">National:</span>
                        <span className="text-sm font-medium text-gray-900">
                            {previousValue.toFixed(1)}{suffix}
                        </span>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};


// Refactored AppealRateDiagram component
const AppealRateDiagram: React.FC<{
    currentValue: number;
    previousValue: number;
    upheldRate: number;
    approvedRate: number;
}> = ({
    currentValue,
    previousValue,
    upheldRate,
    approvedRate
}) => {
        // Helper function to calculate trend
        const calculateTrend = (current: number, previous: number) => {
            const difference = current - previous;
            const percentageChange = ((difference / previous) * 100).toFixed(1);
            const isPositive = difference > 0;
            return {
                direction: isPositive ? '↑' : '↓',
                value: Math.abs(Number(percentageChange)),
                isPositive
            };
        };

        const mainTrend = calculateTrend(currentValue, previousValue);
        const upheldTrend = calculateTrend(upheldRate, upheldRate - 2);
        const approvedTrend = calculateTrend(approvedRate, approvedRate + 2);

        const TrendIndicator: React.FC<{ trend: ReturnType<typeof calculateTrend> }> = ({ trend }) => (
            <div className={`px-2.5 py-1 rounded-full text-sm font-medium 
            ${trend.isPositive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                {trend.direction} {trend.value}%
            </div>
        );

        const MetricDisplay: React.FC<{
            current: number;
            previous: number;
            label: string;
            size?: 'large' | 'medium';
            trendPosition?: 'left' | 'right';
        }> = ({ current, previous, label, size = 'medium', trendPosition = 'right' }) => {
            const trend = calculateTrend(current, previous);

            const ValueDisplay = (
                <div className="flex items-baseline gap-2">
                    <div className={`font-bold text-gray-900 ${size === 'large' ? 'text-4xl' : 'text-3xl'}`}>
                        {current}%
                    </div>
                    <div className="text-gray-400 text-lg">
                        ({previous}%)
                    </div>
                </div>
            );

            return (
                <div>
                    <div className="flex items-center gap-3">
                        {trendPosition === 'left' && <TrendIndicator trend={trend} />}
                        {ValueDisplay}
                        {trendPosition === 'right' && <TrendIndicator trend={trend} />}
                    </div>
                    <div className="text-sm text-gray-500 mt-1">{label}</div>
                </div>
            );
        };

        return (
            <Card className="w-full h-full bg-gradient-to-br from-gray-100 to-white">
                <CardContent className="pt-6 h-full flex flex-col">
                    <div className="flex flex-col h-full">
                        {/* Header with title */}
                        <div className="flex items-center mb-4">
                            <div className="flex items-center space-x-2">
                                {METRICS_CONFIG.appealRate.icon}
                                <h3 className="text-sm font-medium text-gray-600">
                                    {METRICS_CONFIG.appealRate.title}
                                </h3>
                            </div>
                        </div>

                        {/* Main content with flow diagram */}
                        <div className="flex-1 flex items-center justify-between space-x-4">
                            {/* Initial Rate */}
                            <MetricDisplay
                                current={currentValue}
                                previous={previousValue}
                                label="Annual Appeal Rate"
                                size="large"
                                trendPosition="left"
                            />

                            {/* Flow Lines and Results */}
                            <div className="flex-1 relative h-48">
                                <svg className="absolute inset-0 w-full h-full" viewBox="0 0 400 300" preserveAspectRatio="xMidYMid meet">
                                    {/* Top flow line - adjusted y coordinates */}
                                    <path
                                        d="M 0,150 C 140,150 200,0 340,0"
                                        fill="none"
                                        stroke="#000000"
                                        strokeWidth="8"
                                    />
                                    <path
                                        d="M 340,0 L 380,0 L 360,-20 L 380,0 L 360,20"
                                        fill="none"
                                        stroke="#000000"
                                        strokeWidth="8"
                                    />

                                    {/* Bottom flow line - with higher separation */}
                                    <path
                                        d="M 0,150 C 140,150 200,300 340,300"
                                        fill="none"
                                        stroke="#000000"
                                        strokeWidth="8"
                                    />
                                    <path
                                        d="M 340,300 L 380,300 L 360,280 L 380,300 L 360,320"
                                        fill="none"
                                        stroke="#000000"
                                        strokeWidth="8"
                                    />
                                </svg>
                            </div>

                            {/* Results Column */}
                            <div className="space-y-4">
                                <MetricDisplay
                                    current={upheldRate}
                                    previous={upheldRate - 2}
                                    label="Upheld"
                                />
                                <MetricDisplay
                                    current={approvedRate}
                                    previous={approvedRate + 2}
                                    label="Overturned"
                                />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    };

type PathwayData = {
    label: string;
    value: number;
    total: number;
}[];

const CasePathwayChart: React.FC<{
    monthlyData: PathwayData;
    yearlyData: PathwayData;
}> = ({
    monthlyData = [
        { label: "More Info", value: 125, total: 430 },      // ~29%
        { label: "Medical Director", value: 85, total: 430 }, // ~20%
        { label: "Nurses", value: 220, total: 430 },         // ~51%
    ],
    yearlyData = [
        { label: "More Info", value: 1450, total: 5030 },    // ~29%
        { label: "Medical Director", value: 980, total: 5030 }, // ~19%
        { label: "Nurses", value: 2600, total: 5030 },       // ~52%
    ] }
) => {
        const calculatePercentages = (data: PathwayData) => {
            const totalValue = data.reduce((sum, item) => sum + item.value, 0);
            return {
                data: data.map(item => ({
                    ...item,
                    percentage: Number(((item.value / totalValue) * 100).toFixed(1))
                })),
                total: totalValue
            };
        };

        const currentMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date());
        const currentYear = new Date().getFullYear();

        const HorizontalBar: React.FC<{
            data: PathwayData;
            title: string;
        }> = ({ data, title }) => {
            const { data: dataWithPercentages, total } = calculatePercentages(data);

            return (
                <div className="space-y-2 flex-1">
                    <p className="text-sm font-medium text-gray-600">
                        <span className="font-bold">{title}</span> ({total} cases)
                    </p>
                    <div className="space-y-3">
                        {dataWithPercentages.map((item, index) => (
                            <div key={index} className="space-y-1">
                                <div className="flex justify-between text-sm">
                                    <span className="text-gray-600">{item.label}</span>
                                    <span className="font-medium">
                                        {item.percentage}%
                                    </span>
                                </div>
                                <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                                    <div
                                        className="h-full bg-cyan-500 rounded-full"
                                        style={{
                                            width: `${item.percentage}%`,
                                            backgroundColor: METRICS_CONFIG.casePathway.color
                                        }}
                                    />
                                </div>
                                <div className="text-xs text-gray-500">
                                    {item.value} cases
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        };

        return (
            <Card className="w-full h-full bg-gradient-to-br from-gray-100 to-white">
                <CardContent className="pt-6 h-full flex flex-col">
                    <div className="flex flex-col h-full">
                        {/* Header */}
                        <div className="flex items-center space-x-2 mb-6">
                            {METRICS_CONFIG.casePathway.icon}
                            <h3 className="text-sm font-medium text-gray-600">
                                {METRICS_CONFIG.casePathway.title}
                            </h3>
                        </div>

                        {/* Charts side by side */}
                        <div className="flex-1 flex gap-6">
                            <HorizontalBar
                                data={monthlyData}
                                title="Monthly"
                            />
                            <div className="w-px bg-gray-200" /> {/* Vertical separator */}
                            <HorizontalBar
                                data={yearlyData}
                                title="Yearly"
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>
        );
    };

// First, let's expand our data generation utilities

const getCurrentDate = () => {
    // return new Date('2024-11-06T12:00:00-08:00');
    const date = new Date().toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
    });
    return new Date(date);
};

const getDayOfYear = () => {
    const now = getCurrentDate();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now.getTime() - start.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
};

const generateMetricValue = (baseValue: number, variance: number = 0.1) => {
    return Math.round(baseValue * (1 + (Math.random() * 2 - 1) * variance));
};

const generateDailyMetrics = () => {
    return {
        totalCases: generateMetricValue(42),
        averageTime: generateMetricValue(25, 0.05), // minutes
        slaMet: generateMetricValue(95, 0.02), // percentage
        approvalRate: generateMetricValue(88, 0.03), // percentage
    };
};

const generateHealthcareMetrics = () => {
    return {
        admissionRate: {
            current: generateMetricValue(22, 0.1),
            previous: generateMetricValue(20, 0.1),
        },
        lengthOfStay: {
            current: Number((generateMetricValue(42, 0.05) / 10).toFixed(1)), // 4.2 days
            previous: Number((generateMetricValue(45, 0.05) / 10).toFixed(1)), // 4.5 days
        },
        readmissionRate: {
            current: Number((generateMetricValue(83, 0.05) / 10).toFixed(1)), // 8.3%
            previous: Number((generateMetricValue(91, 0.05) / 10).toFixed(1)), // 9.1%
        },
        utilizationRate: {
            current: generateMetricValue(325, 0.05),
            previous: generateMetricValue(310, 0.05),
        }
    };
};

const generateAppealRateData = () => {
    const currentValue = Number((generateMetricValue(8, 0.1) / 10).toFixed(1)); // 0.8%
    return {
        currentValue,
        previousValue: Number((generateMetricValue(9, 0.1) / 10).toFixed(1)), // 0.9%
        upheldRate: generateMetricValue(17, 0.05),
        approvedRate: generateMetricValue(83, 0.05),
    };
};

const generatePathwayData = () => {
    const generateDataSet = (totalCases: number) => {
        const nurseCases = generateMetricValue(Math.round(totalCases * 0.51)); // ~51%
        const moreCases = generateMetricValue(Math.round(totalCases * 0.29)); // ~29%
        const mdCases = totalCases - nurseCases - moreCases; // remainder

        return [
            { label: "More Info", value: moreCases, total: totalCases },
            { label: "Medical Director", value: mdCases, total: totalCases },
            { label: "Nurses", value: nurseCases, total: totalCases },
        ];
    };

    return {
        monthlyData: generateDataSet(430),
        yearlyData: generateDataSet(5030),
    };
};
const formatters = {
    time: (value: number) => `${value} min`,
    percentage: (value: number) => `${value}%`,
    number: (value: number) => `${Math.round(value)}`,
};



const generateCPTCodesData = () => {
    const currentDate = getCurrentDate();
    const dayOfYear = Math.floor((currentDate.getTime() - new Date(currentDate.getFullYear(), 0, 0).getTime()) / (1000 * 60 * 60 * 24));

    // Scale the approvals based on day of year
    const yearProgress = dayOfYear / 365;

    return {
        mostExpensive: [
            { rank: 1, code: "33533", description: "CABG, arterial, single", approvals: Math.round(120 * yearProgress), averageCost: 35000.00 },
            { rank: 2, code: "33249", description: "Insertion of pacemaker", approvals: Math.round(95 * yearProgress), averageCost: 32000.00 },
            { rank: 3, code: "27447", description: "Total knee arthroplasty", approvals: Math.round(150 * yearProgress), averageCost: 30000.00 },
            { rank: 4, code: "47562", description: "Laparoscopic cholecystectomy", approvals: Math.round(200 * yearProgress), averageCost: 28000.00 },
            { rank: 5, code: "29827", description: "Arthroscopy, shoulder", approvals: Math.round(180 * yearProgress), averageCost: 26000.00 }
        ],
        leastExpensive: [
            { rank: 1, code: "99213", description: "Office visit, est patient, 15 min", approvals: Math.round(5000 * yearProgress), averageCost: 100.00 },
            { rank: 2, code: "97110", description: "Therapeutic exercises", approvals: Math.round(4500 * yearProgress), averageCost: 120.00 },
            { rank: 3, code: "90834", description: "Psychotherapy, 45 minutes", approvals: Math.round(3800 * yearProgress), averageCost: 150.00 },
            { rank: 4, code: "81001", description: "Urinalysis, automated", approvals: Math.round(7000 * yearProgress), averageCost: 180.00 },
            { rank: 5, code: "85025", description: "Complete blood count", approvals: Math.round(6500 * yearProgress), averageCost: 200.00 }
        ]
    };
};

// Add helper function to aggregate nurse metrics
const aggregateNurseMetrics = (selectedNurses: Set<string>, nurses: Nurse[]) => {
    if (selectedNurses.size === 0) return null;

    const selectedNurseData = nurses.filter(nurse => selectedNurses.has(nurse.id));
    
    // Calculate totals and averages
    return {
        totalCases: selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0),
        averageTime: selectedNurseData.reduce((sum, nurse) => sum + nurse.averageTime * nurse.casesReviewed, 0) / 
                    selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0), // Weighted average
        slaCompliance: selectedNurseData.reduce((sum, nurse) => sum + nurse.slaCompliance * nurse.casesReviewed, 0) /
                      selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0), // Weighted average
        approvalRate: selectedNurseData.reduce((sum, nurse) => sum + nurse.approvalRate * nurse.casesReviewed, 0) /
                     selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0), // Weighted average
    };
};

// Add a cache to store generated data
const dataCache = new Map<string, any>();


const calculateDailyProgress = (dailyAverageCases: number, averageTimePerCase: number) => {
    const currentDate = getCurrentDate();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    
    const currentTimeInMinutes = (currentHour * 60) + currentMinute;
    
    // 8 AM
    const workDayStartMinutes = 8 * 60;
    // 5 PM
    const workDayEndMinutes = 17 * 60;   
    const workDayLengthMinutes = workDayEndMinutes - workDayStartMinutes;

    // Before work day starts, return 0
    if (currentTimeInMinutes < workDayStartMinutes) {
        return 0;
    }
    
    // After work day ends, return full daily average
    if (currentTimeInMinutes > workDayEndMinutes) {
        return dailyAverageCases;
    }

    // progress through the work day
    const minutesWorked = currentTimeInMinutes - workDayStartMinutes;
    
    // Calculate progress based on time and case
    const timeBasedProgress = minutesWorked / workDayLengthMinutes;
    const theoreticalCasesCompleted = Math.min(
        (minutesWorked / averageTimePerCase),
        dailyAverageCases
    );
    const caseBasedProgress = theoreticalCasesCompleted / dailyAverageCases;

    // Weight the progress (70% time-based, 30% case-based)
    const weightedProgress = (timeBasedProgress * 0.7) + (caseBasedProgress * 0.3);
    
    return Math.round(dailyAverageCases * weightedProgress);
};

const getWeeklyPredictionValue = (
    currentDayValue: number, 
    dayIndex: number, 
    metricType: 'cases' | 'time' | 'compliance' | 'approval',
    baseValue: number,
    dayMultipliers: Record<string, number[]>,
    currentDate: Date
) => {
    const currentDayIndex = currentDate.getDay() - 1;
    const daysAhead = dayIndex - currentDayIndex;
    
    // Get the last few days' trend
    const recentTrend = dayIndex > 0 ? 
        (currentDayValue / (baseValue * dayMultipliers[metricType][dayIndex - 1])) - 1 : 0;

    switch(metricType) {
        case 'cases':
            return baseValue * dayMultipliers.cases[dayIndex] * (1 + (recentTrend * 0.5));
            
        case 'time':
            return baseValue * dayMultipliers.time[dayIndex] * (1 + (daysAhead * 0.02));
            
        case 'compliance':
            const predictedCompliance = baseValue * dayMultipliers.compliance[dayIndex] * 
                (1 - (daysAhead * 0.01));
            return Math.min(100, Math.max(90, predictedCompliance));
            
        case 'approval':
            const predictedApproval = baseValue * dayMultipliers.approval[dayIndex] * 
                (1 + (recentTrend * 0.3));
            return Math.min(100, Math.max(80, predictedApproval));
            
        default:
            return baseValue * dayMultipliers[metricType][dayIndex];
    }
};

const generateWeeklyData = (baseValue: number, metricType: 'cases' | 'time' | 'compliance' | 'approval', weightedAverageTime: number) => {
    const currentDate = getCurrentDate();
    const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    
    // Fix multipliers for each day
    const dayMultipliers = {
        cases: [1.15, 0.95, 1.0, 1.1, 0.8],
        time: [0.9, 1.0, 1.0, 1.05, 1.1], 
        compliance: [1.02, 1.0, 0.98, 0.97, 0.95],
        approval: [1.0, 1.02, 1.0, 0.98, 0.97]
    };

    const currentDay = currentDate.getDay();
    const daysToInclude = Math.min(5, currentDay === 0 ? 5 : currentDay);
    
    return weekDays.map((day, index) => {
        const isPrediction = index >= (currentDay === 0 ? 5 : currentDay);
        let value: number;

        if (index === currentDay - 1 && !isPrediction) {
            // Current day's actual value
            if (metricType === 'cases') {
                value = calculateDailyProgress(baseValue, weightedAverageTime);
            } else {
                value = baseValue * dayMultipliers[metricType][index];
            }
        } else if (isPrediction) {
            // Predicted value
            value = getWeeklyPredictionValue(
                index > 0 ? baseValue * dayMultipliers[metricType][index - 1] : baseValue,
                index,
                metricType,
                baseValue,
                dayMultipliers,
                currentDate
            );
        } else {
            // Historical value
            value = baseValue * dayMultipliers[metricType][index];
        }

        // Final adjustments and rounding
        if (metricType === 'compliance' || metricType === 'approval') {
            value = Math.min(100, Math.max(0, value));
        }

        return {
            day,
            value: Math.round(value * 100) / 100,
            isPrediction
        };
    });
};

const generateYearlyData = (baseValue: number, metricType: 'cases' | 'time' | 'compliance' | 'approval', totalCases?: number) => {
    const quarters = [];
    const monthsPerQuarter = 3;
    const currentDate = getCurrentDate();

    const formatValue = (value: number, type: string): number => {
        if (type === 'cases') {
            return Math.round(value);
        }
        return Number(Number(value).toFixed(2));
    };
    
    const currentQuarter = Math.floor(currentDate.getMonth() / 3);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const quarterlyPatterns = {
        // Q1 slower, Q2 busy, Q3 vacation, Q4 normal
        cases: [0.95, 1.15, 0.90, 1.0],    
        // Better times in Q2, slower in Q3
        time: [1.0, 0.95, 1.1, 1.05],      
        // Small variations in compliance
        compliance: [0.97, 0.95, 0.93, 0.94], 
        // Realistic approval rate variations
        approval: [0.89, 0.91, 0.87, 0.88]    
    };

    let quarterlyBase;
    if (metricType === 'cases') {
        // For cases, use the daily average * working days per quarter
        // ~60 working days per quarter
        const workingDaysPerQuarter = 60; 
        quarterlyBase = baseValue * workingDaysPerQuarter;
    } else if (metricType === 'compliance' || metricType === 'approval') {
        quarterlyBase = baseValue;
    } else {
        quarterlyBase = baseValue;
    }

    for (let quarter = 0; quarter <= currentQuarter; quarter++) {
        const monthlyData = [];
        const quarterPattern = quarterlyPatterns[metricType][quarter];
        const monthLimit = quarter === currentQuarter ? (currentDate.getMonth() % 3) + 1 : 3;
        
        let quarterTotal = 0;
        for (let month = 0; month < monthLimit; month++) {
            const monthIndex = quarter * 3 + month;
            
            // Calculate monthly value with slight variation of ±2%
            let value = quarterlyBase * quarterPattern;
            const randomFactor = 0.98 + (Math.random() * 0.04);
            value *= randomFactor;

            if (metricType === 'compliance' || metricType === 'approval') {
                value = Math.min(100, Math.max(0, value));
            }

            const monthValue = formatValue(value, metricType);
            quarterTotal += monthValue;
            
            monthlyData.push({
                month: monthNames[monthIndex],
                value: monthValue
            });
        }

        const quarterValue = monthlyData.length > 0 ? quarterTotal / monthlyData.length : 0;

        quarters.push({
            quarter: `Q${quarter + 1}`,
            value: formatValue(quarterValue, metricType),
            months: monthlyData
        });
    }

    return quarters;
};

const generateDashboardData = (selectedNurses: Set<string>) => {
    // Create a cache key based on selected nurses
    const cacheKey = Array.from(selectedNurses).sort().join(',');
    
    // Check if we have cached data
    if (dataCache.has(cacheKey)) {
        return dataCache.get(cacheKey);
    }

    const currentDate = getCurrentDate();

    // For company view, use all nurses' data
    const relevantNurses = selectedNurses.size === 0 ? NURSES : 
        NURSES.filter(nurse => selectedNurses.has(nurse.id));

    
    // Calculate total cases and weighted averages
    const totalCases = relevantNurses.reduce((sum, nurse) => sum + nurse.casesReviewed, 0);
    const weightedAverageTime = relevantNurses.reduce((sum, nurse) => 
        sum + (nurse.averageTime * nurse.casesReviewed), 0) / totalCases;
    const weightedSLACompliance = relevantNurses.reduce((sum, nurse) => 
        sum + (nurse.slaCompliance * nurse.casesReviewed), 0) / totalCases;
    const weightedApprovalRate = relevantNurses.reduce((sum, nurse) => 
        sum + (nurse.approvalRate * nurse.casesReviewed), 0) / totalCases;

    // Calculate daily average (assuming 240 working days per year)
    const dailyAverageCases = Math.round(totalCases / 240);
    console.log('dailyAverageCases', dailyAverageCases);

    const currentDay = currentDate.getDay();
    const weeklyMetrics = {
        totalCases: generateWeeklyData(dailyAverageCases, 'cases', weightedAverageTime),
        averageTime: generateWeeklyData(weightedAverageTime, 'time', weightedAverageTime),
        slaCompliance: generateWeeklyData(weightedSLACompliance, 'compliance', weightedAverageTime),
        approvalRate: generateWeeklyData(weightedApprovalRate, 'approval', weightedAverageTime)
    };

    const yearlyMetrics = {
        totalCases: generateYearlyData(dailyAverageCases, 'cases'),
        averageTime: generateYearlyData(weightedAverageTime, 'time'),
        slaCompliance: generateYearlyData(weightedSLACompliance, 'compliance'),
        approvalRate: generateYearlyData(weightedApprovalRate, 'approval')
    }

    const dayIndex = currentDate.getDay();
    const todayTotalCases = Math.round(calculateDailyProgress(dailyAverageCases, weightedAverageTime));
    
    console.log('dayIndex', dayIndex);
    const weekdayIndex = dayIndex === 0 || dayIndex === 6 ? 4 : dayIndex - 1;
    weeklyMetrics.totalCases[weekdayIndex].value = todayTotalCases;
    const data = {
        currentDate,
        dailyMetrics: {
            totalCases: todayTotalCases,
            averageTime: weeklyMetrics.averageTime[weekdayIndex].value,
            slaMet: (weeklyMetrics.slaCompliance[weekdayIndex].value),
            approvalRate: weeklyMetrics.approvalRate[weekdayIndex].value
        },
        weeklyMetrics,
        yearlyMetrics,
        healthcareMetrics: generateHealthcareMetricsForNurses(selectedNurses),
        appealRate: generateAppealRateDataForNurses(selectedNurses),
        pathwayData: generatePathwayDataForNurses(selectedNurses),
        cptCodes: generateCPTCodesForNurses(selectedNurses),
        pmpmMetrics: generatePMPMMetrics(selectedNurses),
    };

    // Cache the generated data
    dataCache.set(cacheKey, data);
    return data;
};

// ! inpatient_metrics
// - Admission Rate: ~80-120 per 1000 members per year
// - Length of Stay: Average 4-6 days
// - Readmission Rate: 12-18%
// - Utilization Rate: 280-320 days per 1000 members
const NATIONAL_AVERAGES = {
    // National average: 104 admits per 1,000 population
    admissionRate: 104, 
    // National average: 4.6 days
    lengthOfStay: 4.6,   
    // National average: 15.2% 30-day readmission rate 
    readmissionRate: 15.2 
};

const METRIC_ADJUSTMENTS = {
    admissionRate: {
        baseRange: { min: 85, max: 125 },   
        nationalAverage: NATIONAL_AVERAGES.admissionRate               
    },
    lengthOfStay: {
        baseRange: { min: 4, max: 6 },   
        nationalAverage: NATIONAL_AVERAGES.lengthOfStay               
    },
    readmissionRate: {
        baseRange: { min: 12, max: 18 },
        // Previous data was 15% higher
        previousMultiplier: 1.15,
        nationalAverage: NATIONAL_AVERAGES.readmissionRate               
    },
    utilizationRate: {
        baseRange: { min: 280, max: 320 },    
        // Previous data was 10% higher
        previousMultiplier: 1.10               
    }
};
const generateAdmissionHistory = (numberOfRecords: number = 3000): AdmissionRecord[] => {
    const admissions: AdmissionRecord[] = [];
    const currentDate = getCurrentDate();
    // average los are 3-5 days
    // 60% between 3-5 days
    // 25% between 6-8 days
    // 15% between 9-15 days
    const generateLOS = (): number => {
        const rand = Math.random();
        if (rand < 0.60) return 3 + Math.floor(Math.random() * 3); 
        if (rand < 0.85) return 6 + Math.floor(Math.random() * 3);
        return 9 + Math.floor(Math.random() * 7); 
    };

    for (let i = 0; i < numberOfRecords; i++) {
        const date = new Date(currentDate);
        const daysAgo = Math.floor(Math.random() * 365);
        date.setDate(date.getDate() - daysAgo);
        
        // 60% fewer weekend admissions
        if (date.getDay() === 0 || date.getDay() === 6) {
            if (Math.random() > 0.4) continue; 
        }

        admissions.push({
            id: `ADM${i.toString().padStart(6, '0')}`,
            date: date,
            lengthOfStay: generateLOS(),
            nurseId: NURSES[Math.floor(Math.random() * NURSES.length)].id
        });
    }
    
    return admissions.sort((a, b) => b.date.getTime() - a.date.getTime());
};

// population size
const MEMBER_POPULATION = 100000; 

const calculateAdmitRate = (admissions: AdmissionRecord[]): number => {
    if (admissions.length === 0) return 0;
    
    const timeSpanDays = (admissions[0].date.getTime() - admissions[admissions.length - 1].date.getTime()) 
        / (1000 * 60 * 60 * 24);
    
    // hospital admission rates (5-15 per 1000 per month)
    const admitsPerDay = admissions.length / timeSpanDays;
    const annualRate = Math.round((admitsPerDay * 365 * 1000) / MEMBER_POPULATION);
    
    // Target range: 85-125 admits per 1,000 per year
    return (Math.min(125, Math.max(85, annualRate)));
};

const calculateReadmissionRate = (admissions: AdmissionRecord[]): number => {
    if (admissions.length === 0) return 0;
    
    let readmissions = 0;
    const thirtyDays = 30 * 24 * 60 * 60 * 1000;
    
    admissions.forEach((admission, index) => {
        const previousAdmission = admissions.slice(index + 1).find(prev => 
            admission.date.getTime() - prev.date.getTime() <= thirtyDays
        );
        if (previousAdmission) readmissions++;
    });
    
    // Target range: 10-20% readmission rate
    const rate = (readmissions / admissions.length) * 100;
    return Number(Math.min(20, Math.max(10, rate)).toFixed(1));
};

const calculateUtilizationRate = (admissions: AdmissionRecord[]): number => {
    if (admissions.length === 0) return 0;
    
    const totalPatientDays = admissions.reduce((sum, adm) => sum + adm.lengthOfStay, 0);
    const periodDays = (admissions[0].date.getTime() - admissions[admissions.length - 1].date.getTime()) 
        / (1000 * 60 * 60 * 24);
    
    // Target range: 250-350 patient days per 1000 members per year
    const utilizationRate = (totalPatientDays / periodDays) * (1000 / MEMBER_POPULATION) * 365;
    return Number(Math.min(350, Math.max(250, utilizationRate)).toFixed(1));
};

// Add these new helper functions for generating nurse-specific data
const generateHealthcareMetricsForNurses = (selectedNurses: Set<string>) => {
    const admissionHistory = generateAdmissionHistory(3000);
    
    const relevantAdmissions = selectedNurses.size === 0 
        ? admissionHistory 
        : admissionHistory.filter(admission => selectedNurses.has(admission.nurseId));
    
    // Split into current and previous periods
    const currentThousand = relevantAdmissions.slice(0, 1000);

    const calculateAdjustedMetrics = () => {
        // admission Rate
        const baseAdmitRate = calculateAdmitRate(currentThousand);
        const currentAdmitRate = Math.min(
            METRIC_ADJUSTMENTS.admissionRate.baseRange.max,
            Math.max(METRIC_ADJUSTMENTS.admissionRate.baseRange.min, baseAdmitRate)
        );
        const admitRateVsNational = ((currentAdmitRate - NATIONAL_AVERAGES.admissionRate) / NATIONAL_AVERAGES.admissionRate) * 100;


        // length of stay
        const baseLOS = currentThousand.reduce((sum, adm) => sum + adm.lengthOfStay, 0) / currentThousand.length;
        const currentLOS = Math.min(
            METRIC_ADJUSTMENTS.lengthOfStay.baseRange.max,
            Math.max(METRIC_ADJUSTMENTS.lengthOfStay.baseRange.min, baseLOS)
        );
        const losVsNational = ((currentLOS - NATIONAL_AVERAGES.lengthOfStay) / NATIONAL_AVERAGES.lengthOfStay) * 100;

        // readmission rate
        const baseReadmission = calculateReadmissionRate(currentThousand);
        const currentReadmission = Math.min(
            METRIC_ADJUSTMENTS.readmissionRate.baseRange.max,
            Math.max(METRIC_ADJUSTMENTS.readmissionRate.baseRange.min, baseReadmission)
        );
        const previousReadmission = currentReadmission * METRIC_ADJUSTMENTS.readmissionRate.previousMultiplier;

        // utilization rate
        const baseUtilization = calculateUtilizationRate(currentThousand);
        const currentUtilization = Math.min(
            METRIC_ADJUSTMENTS.utilizationRate.baseRange.max,
            Math.max(METRIC_ADJUSTMENTS.utilizationRate.baseRange.min, baseUtilization)
        );
        const previousUtilization = currentUtilization * METRIC_ADJUSTMENTS.utilizationRate.previousMultiplier;

        return {
            admissionRate: {
                current: Math.round(currentAdmitRate),
                previous: (NATIONAL_AVERAGES.admissionRate),
                trend: currentAdmitRate < NATIONAL_AVERAGES.admissionRate ? "down" : "up",
                description: "Admits per 1000"
            },
            lengthOfStay: {
                current: Number(currentLOS.toFixed(1)),
                previous: Number(NATIONAL_AVERAGES.lengthOfStay.toFixed(1)),
                trend: currentLOS < NATIONAL_AVERAGES.lengthOfStay ? "down" : "up",
                description: "Average LOS per 1000"
            },
            readmissionRate: {
                current: Number(currentReadmission.toFixed(1)),
                previous: Number(previousReadmission.toFixed(1)),
                trend: currentReadmission < NATIONAL_AVERAGES.readmissionRate ? "down" : "up",
                description: "30-day readmission rate"
            },
            utilizationRate: {
                current: Number(currentUtilization.toFixed(1)),
                previous: Number(previousUtilization.toFixed(1)),
                trend: "down",
                description: "Cases per 1000 members"
            }
        };
    };

    return calculateAdjustedMetrics();
    // const relevantNurses = selectedNurses.size === 0 ? NURSES : 
    //     NURSES.filter(nurse => selectedNurses.has(nurse.id));
    
    // const totalCases = relevantNurses.reduce((sum, nurse) => sum + nurse.casesReviewed, 0);
    
    // // Use deterministic calculations based on actual nurse data
    // const calculateMetric = (baseValue: number) => {
    //     return relevantNurses.reduce((sum, nurse) => {
    //         const weight = nurse.casesReviewed / totalCases;
    //         return sum + (baseValue * weight * (nurse.approvalRate / 100));
    //     }, 0);
    // };

    // const current = {
    //     admissionRate: calculateMetric(22),
    //     lengthOfStay: calculateMetric(4.2),
    //     readmissionRate: calculateMetric(8.3),
    //     utilizationRate: calculateMetric(325)
    // };

    // // Previous values are slightly different but still deterministic
    // const previous = {
    //     admissionRate: calculateMetric(20),
    //     lengthOfStay: calculateMetric(4.5),
    //     readmissionRate: calculateMetric(9.1),
    //     utilizationRate: calculateMetric(310)
    // };

    // return {
    //     admissionRate: { current: current.admissionRate, previous: previous.admissionRate },
    //     lengthOfStay: { current: current.lengthOfStay, previous: previous.lengthOfStay },
    //     readmissionRate: { current: current.readmissionRate, previous: previous.readmissionRate },
    //     utilizationRate: { current: current.utilizationRate, previous: previous.utilizationRate }
    // };
};


// ! appeal_metrics
const generateAppealRateDataForNurses = (selectedNurses: Set<string>) => {
    // if (selectedNurses.size === 0) return generateAppealRateData();

    // const selectedNurseData = NURSES.filter(nurse => selectedNurses.has(nurse.id));
    // const totalCases = selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0);

    // // Calculate weighted appeal rates
    // const weightedApprovalRate = selectedNurseData.reduce((sum, nurse) => 
    //     sum + (nurse.approvalRate * nurse.casesReviewed / totalCases), 0);
    
    // // Assume appeal rate is inversely related to approval rate
    // const baseAppealRate = (100 - weightedApprovalRate) * 0.1;
    
    // return {
    //     currentValue: Number(baseAppealRate.toFixed(1)),
    //     previousValue: Number((baseAppealRate * (1 + (Math.random() * 0.2 - 0.1))).toFixed(1)),
    //     upheldRate: Math.round(weightedApprovalRate * 0.2), // 20% of appeals upheld
    //     approvedRate: Math.round(100 - (weightedApprovalRate * 0.2)), // Remainder approved on appeal
    // };
    if (selectedNurses.size === 0) return generateAppealRateData();
    const selectedNurseData = NURSES.filter(nurse => selectedNurses.has(nurse.id));

    // Calculate yearly metrics
    const yearlyData = {
        totalCases: selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0),
        approvalRate: selectedNurseData.reduce((sum, nurse) => 
            sum + (nurse.approvalRate * nurse.casesReviewed), 0) / 
            selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0)
    };

    // Previous year data (assume 5% different)
    const previousYearData = {
        totalCases: Math.round(yearlyData.totalCases * 0.95),
        approvalRate: yearlyData.approvalRate * 1.05
    };

    // Calculate appeal rates based on approval rates
    // appeal rate = (100 - approvalRate) * 0.1
    const calculateAppealRate = (approvalRate: number) => {
        return Number(((100 - approvalRate) * 0.1).toFixed(1));
    };

    const currentAppealRate = calculateAppealRate(yearlyData.approvalRate);
    const previousAppealRate = calculateAppealRate(previousYearData.approvalRate);

    // Calculate upheld and approved rates for appeals
    const upheldRate = Math.round(yearlyData.approvalRate * 0.2); // 20% of appeals upheld
    const approvedRate = 100 - upheldRate; // Remainder approved on appeal

    return {
        currentValue: currentAppealRate,
        previousValue: previousAppealRate,
        upheldRate,
        approvedRate,
    };
};

const generatePathwayDataForNurses = (selectedNurses: Set<string>) => {
    if (selectedNurses.size === 0) return generatePathwayData();

    const selectedNurseData = NURSES.filter(nurse => selectedNurses.has(nurse.id));
    const totalCases = selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0);

    // Calculate monthly and yearly case distributions
    const monthlyTotal = Math.round(totalCases / 12);
    const yearlyTotal = totalCases;

    // Distribution ratios (adjust based on nurse department)
    const calculateDistribution = (total: number) => {
        const hasPharmacy = selectedNurseData.some(nurse => nurse.department === 'Pharmacy');
        const hasInpatient = selectedNurseData.some(nurse => nurse.department === 'Inpatient');
        
        let nurseRatio = 0.5;
        let moreInfoRatio = 0.3;
        let mdRatio = 0.2;

        if (hasPharmacy) {
            nurseRatio = 0.6;
            moreInfoRatio = 0.25;
            mdRatio = 0.15;
        }
        if (hasInpatient) {
            nurseRatio = 0.45;
            moreInfoRatio = 0.3;
            mdRatio = 0.25;
        }

        return [
            { label: "More Info", value: Math.round(total * moreInfoRatio), total },
            { label: "Medical Director", value: Math.round(total * mdRatio), total },
            { label: "Nurses", value: Math.round(total * nurseRatio), total },
        ];
    };

    return {
        monthlyData: calculateDistribution(monthlyTotal),
        yearlyData: calculateDistribution(yearlyTotal),
    };
};

const generateCPTCodesForNurses = (selectedNurses: Set<string>) => {
    if (selectedNurses.size === 0) return generateCPTCodesData();

    const selectedNurseData = NURSES.filter(nurse => selectedNurses.has(nurse.id));
    const totalCases = selectedNurseData.reduce((sum, nurse) => sum + nurse.casesReviewed, 0);
    const dayOfYear = getDayOfYear();
    const yearProgress = dayOfYear / 365;

    // Adjust CPT code distribution based on nurse departments and current date
    const departments = new Set(selectedNurseData.map(nurse => nurse.department));
    
    const generateDepartmentSpecificCPT = () => {
        const baseData = {
            mostExpensive: [
                { rank: 1, code: "33533", description: "CABG, arterial, single", approvals: 0, averageCost: 35000.00 },
                { rank: 2, code: "33249", description: "Insertion of pacemaker", approvals: 0, averageCost: 32000.00 },
                { rank: 3, code: "27447", description: "Total knee arthroplasty", approvals: 0, averageCost: 30000.00 },
                { rank: 4, code: "47562", description: "Laparoscopic cholecystectomy", approvals: 0, averageCost: 28000.00 },
                { rank: 5, code: "29827", description: "Arthroscopy, shoulder", approvals: 0, averageCost: 26000.00 }
            ],
            leastExpensive: [
                { rank: 1, code: "99213", description: "Office visit, est patient, 15 min", approvals: 0, averageCost: 100.00 },
                { rank: 2, code: "97110", description: "Therapeutic exercises", approvals: 0, averageCost: 120.00 },
                { rank: 3, code: "90834", description: "Psychotherapy, 45 minutes", approvals: 0, averageCost: 150.00 },
                { rank: 4, code: "81001", description: "Urinalysis, automated", approvals: 0, averageCost: 180.00 },
                { rank: 5, code: "85025", description: "Complete blood count", approvals: 0, averageCost: 200.00 }
            ]
        };

        // Adjust approvals based on department and case volume
        const casesPerCode = totalCases / (baseData.mostExpensive.length + baseData.leastExpensive.length);
        
        baseData.mostExpensive = baseData.mostExpensive.map(code => ({
            ...code,
            approvals: Math.round(casesPerCode * yearProgress * (
                departments.has('Inpatient') ? 1.2 :
                departments.has('Outpatient') ? 0.8 : 0.6
            ))
        }));

        baseData.leastExpensive = baseData.leastExpensive.map(code => ({
            ...code,
            approvals: Math.round(casesPerCode * yearProgress * (
                departments.has('Pharmacy') ? 1.3 :
                departments.has('Outpatient') ? 1.1 : 0.9
            ))
        }));

        return baseData;
    };

    return generateDepartmentSpecificCPT();
};

const generatePMPMMetrics = (selectedNurses: Set<string>): PMPMMetrics => {
    const selectedNurseData = selectedNurses.size === 0 ? 
        NURSES.filter(nurse => nurse.department !== 'Pharmacy') : 
        NURSES.filter(nurse => selectedNurses.has(nurse.id) && nurse.department !== 'Pharmacy');

    const basePMPM = 24.50;
    const calculatePMPM = (nurses: typeof NURSES) => {
        const totalCases = nurses.reduce((sum, nurse) => sum + nurse.casesReviewed, 0);
        const weightedCost = nurses.reduce((sum, nurse) => {
            const departmentMultiplier = 
                nurse.department === 'Inpatient' ? 1.5 :
                1.0; // Outpatient
            const approvalRateImpact = (100 - nurse.approvalRate) / 100;
            return sum + (basePMPM * departmentMultiplier * (1 + approvalRateImpact));
        }, 0) / nurses.length;

        return Number(weightedCost.toFixed(2));
    };

    // - Inpatient: ~1.2-1.5 auths per patient/year
    // - Outpatient: ~2.0-2.5 auths per patient/year
    const calculateAuthsPerPatient = () => {
        const departmentCounts = {
            Inpatient: 0,
            Outpatient: 0
        };

        selectedNurseData.forEach(nurse => {
            if (nurse.department !== 'Pharmacy') {
                departmentCounts[nurse.department]++;
            }
        });

        const totalNurses = Object.values(departmentCounts).reduce((sum, count) => sum + count, 0);
        if (totalNurses === 0) return 1.8;

        const weightedSum = 
            (departmentCounts.Inpatient * 1.3) +  
            (departmentCounts.Outpatient * 2.2); 

        return Number((weightedSum / totalNurses).toFixed(1));
    };

    const currentPMPM = calculatePMPM(selectedNurseData);
    const previousPMPM = calculatePMPM(selectedNurseData) * 0.93; 
    const currentAuths = calculateAuthsPerPatient();
    // 5% lower last year
    const previousAuths = Number((currentAuths * 0.95).toFixed(1));

    return {
        pmpmCost: {
            current: currentPMPM,
            previous: Number(previousPMPM.toFixed(2)),
            percentChange: Number(((currentPMPM - previousPMPM) / previousPMPM * 100).toFixed(1))
        },
        authsPerPatient: {
            current: currentAuths,
            previous: previousAuths,
            percentChange: Number(((currentAuths - previousAuths) / previousAuths * 100).toFixed(1))
        }
    };
};

const PmpmMetrics = ({ metrics }: { metrics: PMPMMetrics }) => {
    const { pmpmCost, authsPerPatient } = metrics;
    
    return (
        <div className="grid gap-4 md:grid-cols-2">
            <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
                <CardContent className="pt-6">
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <DollarSign className="h-6 w-6 text-gray-600" />
                                <h3 className="text-sm font-medium text-gray-600">Typical PMPM Cost</h3>
                            </div>
                            <div className={`px-2.5 py-0.5 rounded-full text-xs font-medium 
                                ${pmpmCost.percentChange > 0 
                                    ? 'bg-red-100 text-red-800' 
                                    : 'bg-green-100 text-green-800'}`}>
                                {pmpmCost.percentChange > 0 ? '↑' : '↓'} {Math.abs(pmpmCost.percentChange)}%
                            </div>
                        </div>

                        <div className="flex flex-col space-y-1">
                            <p className="text-3xl font-bold text-gray-900">
                                ${pmpmCost.current}
                            </p>
                            <p className="text-sm text-gray-500">
                                Per member per month cost
                            </p>
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className="text-sm text-gray-500">Previous:</span>
                            <span className="text-sm font-medium text-gray-900">
                                ${pmpmCost.previous}
                            </span>
                        </div>
                    </div>
                </CardContent>
            </Card>

            <Card className="w-full bg-gradient-to-br from-gray-100 to-white">
                <CardContent className="pt-6">
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2">
                                <FileText className="h-6 w-6 text-gray-600" />
                                <h3 className="text-sm font-medium text-gray-600">PMPM Cost</h3>
                            </div>
                            <div className={`px-2.5 py-0.5 rounded-full text-xs font-medium 
                                ${authsPerPatient.percentChange > 0 
                                    ? 'bg-green-100 text-green-800' 
                                    : 'bg-red-100 text-red-800'}`}>
                                {authsPerPatient.percentChange > 0 ? '↑' : '↓'} {Math.abs(authsPerPatient.percentChange)}%
                            </div>
                        </div>

                        <div className="flex flex-col space-y-1">
                            <p className="text-3xl font-bold text-gray-900">
                                {authsPerPatient.current}
                            </p>
                            <p className="text-sm text-gray-500">
                                Annual authorizations per member
                            </p>
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className="text-sm text-gray-500">Previous:</span>
                            <span className="text-sm font-medium text-gray-900">
                                {authsPerPatient.previous}
                            </span>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

// Update Dashboard component to handle view switching
export default function Dashboard() {
    const [searchTerm, setSearchTerm] = useState("");
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);
    const history = useHistory();
    const [viewType, setViewType] = useState("company");
    const [nurseSearchTerm, setNurseSearchTerm] = useState("");
    const [selectedNurses, setSelectedNurses] = useState<Set<string>>(new Set());
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    // Update useMemo to consider viewType
    const [selectedDepartments, setSelectedDepartments] = useState<Set<string>>(
        new Set(['Inpatient', 'Outpatient'])
    );

    const isOutpatientOnly = viewType === "company" && 
        selectedDepartments.size === 1 && 
        selectedDepartments.has('Outpatient');

    const dashboardData = useMemo(() => {
        if (viewType === "nurse") {
            return generateDashboardData(selectedNurses);
        } else {
            const departmentNurses = new Set(
                NURSES
                    .filter(nurse => selectedDepartments.has(nurse.department))
                    .map(nurse => nurse.id)
            );
            return generateDashboardData(departmentNurses);
        }
    }, [viewType, selectedNurses, selectedDepartments]);

    // Destructure the data for use in components
    const {
        currentDate,
        dailyMetrics,
        weeklyMetrics,
        yearlyMetrics,
        healthcareMetrics,
        appealRate,
        pathwayData,
        cptCodes,
        pmpmMetrics
    } = dashboardData;


    const currentMonthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate);
    const currentYear = currentDate.getFullYear();

    // Calculate weekly averages from actual available data
    const weeklyAverages = {
        totalCases: calculateAverage(weeklyMetrics.totalCases),
        averageTime: calculateAverage(weeklyMetrics.averageTime),
        slaCompliance: calculateAverage(weeklyMetrics.slaCompliance),
        approvalRate: calculateAverage(weeklyMetrics.approvalRate)
    };

    // Add new helper functions
    const getDepartmentNurses = (department: Department) => {
        return NURSES.filter(nurse => nurse.department === department).map(nurse => nurse.id);
    };

    const isDepartmentFullySelected = (department: Department, selectedNurses: Set<string>) => {
        const departmentNurses = getDepartmentNurses(department);
        return departmentNurses.every(nurseId => selectedNurses.has(nurseId));
    };

    const isDepartmentPartiallySelected = (department: Department, selectedNurses: Set<string>) => {
        const departmentNurses = getDepartmentNurses(department);
        return departmentNurses.some(nurseId => selectedNurses.has(nurseId)) && 
                !isDepartmentFullySelected(department, selectedNurses);
    };

    // Add useRef for click-outside detection
    const searchRef = useRef<HTMLDivElement>(null);

    // !
    const DepartmentSelector = () => (
        <div className="flex items-center gap-2 bg-gray-100 p-1 rounded-lg">
            {['Inpatient', 'Outpatient'].map(dept => (
                <button
                    key={dept}
                    onClick={() => {
                        const newDepts = new Set(selectedDepartments);
                        if (newDepts.has(dept)) {
                            if (newDepts.size > 1) { 
                                newDepts.delete(dept);
                            }
                        } else {
                            newDepts.add(dept);
                        }
                        setSelectedDepartments(newDepts);
                    }}
                    className={`px-4 py-2 rounded-md text-sm font-medium transition-all
                        ${selectedDepartments.has(dept)
                            ? 'bg-white text-blue-600 shadow-sm'
                            : 'text-gray-600 hover:text-gray-900'
                        }`}
                >
                    <div className="flex items-center gap-2">
                        {dept === 'Inpatient' ? (
                            <BedDouble className="w-4 h-4" />
                        ) : (
                            <Users className="w-4 h-4" />
                        )}
                        {dept}
                    </div>
                </button>
            ))}
        </div>
    );
    
    // Add useEffect for click-outside handling
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
                setIsSearchFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="min-h-screen p-4 sm:p-6">
            <div className="max-w-7xl mx-auto relative">
                {/* Header Section */}
                <header className="sticky top-0 z-10 bg-white mb-6">
                    <div className="container mx-auto py-4 flex flex-row justify-between items-center">
                        <h1 className="text-3xl font-bold text-black">
                            Observability Dashboard
                        </h1>
                        <div className="flex items-center gap-4">
                        {viewType === "company" ? (
                            <DepartmentSelector />
                        ) : (
                                <div className="relative w-[240px]" ref={searchRef}>
                                    <Input
                                        placeholder="Search nurses..."
                                        value={nurseSearchTerm}
                                        onChange={(e) => setNurseSearchTerm(e.target.value)}
                                        onFocus={() => setIsSearchFocused(true)}
                                        className="w-full"
                                    />
                                    {isSearchFocused && (
                                        <div className="absolute top-full mt-1 w-full bg-white rounded-lg shadow-lg border nurse-dropdown">
                                            <Command className="border-none">
                                                <CommandList>
                                                    <CommandEmpty>No nurses found.</CommandEmpty>
                                                    {/* Select All/Deselect All */}
                                                    <div className="flex items-center gap-2 px-2 py-1.5 border-b">
                                                        <div 
                                                            onClick={() => {
                                                                const filteredNurses = NURSES.filter(nurse =>
                                                                    nurse.name.toLowerCase().includes(nurseSearchTerm.toLowerCase()) ||
                                                                    nurse.department.toLowerCase().includes(nurseSearchTerm.toLowerCase())
                                                                );
                                                                const filteredIds = new Set(filteredNurses.map(nurse => nurse.id));
                                                                const areAllSelected = filteredNurses.every(nurse => 
                                                                    selectedNurses.has(nurse.id)
                                                                );
                                                                
                                                                const newSelected = new Set(selectedNurses);
                                                                if (areAllSelected) {
                                                                    // clear selection
                                                                    filteredNurses.forEach(nurse => newSelected.delete(nurse.id));
                                                                } else {
                                                                    // Select all
                                                                    filteredNurses.forEach(nurse => newSelected.add(nurse.id));
                                                                }
                                                                setSelectedNurses(newSelected);
                                                            }}
                                                            className="flex items-center gap-2 cursor-pointer hover:bg-gray-50 w-full py-1"
                                                        >
                                                            <div className={`w-4 h-4 border rounded flex items-center justify-center
                                                                ${NURSES.filter(nurse =>
                                                                    nurse.name.toLowerCase().includes(nurseSearchTerm.toLowerCase()) ||
                                                                    nurse.department.toLowerCase().includes(nurseSearchTerm.toLowerCase())
                                                                ).every(nurse => selectedNurses.has(nurse.id))
                                                                    ? 'bg-blue-500 border-blue-500'
                                                                    : 'border-gray-300'}`}
                                                            >
                                                                {NURSES.filter(nurse =>
                                                                    nurse.name.toLowerCase().includes(nurseSearchTerm.toLowerCase()) ||
                                                                    nurse.department.toLowerCase().includes(nurseSearchTerm.toLowerCase())
                                                                ).every(nurse => selectedNurses.has(nurse.id)) && (
                                                                    <span className="text-white text-xs">✓</span>
                                                                )}
                                                            </div>
                                                            <span className="text-sm text-gray-600">
                                                                {NURSES.filter(nurse =>
                                                                    nurse.name.toLowerCase().includes(nurseSearchTerm.toLowerCase()) ||
                                                                    nurse.department.toLowerCase().includes(nurseSearchTerm.toLowerCase())
                                                                ).every(nurse => selectedNurses.has(nurse.id))
                                                                    ? "Clear selection"
                                                                    : "Select all"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* Search Results */}
                                                    {NURSES
                                                        .filter(nurse => 
                                                            nurse.name.toLowerCase().includes(nurseSearchTerm.toLowerCase()) ||
                                                            nurse.department.toLowerCase().includes(nurseSearchTerm.toLowerCase())
                                                        )
                                                        .map((nurse) => (
                                                            <CommandItem
                                                                key={nurse.id}
                                                                onSelect={() => {
                                                                    const newSelected = new Set(selectedNurses);
                                                                    if (newSelected.has(nurse.id)) {
                                                                        newSelected.delete(nurse.id);
                                                                    } else {
                                                                        newSelected.add(nurse.id);
                                                                    }
                                                                    setSelectedNurses(newSelected);
                                                                }}
                                                                className="flex items-center gap-2 px-2 py-1.5"
                                                            >
                                                                <div className={`w-4 h-4 border rounded flex items-center justify-center
                                                                    ${selectedNurses.has(nurse.id) ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}`}
                                                                >
                                                                    {selectedNurses.has(nurse.id) && (
                                                                        <span className="text-white text-xs">✓</span>
                                                                    )}
                                                                </div>
                                                                <span className="flex-1">{nurse.name}</span>
                                                                <span className="text-gray-500 text-sm">{Math.round(nurse.casesReviewed / 240)} cases</span>
                                                            </CommandItem>
                                                        ))}
                                                </CommandList>
                                            </Command>
                                        </div>
                                    )}
                                </div>
                            )}
                            <Select value={viewType} onValueChange={setViewType}>
                                <SelectTrigger className="w-[180px] bg-white text-black text-lg">
                                    <SelectValue placeholder="Select view" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="company">Company View</SelectItem>
                                    <SelectItem value="nurse">Nurse View</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div className="border-b border-gray-200 w-full"></div>

                    {/* Show selected nurses below the header */}
                    {viewType === "nurse" && selectedNurses.size > 0 && (
                        <div className="container mx-auto py-2">
                            <div className="flex items-center gap-2">
                                <span className="text-sm text-gray-600">Selected Nurses:</span>
                                <div className="flex flex-wrap gap-1">
                                    {Array.from(selectedNurses).map(nurseId => {
                                        const nurse = NURSES.find(n => n.id === nurseId);
                                        return nurse && (
                                            <div 
                                                key={nurse.id}
                                                className="flex items-center gap-1 text-xs bg-gray-100 rounded-full px-2 py-1"
                                            >
                                                <span>{nurse.name}</span>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const newSelected = new Set(selectedNurses);
                                                        newSelected.delete(nurseId);
                                                        setSelectedNurses(newSelected);
                                                    }}
                                                    className="text-gray-500 hover:text-gray-700"
                                                >
                                                    ×
                                                </button>
                                            </div>
                                        );
                                    })}
                                    <button
                                        onClick={() => setSelectedNurses(new Set())}
                                        className="text-xs text-red-500 hover:text-red-700"
                                    >
                                        Clear all
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </header>
                {/* Rest of your dashboard content */}
                <main className="container mx-auto py-8 px-4 sm:px-6 lg:px-8 space-y-8">
                    {/* Daily Metrics Section */}
                    <section>
                        <h2 className="text-2xl font-bold mb-4">
                            Daily Metrics ({currentMonthName} {currentDate.getDate()}, {currentYear})
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                            <DailyMetricsCard
                                metric="Total Cases"
                                value={dailyMetrics.totalCases}
                                weeklyAverage={weeklyAverages.totalCases}
                                icon={<ClipboardList />}
                            />
                            <DailyMetricsCard
                                metric="Average Time"
                                value={`${dailyMetrics.averageTime} min`}
                                weeklyAverage={weeklyAverages.averageTime}
                                icon={<Clock />}
                                valueFormatter={(value) => `${value} min`}
                            />
                            <DailyMetricsCard
                                metric="TAT Compliance"
                                value={`${dailyMetrics.slaMet}%`}
                                weeklyAverage={weeklyAverages.slaCompliance}
                                icon={<CheckCircle />}
                                valueFormatter={(value) => `${value}%`}
                            />
                            <DailyMetricsCard
                                metric="Approval Rate"
                                value={`${dailyMetrics.approvalRate}%`}
                                weeklyAverage={weeklyAverages.approvalRate}
                                icon={<BarChart2 />}
                                valueFormatter={(value) => `${value}%`}
                            />
                            <DailyMetricsCard
                                metric="PMPM Cost"
                                value={`${pmpmMetrics.authsPerPatient.current} APP`}
                                weeklyAverage={pmpmMetrics.authsPerPatient.previous}
                                icon={<FileText />}
                                valueFormatter={(value) => `${value.toFixed(1)}`}
                                comparisonLabel="2023"
                                description="pmpm"
                            />
                        </div>
                    </section>


                    {/* Weekly Metrics Section */}
                    <section>
                        <h2 className="text-2xl font-bold mb-4">Weekly Performance</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <WeeklyMetricChart
                                data={weeklyMetrics.totalCases}
                                title="Total Cases"
                                valueFormatter={(value) => `${Math.round(value)}`}
                                icon={METRICS_CONFIG.totalCases.icon}
                                color={METRICS_CONFIG.totalCases.color}
                            />
                            <WeeklyMetricChart
                                data={weeklyMetrics.averageTime}
                                title="Average Time"
                                valueFormatter={(value) => `${value} min`}
                                icon={METRICS_CONFIG.averageTime.icon}
                                color={METRICS_CONFIG.averageTime.color}
                            />
                            <WeeklyMetricChart
                                data={weeklyMetrics.slaCompliance}
                                title="TAT Compliance"
                                valueFormatter={(value) => `${value}%`}
                                icon={METRICS_CONFIG.slaCompliance.icon}
                                color={METRICS_CONFIG.slaCompliance.color}
                            />
                            <WeeklyMetricChart
                                data={weeklyMetrics.approvalRate}
                                title="Approval Rate"
                                valueFormatter={(value) => `${value}%`}
                                icon={METRICS_CONFIG.approvalRate.icon}
                                color={METRICS_CONFIG.approvalRate.color}
                            />
                        </div>
                    </section>

                    {/* Quarterly/Yearly Metrics Section */}
                    <section>
                        <h2 className="text-2xl font-bold mb-4">Quarterly & Yearly Analysis</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <QuarterlyMetricChart
                                data={yearlyMetrics.totalCases}
                                title="Total Cases"
                                icon={METRICS_CONFIG.totalCases.icon}
                                color={METRICS_CONFIG.totalCases.color}
                            />
                            <QuarterlyMetricChart
                                data={yearlyMetrics.averageTime}
                                title="Average Time"
                                valueFormatter={(value) => `${value} min`}
                                icon={METRICS_CONFIG.averageTime.icon}
                                color={METRICS_CONFIG.averageTime.color}
                            />
                            <QuarterlyMetricChart
                                data={yearlyMetrics.slaCompliance}
                                title="TAT Compliance"
                                valueFormatter={(value) => `${value}%`}
                                icon={METRICS_CONFIG.slaCompliance.icon}
                                color={METRICS_CONFIG.slaCompliance.color}
                            />
                            <QuarterlyMetricChart
                                data={yearlyMetrics.approvalRate}
                                title="Approval Rate"
                                valueFormatter={(value) => `${value}%`}
                                icon={METRICS_CONFIG.approvalRate.icon}
                                color={METRICS_CONFIG.approvalRate.color}
                            />
                        </div>
                    </section>   
                    
                    {/* Healthcare Metrics Section */}
                    {!isOutpatientOnly && (
                        <section>
                            <h2 className="text-2xl font-bold mb-4">Inpatient Metrics</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                <HealthcareMetricCard
                                    title="Admission Rate"
                                    currentValue={healthcareMetrics.admissionRate.current}
                                    previousValue={healthcareMetrics.admissionRate.previous}
                                    icon={<BedDouble />}
                                    suffix=""
                                    trend={healthcareMetrics.admissionRate.trend}
                                    description={healthcareMetrics.admissionRate.description}
                                />
                                <HealthcareMetricCard
                                    title="Length of Stay"
                                    currentValue={healthcareMetrics.lengthOfStay.current}
                                    previousValue={healthcareMetrics.lengthOfStay.previous}
                                    icon={<Calendar />}
                                    suffix=" days"
                                    trend={healthcareMetrics.lengthOfStay.trend}
                                    description={healthcareMetrics.lengthOfStay.description}
                                />
                                <HealthcareMetricCard
                                    title="Readmission Rate"
                                    currentValue={healthcareMetrics.readmissionRate.current}
                                    previousValue={healthcareMetrics.readmissionRate.previous}
                                    icon={<BarChart3 />}
                                    trend={healthcareMetrics.readmissionRate.trend}
                                    description={healthcareMetrics.readmissionRate.description}
                                />
                            </div>
                        </section>
                    )}

                    {/* Trends Section */}
                    <section>
                        <h2 className="text-2xl font-bold mb-4">Case Trends</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {/* <AppealRateDiagram {...appealRate} /> */}
                            <AppealRateDiagram 
                                currentValue={appealRate.currentValue}
                                previousValue={appealRate.previousValue}
                                upheldRate={appealRate.upheldRate}
                                approvedRate={appealRate.approvedRate}
                            />
                            <CasePathwayChart {...pathwayData} />
                        </div>
                    </section>  
                    
                    {/* CPT Codes Section */}
                    <section>
                        <h2 className="text-2xl font-bold mb-4">CPT Code Analysis</h2>
                        <CPTCodesCard {...cptCodes} />
                    </section>
                </main>
            </div>
        </div>
    );
}