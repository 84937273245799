import {
  CheckCircleOutline,
  Description,
  HighlightOff,
  InfoOutlined,
  PlayCircle,
  Upload,
} from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Fade,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';
import { getMakers, getGuidelines, getMedicalRecord, getSampleJson, getSamplePdf, processWithExistingGuidelines, updateReviewerProcessStartTime, processDemo } from '../libs/backend';
import { v4 as uuidv4 } from 'uuid';

interface Guideline {
  Id: string;
  Text: string;
}

interface Maker {
  MakerName: string;
}

const GuidelineComponent: React.FC = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  // ! Guidelines
  const [guidelines, setGuidelines] = useState<Guideline[]>([]);
  const [selectedGuideline, setSelectedGuideline] = useState<Guideline | null>(null);
  const [isFetchingGuidelines, setIsFetchingGuidelines] = useState<boolean>(false);

  
  // ! Maker
  const [makers, setMakers] = useState<Maker[]>([]);
  const [selectedMaker, setSelectedMaker] = useState<Maker | null>(null);
  const [isFetchingMakers, setIsFetchingMakers] = useState<boolean>(false);

  
  const [file, setFile] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isDemoProcessing, setIsDemoProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const fetchGuidelines = async (maker: string) => {
    if (auth?.sessionInfo?.accessToken) {
      setIsFetchingGuidelines(true);
      try {
        const response = await getGuidelines(maker, auth.sessionInfo.accessToken);
        setGuidelines(response.data);
        setError(null);
      } catch (error) {
        console.error('Error fetching guidelines:', error);
        setError('Failed to fetch guidelines. Please try again later.');
      } finally {
        setIsFetchingGuidelines(false);
      }
    }
  };
  
  const fetchMakers = async () => {
    if (auth?.sessionInfo?.accessToken) {
      setIsFetchingMakers(true);
      try {
        const response = await getMakers(auth.sessionInfo.accessToken);
        setMakers(response.data);
        setError(null);
        return response.data;
      } catch (error) {
        console.error('Error fetching makers:', error);
        setError('Failed to fetch makers. Please try again later.');
      } finally {
        setIsFetchingMakers(false);
      }
    }
  };


  useEffect(() => {
    fetchMakers();
  }, [auth?.sessionInfo?.accessToken]);

  useEffect(() => {
    if (selectedMaker) {
      fetchGuidelines(selectedMaker.MakerName);
    }
  }, [selectedMaker, auth?.sessionInfo?.accessToken]);


  const handleGuidelineChange = (event: React.SyntheticEvent, value: Guideline | null) => {
    setSelectedGuideline(value);
  };

  const handleMakerChange = (event: React.SyntheticEvent, value: Maker | null) => {
    setSelectedMaker(value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleProcess = async () => {
    if (!selectedGuideline || !file) return;
    setIsProcessing(true);
    setError(null);

    try {
      const response = await processWithExistingGuidelines(selectedGuideline.Id, file, auth?.sessionInfo?.accessToken || '');
      const pdfResponse = await getMedicalRecord(response.data.result.case_id, file.name, auth?.sessionInfo?.accessToken || '');

      history.push('/results', {
        json_data: response.data.result,
        pdf_data: {
          blob: pdfResponse.data,
          width: response.data.result.width,
          height: response.data.result.height,
        },
        // reviewer_process_start_time: 0
        reviewer_process_start_time: 0,
        coming_from: "demo"
      });
    } catch (error) {
      console.error('Error processing:', error);
      setError('Failed to process the file. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDemoProcess = async () => {
    setIsDemoProcessing(true);
    setError(null);

    try {

      const randomNumber = Math.floor(Math.random() * 3);
      const [jsonResponse, pdfResponse] = await Promise.all([
        getSampleJson(randomNumber),
        getSamplePdf(randomNumber)
      ]);
      
      let json_data_with_case_id = jsonResponse.data.json_data;
      json_data_with_case_id.case_id = uuidv4();
      const response = await processDemo("demo", json_data_with_case_id, auth?.sessionInfo?.accessToken || '');

      history.push('/results', { 
        json_data: json_data_with_case_id, 
        pdf_data: pdfResponse.data,
        coming_from: "demo"
      });
    } catch (error) {
      console.error('Error fetching demo data:', error);
      setError('Failed to process demo data. Please try again.');
    } finally {
      setIsDemoProcessing(false);
    }
  };


  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom align="center" sx={{ my: 4 }}>
        AI-Powered Utilization Management Assistant
      </Typography>

      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* START -- This is Maker selection will be removed once Payers Portal is build */}
          <Grid size={{ xs: 12 }}>
            <Box display="flex" alignItems="center">
              <Description sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6" gutterBottom>
                1. Select Maker
              </Typography>
              <Tooltip
                title="Choose a guideline from the list to process your clinical attachment"
                arrow
                placement="right"
              >
                <IconButton size="small" onClick={() => setShowTooltip(!showTooltip)}>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Autocomplete
              options={makers}
              getOptionLabel={(option) => option.MakerName}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Search and select a maker" 
                  variant="outlined" 
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (
                        <React.Fragment>
                          {isFetchingMakers ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.startAdornment}
                        </React.Fragment>
                      ),
                    },
                  }}
                />
              )}
              value={selectedMaker}
              onChange={handleMakerChange}
              loading={isFetchingMakers}
              // isOptionEqualToValue={(option, value) => option.MakerName === value.MakerName}
            />
          </Grid>
          {/* END */}
          <Grid size={{ xs: 12 }}>
            <Box display="flex" alignItems="center">
              <Description sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6" gutterBottom>
                2. Select Guideline
              </Typography>
              <Tooltip
                title="Choose a guideline from the list to process your clinical attachment"
                arrow
                placement="right"
              >
                <IconButton size="small" onClick={() => setShowTooltip(!showTooltip)}>
                  <InfoOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Autocomplete
              options={guidelines}
              getOptionLabel={(option) => option.Text}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label="Search and select a guideline" 
                  variant="outlined" 
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (
                        <React.Fragment>
                          {isFetchingGuidelines ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.startAdornment}
                        </React.Fragment>
                      ),
                    },
                  }}
                />
              )}
              value={selectedGuideline}
              onChange={handleGuidelineChange}
              isOptionEqualToValue={(option, value) => option.Id === value.Id}
              loading={isFetchingGuidelines}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Box display="flex" alignItems="center">
              <Upload sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h6" gutterBottom>
                3. Upload Clinical Attachment
              </Typography>
            </Box>
            <TextField
              type="file"
              onChange={handleFileChange}
              // test id for file input, inputProps is deprecated need to figure out how to use the new API they are migrating to
              inputProps={{
                accept: '.pdf',
                'data-testid': 'file-input'
              }}
              fullWidth
              variant="outlined"
            />
            {file && (
              <Fade in={true}>
                <Box display="flex" alignItems="center" mt={1}>
                  <CheckCircleOutline sx={{ color: 'success.main', mr: 1 }} />
                  <Typography variant="body2" color="success.main">
                    File uploaded: {file.name}
                  </Typography>
                  <IconButton size="small" onClick={() => setFile(null)} sx={{ ml: 1 }}>
                    <HighlightOff fontSize="small" color="error" />
                  </IconButton>
                </Box>
              </Fade>
            )}
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              onClick={handleProcess}
              disabled={!selectedGuideline || !file || isProcessing}
              startIcon={isProcessing ? <CircularProgress size={20} /> : <Upload />}
            >
              {isProcessing ? 'Processing...' : 'Process Your Data'}
            </Button>
          </Grid>
        </Grid>

        {error && (
          <Alert severity="error" sx={{ mt: 3 }} icon={<HighlightOff />}>
            {error}
          </Alert>
        )}

        <Divider sx={{ my: 4 }} />

        <Box>
          <Typography variant="subtitle1" align="center" sx={{ mb: 2 }}>
            Not ready to upload? Try our demo instead.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            size="large"
            onClick={handleDemoProcess}
            disabled={isDemoProcessing}
            startIcon={isDemoProcessing ? <CircularProgress size={20} /> : <PlayCircle />}
          >
            {isDemoProcessing ? 'Processing Demo...' : 'Run Demo Process'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default GuidelineComponent;