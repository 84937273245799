import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext, UserRole } from '../contexts/authContext';
import { getUserInfo } from '../libs/backend';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import logoImage from '../logo-white.svg';

export const SimpleNavBar = () => {
  return (
    <nav className="bg-slate-900 py-2 px-4 shadow-md">
      <div className="flex items-center max-w-7xl mx-auto">
        <div className="flex items-center flex-grow">
          <img 
            src={logoImage} 
            alt="Logo" 
            className="h-10 mr-4"
          />
        </div>
      </div>
    </nav>
  );
};

interface LocationState {
  reviewer_decision?: string;
}

const NavBar = () => {
  const [userEmail, setUserEmail] = useState<string>('');
  const { sessionInfo, signOut } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [disableAllExpectCases, setDisableAllExpectCases] = useState(false);

  useEffect(() => {
    const reviewer_decision = (location['state'] as LocationState)?.reviewer_decision;
    if (location.pathname === '/results' && reviewer_decision === undefined) {
      setButtonsDisabled(true);
    } else if (location.pathname === '/results' && reviewer_decision) {
      setDisableAllExpectCases(true);
    }

    if (location.pathname !== '/results') {
      setButtonsDisabled(false);
      setDisableAllExpectCases(false);
    }
  }, [location]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (sessionInfo && sessionInfo.accessToken) {
        try {
          const response = await getUserInfo(sessionInfo.accessToken);
          if (response.data && response.data.email) {
            setUserEmail(response.data.email);
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      }
    };

    fetchUserInfo();
  }, [sessionInfo]);

  const handleLogout = () => {
    signOut();
  };

  const handleChangePassword = () => {
    history.push('/changepassword');
  };

  const handleDashboardClick = () => {
    history.push('/');
  };

  const handleCaseTrackingClick = () => {
    history.replace('/caseTracking', {new_submission: true});
  };


  const handleUserManagementClick = () => {
    history.push('/userManagement');
  };

  const isActive = (path: string) => {
    return location.pathname === path;
  };
  return (
    <nav className="border-b bg-[#054d5e] text-white">
      <div className="flex h-16 items-center px-4">
        <div className="flex items-center space-x-4 flex-1">
          <img src={logoImage} alt="Logo" className="h-10" />
          
          {sessionInfo && sessionInfo.accessToken && (
            <div className="flex items-center space-x-2">
              <Button
                variant={isActive('/') ? "secondary" : "ghost"}
                onClick={handleDashboardClick}
                disabled={disableAllExpectCases || buttonsDisabled}
              >
                Home
              </Button>
              
              <Button
                variant={isActive('/caseTracking') ? "secondary" : "ghost"}
                onClick={handleCaseTrackingClick}
                disabled={buttonsDisabled}
              >
                Cases
              </Button>
              
              {sessionInfo.role === UserRole.Admin && (
                <Button
                  variant={isActive('/userManagement') ? "secondary" : "ghost"}
                  onClick={handleUserManagementClick}
                  disabled={buttonsDisabled || disableAllExpectCases}
                >
                  User Management
                </Button>
              )}
            </div>
          )}
        </div>

        {sessionInfo && sessionInfo.accessToken && (
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium">{userEmail}</span>
            
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                  <Avatar className="h-8 w-8">
                    <AvatarFallback className="bg-[#ffff69] text-black">
                      {userEmail[0]?.toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={handleChangePassword} disabled={buttonsDisabled || disableAllExpectCases}>
                  Change Password
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleLogout} disabled={buttonsDisabled || disableAllExpectCases}>
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;