// * React 
import React, { useContext, useEffect, useState } from "react"
import { useHistory, useLocation } from 'react-router-dom';

// * shadcn UI
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

// * libs
import { getCaseCreatedTime, getCompletedData, getMedicalRecord, getQueueData, pullCase, updateReviewerProcessStartTime } from '../libs/backend';
import { AuthContext } from '../contexts/authContext';

// * tables and columns
import { DataTable, completedColumns, queueColumns } from "@/components/tables/caseTrackingTables";
import { History } from "history";

interface Case {
    case_id: string;
    created_time: string;
    reviewer_decision: string;
    reviewer_rationale: string;
    number_of_pages: number;
    mcg_guideline: string;
    processing_time: number;
    medical_record_filename: string;
    response: any;
    matches_count: number; 
}  

interface Reviewer {
    reviewer_id: string;
    reviewer_email: string;
    reviewer_full_name: string;
    reviewer_role: string;
}

interface CompletedCase {
    case_id: string
    status: "Approved" | "Denied" | "Appealed - upheld" | "Appealed - approved"
    decision_date: string
    req_date_service: string
    actual_date_service: string
    cpt_code: string
    cpt_description: string
    procedure: "Inpatient" | "Outpatient"
    service_type: string
    reviewer: Reviewer
    case: Case
    demo_data: any
}

export interface QueueCase {
    case_id: string
    reviewer: Reviewer
    status: string
    receipt_date: string
    time_left_to_decision: string
    cpt_code: string
    cpt_description: string
    patient_type: string
    service_type: string
    units_req: number
    unit_type: string
}


const DisableBackButton = (new_submission: boolean) => {
    const history = useHistory();

    useEffect(() => {
        
        const unblock = (history as unknown as History).block((location: any, action: any) => {
            if (action === 'POP' && new_submission) {
                history.replace('/caseTracking', {new_submission: true});
                return false;
            }

        });


        return () => {
            unblock();
        };
    }, [history, new_submission]);
}

export default function CaseTracking() {

    const location = useLocation<{new_submission: boolean}>()
    const new_submission = location.state?.new_submission || false;
    // console.log("new_submission", new_submission);
    DisableBackButton(new_submission);

    const auth = useContext(AuthContext);
    const { sessionInfo } = useContext(AuthContext);
    const history = useHistory();
    const [queueData, setQueueData] = useState<QueueCase[]>([]);
    const [completeCases, setCompleteCases] = useState<CompletedCase[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    type CaseType = QueueCase | CompletedCase;

    
    const fetchQueueData = async () => {
        if (auth?.sessionInfo?.accessToken) {
            try {
                const response = await getQueueData(auth.sessionInfo.accessToken);
                setQueueData(response.data);
                setError(null);
                return response.data;
            } catch (error) {
                console.error('Error fetching makers:', error);
                setError('Failed to fetch makers. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const fetchCompletedData = async () => {
        if (auth?.sessionInfo?.accessToken) {
            try {
                const response = await getCompletedData(auth.sessionInfo.accessToken);
                setCompleteCases(response.data);
                setError(null);
                return response.data;
            } catch (error) {
                console.error('Error fetching makers:', error);
                setError('Failed to fetch makers. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (auth?.sessionInfo?.accessToken) {
            Promise.all([
                fetchQueueData(),
                fetchCompletedData()
            ]);
        }
    }, [auth?.sessionInfo?.accessToken]);



    const handlePullCase = async (history: any, item: string) => {
        if (!auth?.sessionInfo?.accessToken || !item) return;
        try {
            const pulledCase = await pullCase(item, auth.sessionInfo.accessToken);
            const reviewer = pulledCase.data.result.reviewer;
            const caseResponse = pulledCase.data.result.case;
            const patientResponse = pulledCase.data.result.patient;
            const demoData = pulledCase.data.result.demo_data;

            const s3PathParts = caseResponse.clinical_attachment_s3_path.split('/');
            const fileName = s3PathParts[s3PathParts.length - 1];
            
            const pdfResponse = await getMedicalRecord(caseResponse.case_id, fileName, auth?.sessionInfo?.accessToken || '');

            const reviewerTimerResponse = await updateReviewerProcessStartTime(caseResponse.case_id, auth?.sessionInfo?.accessToken || '');
            
            for (let i = 0; i < 20; i++) {
                window.history.pushState(null, '', '/results');
            }
            history.push('/results', {
                json_data: caseResponse.rational_decisioning_criterias,
                pdf_data: {
                    blob: pdfResponse.data,
                    width: caseResponse.rational_decisioning_criterias.width,
                    height: caseResponse.rational_decisioning_criterias.height,
                },
                reviewer_process_start_time: reviewerTimerResponse.data.result,
                coming_from: "case_tracking",
                reviewer_data: reviewer,
                demo_data: demoData
            })
        } catch (error) {
            console.error('Error fetching medical record:', error)
            setError('Failed to pull case. Please try again later.');
        }
    }

    const handleRowClick = async (item: CaseType) => {
        // currently not being used
        console.log("queueData row clicked");
    }

    const handleCompletedRowClick = async (data: CompletedCase) => {
        const item = data.case;
        const reviewer = data.reviewer;
        const demoData = data.demo_data;
        try {
            const pdfResponse = await getMedicalRecord(item.case_id, item.medical_record_filename, sessionInfo?.accessToken || '');
            
            let reviewerProcessStartTime = item.processing_time;
            if (item.reviewer_decision === null) { 
                const responseDB = await getCaseCreatedTime(item.case_id, sessionInfo?.accessToken || '');
                const reviwerTime = responseDB.data.result;
        
                const startTime = new Date(reviwerTime).getTime();
                const currentTime = new Date().getTime();
                let elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
                reviewerProcessStartTime = elapsedSeconds;
            }
            history.push('/results', {
                json_data: item.response,
                pdf_data: {
                    blob: pdfResponse.data,
                    width: item.response.width, 
                    height: item.response.height, 
                },
                reviewer_process_start_time: reviewerProcessStartTime,
                coming_from: "historical_views",
                reviewer_decision: item.reviewer_decision,
                reviewer_rationale: item.reviewer_rationale,
                reviewer_data: reviewer,
                demo_data: demoData
            });
        } catch (error) {
            console.error('Error fetching medical record:', error);
            setError('Failed to fetch medical record. Please try again.');
        }
    }

    return (
        <div className="bg-background flex flex-col">
            <header className="sticky top-0 z-10 bg-background border-b">
                <div className="container mx-auto py-4">
                    <h1 className="text-2xl font-bold">Medical Cases Management</h1>
                </div>
            </header>
            <main className="flex-grow container mx-auto py-8 px-4 sm:px-6 lg:px-8 pb-16">
                <div className="space-y-8">
                    {/* Queue Table */}
                    <Card className="overflow-hidden border-2 border-[#054d5e]">
                        <CardHeader className="bg-[#054d5e] text-[#ffff69] flex flex-row items-center justify-between">
                            <CardTitle className="text-xl sm:text-2xl font-bold">Incoming Medical Cases Queue</CardTitle>
                            <Button
                                onClick={() => handlePullCase(history, queueData[0].case_id)} 
                                className="text-[#054d5e] hover:bg-[#abffc1] text-lg px-6 py-3 h-auto bg-[#ffff69]"
                                variant="secondary"
                            >
                                Pull Case
                            </Button>
                        </CardHeader>
                        <CardContent className="p-2 sm:p-6">
                            <DataTable<QueueCase> columns={queueColumns<QueueCase>(handleRowClick)} data={queueData} initialSort={[{ id: 'receipt_date', desc: true }]} />
                        </CardContent>
                    </Card>

                    {/* Completed Table */}
                    <Card className="overflow-hidden border-2 border-[#ffff69]">
                        <CardHeader className="bg-[#ffff69] text-[#054d5e]">
                            <CardTitle className="text-xl sm:text-2xl font-bold">Completed Medical Cases Summary</CardTitle>
                        </CardHeader>
                        <CardContent className="p-2 sm:p-6">
                            <DataTable<CompletedCase> columns={completedColumns<CompletedCase>(handleCompletedRowClick)} data={completeCases} initialSort={[{ id: 'decision_date', desc: true }]} />
                        </CardContent>
                    </Card>
                </div>
            </main>
        </div>
    )
}