import { Gradient, HealthAndSafety, Person } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2'
import { styled } from '@mui/system';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Username } from '../../components/authComponents';
import { AuthContext } from '../../contexts/authContext';
import { useValidUsername } from '../../hooks/useAuthHooks';

import logoImage from './../../logo.png';

const GradientBackground = styled('div')(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const LogoImage = styled('img')({
  width: '200px',
  height: 'auto',
  marginBottom: '2rem',
});

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '3.5rem',
  color: theme.palette.primary.contrastText,
  marginBottom: '0.5rem',
}));

const FeatureItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0.5rem',
});

const HoverTypography = styled(Typography)({
  '&:hover': { cursor: 'pointer' },
});

const RequestCode: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const { username, setUsername, usernameIsValid } = useValidUsername('');
  const [error, setError] = useState('');
  const [resetSent, setResetSent] = useState(false);

  const isValid = !usernameIsValid || username.length === 0;

  const sendCodeClicked = async () => {
    try {
      await authContext.sendCode(username);
      setResetSent(true);
      history.push('home');
    } catch (err) {
      setError('Unknown user');
    }
  };

  const emailSent = (
    <>
      <Box mt={1}>
        <Typography textAlign="center" variant="h5">{`Reset Code Sent to ${username}`}</Typography>
      </Box>
      <Box mt={4}>
        <Button onClick={() => history.push('forgotpassword')} color="primary" variant="contained">
          Reset Password
        </Button>
      </Box>
    </>
  );

  const sendCode = (
    <>
      <Box width="80%" m={1}>
        <Username usernameIsValid={usernameIsValid} setUsername={setUsername} />
      </Box>
      <Box mt={2}>
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      </Box>

      <Box mt={2}>
        <Grid container direction="row" justifyContent="center">
          <Box m={1}>
            <Button color="secondary" variant="contained" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button disabled={isValid} color="primary" variant="contained" onClick={sendCodeClicked}>
              Send Code
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );

  return (
    <GradientBackground>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid size={{ xs: 12, md: 4 }}>
            <Box textAlign="center">
              <Typography variant="h4" color="primary.contrastText" gutterBottom>
                AI-Powered Healthcare Management
              </Typography>
              <Box mt={4}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Gradient fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Smart Analytics
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Person fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        AI Co-pilot
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <HealthAndSafety fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Improved Care
                      </Typography>
                    </FeatureItem>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Paper style={{ padding: 32, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <LogoImage src={logoImage} alt="Electra Logo" />
                <Typography variant="h3" gutterBottom>Send Reset Code</Typography>
                
                {resetSent ? emailSent : sendCode}

                <Box mt={2}>
                  <HoverTypography variant="body1" onClick={() => history.push('signin')}>
                    Remember your password? Sign In
                  </HoverTypography>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </GradientBackground>
  );
};

export default RequestCode;