import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";
import { X } from '@mui/icons-material';

const markdownStyles = {
    ul: 'list-none p-0 m-0',
    li: 'mb-4',
    p: 'my-1',
};

function MarkdownContent({ text }: { text: string }) {
    return (
        <ReactMarkdown
        components={{
            p: ({ children }) => (
            <p className={markdownStyles.p}>{children}</p>
            ),
        }}
        >
        {text}
        </ReactMarkdown>
    );
}

interface RationaleViewModalProps {
    isOpen: boolean;
    onClose: () => void;
    textValue: string;
}

interface RationaleEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    textValue: string;
    onSubmit: (editedText: string) => Promise<void>;
}

export const RationaleViewModal: React.FC<RationaleViewModalProps> = ({
    isOpen,
    onClose,
    textValue,
}) => {
    const [editedText, setEditedText] = useState(textValue);

    useEffect(() => {
        setEditedText(textValue);
    }, [textValue]);

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-3xl w-11/12 p-6">
                <DialogHeader className="flex flex-row items-center justify-between mb-6">
                    <DialogTitle className="text-2xl font-semibold text-primary">Suggested Rationale Full View</DialogTitle>
                </DialogHeader>
                
                <Card className="p-6 bg-card hover:bg-accent/5 border shadow-md rounded-xl transition-all duration-200">
                    <div className="space-y-4">
                    <MarkdownContent text={editedText} />
                    </div>
                </Card>

                {/* </ScrollArea> */}
            </DialogContent>
        </Dialog>
    );
};

export const RationaleEditModal: React.FC<RationaleEditModalProps> = ({
    isOpen,
    onClose,
    textValue,
    onSubmit,
}) => {
    const [editedText, setEditedText] = useState(textValue);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditedText(event.target.value);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        try {
        await onSubmit(editedText);
        onClose();
        } catch (error) {
        console.error('Error submitting edited rationale:', error);
        } finally {
        setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="max-w-3xl w-11/12 p-6">
                <DialogHeader className="flex flex-row items-center justify-between mb-6">
                    <DialogTitle className="text-2xl font-semibold text-primary">Edit Rationale</DialogTitle>
                </DialogHeader>

                <Textarea
                    value={editedText}
                    onChange={handleTextChange}
                    className="min-h-[40vh] resize-none"
                />

                <div className="flex justify-end mt-4">
                    <Button onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};