import React, { useContext, useEffect, useState } from 'react';

import * as cognito from '../libs/cognito';
import { updateUserLastLogin } from '@/libs/backend';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export enum UserRole {
  Admin = "admin",
  Nurse = "nurse"
}

export enum UserTeam {
  InPatient = "inpatient",
  OutPatient = "outpatient",
  Pharmacy = "pharmacy"
}

export interface SessionInfo {
  username?: string;
  email?: string;
  sub?: string;
  accessToken?: string;
  refreshToken?: string;
  role?: UserRole;
}

export interface IAuth {
  sessionInfo?: SessionInfo
  attrInfo?: any
  authStatus?: AuthStatus
  role?: UserRole
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  completeNewPasswordChallenge?: any
}

interface SignUpAttributes {
  givenName: string;
  familyName: string;
  middleName?: string;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  role: undefined,
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [role, setRole] = useState<UserRole | undefined>(undefined)

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession()
        const attr: any = await getAttributes()
        const roleAttr = attr.find((attr: any) => attr.Name === 'custom:role')

        if (!roleAttr?.Value) {
          setAuthStatus(AuthStatus.SignedOut)
          throw new Error('User role not found')
        }

        if (!Object.values(UserRole).includes(roleAttr.Value as UserRole)) {
          setAuthStatus(AuthStatus.SignedOut)
          throw new Error('Invalid user role')
        }

        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          role: roleAttr.Value as UserRole,
        })
        window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
        window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
        await setAttribute({ Name: 'website', Value: 'https://github.com/dbroadhurst/aws-cognito-react' })
        setAttrInfo(attr)
        setRole(roleAttr.Value as UserRole)
        setAuthStatus(AuthStatus.SignedIn)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
        setRole(undefined)
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])


  if (authStatus === AuthStatus.Loading) {
    return null
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      const result: any = await cognito.signInWithEmail(username, password)  
      console.log("authContext signInWithEmail status", result.status)
      setAuthStatus(AuthStatus.SignedIn)
      if (result.status === 'SUCCESS') {
        await updateUserLastLogin(result.session.accessToken.jwtToken)
      }
      return result
      // return {passwordResetRequired: false}
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  // ! like signInWithEmail, use authContext to make call to cognito.completeNewPasswordChallenge?
  async function completeNewPasswordChallenge(challengeSession: any, newPassword: string) {
    try {
      await cognito.completeNewPasswordChallenge(challengeSession, newPassword)
      setAuthStatus(AuthStatus.SignedIn)
      const session: any = await getSession()
      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
      })
    } catch (err) {
      throw err
    }
  }

  async function signUpWithEmail(username: string, email: string, password: string, role: UserRole = UserRole.Nurse, names: SignUpAttributes) {
    try {
      console.log("authContext signing up with role", role)
      const result = await cognito.signUpUserWithEmail(username, email, password, role, {givenName: names.givenName.trim(), familyName: names.familyName.trim(), middleName: names.middleName?.trim()})
      console.log("signUpWithEmail result", result)
      return result
    } catch (err) {
      console.error('Error signing up:', err);
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
    setSessionInfo({})  
    setAttrInfo([])     
    window.localStorage.removeItem('accessToken') 
    window.localStorage.removeItem('refreshToken')

    // clear local storage
    localStorage.clear();
    // clear session storage
    sessionStorage.clear();

    // clear cookies
    // document.cookie.split(";").forEach(function(c) {
    //   document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    // });
  }

  async function verifyCode(username: string, code: string) {
    try {
      console.log("verifying code in authContext", code)
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const currentUser = cognito.getCurrentUser()
      const session = await cognito.getSession(currentUser)
      
      const attributes = (await getAttributes()) as Array<{Name: string, Value: string}>
      const roleAttr = attributes.find((attr) => attr.Name === 'custom:role')
      if (roleAttr) {
        const roleValue = roleAttr.Value
        setRole(roleAttr.Value as UserRole)
      }
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    role,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
