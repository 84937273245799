import { useEffect, useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AuthContext } from '@/contexts/authContext';
import { useToast } from '@/hooks/use-toast';
import { signInWithEmail } from '@/libs/cognito';

export const AutoAuthenticator = () => {
    const location = useLocation();
    const history = useHistory();
    const { toast } = useToast();
    const authContext = useContext(AuthContext);

    const [hasAttemptedAuth, setHasAttemptedAuth] = useState(false);
    
    const attemptAuth = async (username: string, password: string) => {
        try {
            const decodedUsername = atob(username);
            const decodedPassword = atob(password);
            
            const result = await authContext.signInWithEmail(decodedUsername, decodedPassword);
            if (result.status === 'NEW_PASSWORD_REQUIRED') {

                // store credentials temporarily
                localStorage.setItem('tempUsername', decodedUsername);
                localStorage.setItem('tempPassword', decodedPassword);
                localStorage.setItem('challengeSession', JSON.stringify(result));
                localStorage.setItem('userAttributes', JSON.stringify(result.userAttributes));

                history.replace('/resetpassword', {challengeSession: result, comingFrom: 'autoAuth'});
            } else {
                toast({
                    title: "Please sign in manually",
                    description: "This auto-authentication link has already been used.",
                    variant: "default",
                });
                history.replace('/signin');
            }
        } catch (error: any) {
            // console.error('Authentication error:', error);
            // setHasAttemptedAuth(true);
            console.log("hasAttemptedAuth", hasAttemptedAuth)
            console.error('Authentication error details:', {
                name: error.name,
                message: error.message,
                stack: error.stack
            });
            // history.replace('/signin');
        }
    };

    const handleAuthAttempt = () => {
        if (location.pathname === '/resetpassword' || location.pathname === '/dashboard') {
            setHasAttemptedAuth(true);
            return;
        }
        if (location.pathname === '/auth') {
            console.log("AutoAuthenticator useEffect triggered");
            const params = new URLSearchParams(location.search);
            const username = params.get('username');
            const password = params.get('password');
            
            if (username && password) {
                attemptAuth(username, password);
            }
        }
    }

    useEffect(() => {
        handleAuthAttempt();
    }, [location.pathname]);

    return null;
};