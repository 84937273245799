import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Container, 
  Typography, 
  Paper,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import { Gradient, Person, HealthAndSafety } from '@mui/icons-material';

import { useValidEmail, useValidPassword } from '../../hooks/useAuthHooks';
import { Email, Password } from '../../components/authComponents';
import { AuthContext, UserRole } from '../../contexts/authContext';

import logoImage from './../../logo.png';

const GradientBackground = styled('div')(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const LogoImage = styled('img')({
  width: '200px',
  height: 'auto',
  marginBottom: '2rem',
});

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '3.5rem',
  color: theme.palette.primary.contrastText,
  marginBottom: '0.5rem',
}));

const FeatureItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0.5rem',
});

const SignUp: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [role, setRole] = useState<UserRole>(UserRole.Nurse);

  const { email, setEmail, emailIsValid } = useValidEmail('');
  const { password, setPassword, passwordIsValid } = useValidPassword('');
  const {
    password: passwordConfirm,
    setPassword: setPasswordConfirm,
    passwordIsValid: passwordConfirmIsValid,
  } = useValidPassword('');
  const [error, setError] = useState('');

  const isValid =
    !emailIsValid ||
    email.length === 0 ||
    !passwordIsValid ||
    password.length === 0 ||
    !passwordConfirmIsValid ||
    passwordConfirm.length === 0;

  const [formData, setFormData] = useState({
    givenName: '',
    middleName: '',
    familyName: '',
    role: ''
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailIsValid || !passwordIsValid || !passwordConfirmIsValid) {
      setError('Please check your input fields');
      return;
    }

    if (password !== passwordConfirm) {
      setError('Passwords do not match');
      return;
    }

    if (!formData.givenName.trim() || !formData.familyName.trim()) {
      setError('First name and last name are required');
      return;
    }

    if (isValid) {
      setError('Please check your input fields');
      return;
    }
    
    try {
      const result = await authContext.signUpWithEmail(
        email,
        email,
        password,
        formData.role as UserRole,
        {
          givenName: formData.givenName,
          familyName: formData.familyName,
          middleName: formData.middleName || undefined,
        }
      );

      const userData = {
        userId: result.userSub,
        username: email,
        password: password,
        givenName: formData.givenName,
        familyName: formData.familyName,
        role: formData.role,
        middleName: formData.middleName || '',
      }
      history.push('/verify', { userData: userData });
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setPasswordConfirm(value);
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  return (
    <GradientBackground>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid size={{ xs: 12, md: 4 }}>
            <Box textAlign="center">
              <Typography variant="h4" color="primary.contrastText" gutterBottom>
                AI-Powered Healthcare Management
              </Typography>
              <Box mt={4}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Gradient fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Smart Analytics
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Person fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        AI Co-pilot
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <HealthAndSafety fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Improved Care
                      </Typography>
                    </FeatureItem>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="text-center mb-8">
              {/* <h1 className="text-2xl font-normal mb-2">ClarityCare</h1> */}
              <div className="flex justify-center mb-1">
                <LogoImage src={logoImage} alt="Electra Logo" />
              </div>
              <h2 className="text-4xl font-normal">Sign Up</h2>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <input
                    type="text"
                    name="givenName"
                    placeholder="First Name"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500"
                    value={formData.givenName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="middleName"
                    placeholder="Middle Name"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500"
                    value={formData.middleName}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    name="familyName"
                    placeholder="Last Name"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500"
                    value={formData.familyName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500 ${
                    email.length > 0 && !emailIsValid ? 'border-red-500' : 'border-gray-300'
                  }`}
                  value={email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500 ${
                    password.length > 0 && !passwordIsValid ? 'border-red-500' : 'border-gray-300'
                  }`}
                  value={password}
                  onChange={handleChange}
                />
              </div>

              <div>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500 ${
                    passwordConfirm.length > 0 && !passwordConfirmIsValid ? 'border-red-500' : 'border-gray-300'
                  }`}
                  value={passwordConfirm}
                  onChange={handleChange}
                />
              </div>

              <div>
                <select
                  name="role"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500"
                  value={formData.role}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a role</option>
                  <option value="nurse">Nurse</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              
              {/* error message */}
              {error && (
                <div className="text-red-500 text-sm text-center">
                  {error}
                </div>
              )}

              <div className="flex justify-center gap-4">
                <button
                  type="button"
                  className="px-6 py-2 bg-yellow-200 rounded-md hover:bg-yellow-300 transition-colors"
                  onClick={() => history.goBack()}
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="px-6 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                >
                  SIGN UP
                </button>
              </div>

              <div className="text-center mt-4">
                <a href="/signin" className="text-gray-600 hover:text-gray-800">
                  Already have an account? Sign In
                </a>
              </div>
            </form>
          </div>
        </Grid>
      </Container>
    </GradientBackground>
  );
};

export default SignUp;