import React from "react"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { ChevronDown, ChevronUp } from "lucide-react"
import { Typography } from "@mui/material"
import { User, Phone, Mail, MapPin } from "lucide-react"
import ReactMarkdown from 'react-markdown'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { OverviewData } from '../types/summaryTypes'

interface ComponentProps {
    overviewData: OverviewData;
    clinicalData: string;
    timer: string;
}



export function SummaryComponent({ timer, overviewData, clinicalData }: ComponentProps) {
    return (
        <TooltipProvider>
            <Card className="w-full">
                <Tabs defaultValue="overview">
                    <CardHeader className="pb-2 pt-6">
                        <div className="flex items-center justify-between">
                            <CardTitle >
                                <div>
                                    <Typography variant="h6" gutterBottom color="primary">
                                        Summary
                                    </Typography>
                                </div>
                            </CardTitle>
                            <TabsList className="grid w-2/3 grid-cols-4">
                                <TabsTrigger value="overview">Overview</TabsTrigger>
                                <TabsTrigger value="member">Member</TabsTrigger>
                                <TabsTrigger value="insurance">Insurance</TabsTrigger>
                                <TabsTrigger value="provider">Provider</TabsTrigger>
                            </TabsList>
                            <div className="text-sm border rounded px-2 py-1">Timer: {timer}</div>
                        </div>
                    </CardHeader>

                    <CardContent>
                        <TabsContent value="overview" className="mt-4">
                            <div className="grid grid-cols-4 gap-4">
                                <InfoSection title="Member Info">
                                    <InfoItem label="Name" value={overviewData.memberInfo.name} />
                                    <div className="flex justify-between text-sm">
                                        <span className="text-muted-foreground">DOB:</span>
                                        <span>
                                            {overviewData.memberInfo.dob} (<span className="font-bold">{overviewData.memberInfo.age}</span>)
                                        </span>
                                    </div>
                                    <InfoItemBold label="Gender" value={overviewData.memberInfo.gender} />
                                    <InfoItem label="ID" value={overviewData.memberInfo.id} />
                                </InfoSection>

                                <InfoSection title="Service & Codes">
                                    <InfoItemBold label="Service" value={overviewData.serviceInfo.service} />
                                    <InfoItemBold label="Type" value={overviewData.serviceInfo.type} />
                                    <InfoItemWithTooltip 
                                        label="ICD 10" 
                                        value={overviewData.serviceInfo.icdCode}
                                        description={overviewData.serviceInfo.icdDescription}
                                    />
                                    <InfoItemWithTooltip 
                                        label="CPT" 
                                        value={overviewData.serviceInfo.cptCode}
                                        description={overviewData.serviceInfo.cptDescription}
                                    />
                                </InfoSection>
                                <InfoSection title="Provider & Facility">
                                    <InfoItem label="Provider" value={overviewData.providerInfo.name} />
                                    <InfoItem label="NPI" value={overviewData.providerInfo.npi} />
                                    <InfoItem label="Facility" value={overviewData.providerInfo.facility} />
                                    <InfoItem label="FAC ID" value={overviewData.providerInfo.facilityId} />
                                </InfoSection>
                                <InfoSection title="Case Details">
                                    <InfoItem label="Guideline" value={overviewData.caseDetails.guideline} />
                                    <InfoItem label="Receipt Date" value={overviewData.caseDetails.receiptDate} />
                                    <InfoItemBold label="Due Date" value={overviewData.caseDetails.dueDate} />
                                </InfoSection>
                            </div>
                        </TabsContent>
                        
                        <TabsContent value="member" className="mt-4">
                            <MemberTab clinicalData={clinicalData} overviewData={overviewData} />
                        </TabsContent>
                    
                        <TabsContent value="insurance" className="mt-4">
                            <div className="grid grid-cols-5 gap-4">
                                <InfoCard label="Plan" title={overviewData.insuranceInfo.plan} />
                                <InfoCard label="Plan Type" title={overviewData.insuranceInfo.planType} />
                                <InfoCard label="Market Type" title={overviewData.insuranceInfo.benefitType} />
                                <InfoCard label="Group #" title={overviewData.insuranceInfo.groupNumber} />
                                <InfoCard label="Policy #" title={overviewData.insuranceInfo.policy} />
                                
                            </div>
                        </TabsContent>
                        
                        <TabsContent value="provider" className="space-y-4 mt-4">
                            <div className="grid grid-cols-6 gap-4">
                            <InfoCard label="Provider Name" title={overviewData.providerInfo.name} />
                            <InfoCard label="NPI" title={overviewData.providerInfo.npi} />
                            <InfoCard label="Specialty" title="General Surgery" />
                            <InfoCard label="Contact" title={overviewData.providerInfo.contact} />
                            <InfoCard label="Facility" title={overviewData.providerInfo.facility} />
                            <InfoCard label="Address" title={overviewData.providerInfo.address} />
                            </div>
                        </TabsContent>
                    </CardContent>
                </Tabs>
            </Card>
        </TooltipProvider>
    )
}

function InfoSection({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <div className="border rounded-lg p-4">
            <h3 className="font-semibold mb-2">{title}</h3>
            <div className="space-y-1">{children}</div>
        </div>
        )
    }
    
function InfoItem({ label, value }: { label: string; value: string }) {
    return (
    <div className="flex justify-between text-sm">
        <span className="text-muted-foreground">{label}:</span>
        <span className="font-medium">{value}</span>
    </div>
    )
}

function InfoItemBold({ label, value }: { label: string; value: string }) {
    return (
        <div className="flex justify-between text-sm">
            <span className="text-muted-foreground">{label}:</span>
            <span className="font-bold">{value}</span>
        </div>
    )
}

function MemberInfoItem({ label, value }: { label: string; value: string }) {
    return (
        <div className="flex items-center space-x-2">
            <span className="text-sm font-medium text-muted-foreground">{label}:</span>
            <span className="text-sm">{value}</span>
        </div>
        )
    }

function InfoCard({ label, title, subtitle, className }: { label: string; title: string; subtitle?: string; className?: string }) {
    return (
    <div className={`border rounded-lg p-4 ${className}`}>
        <Label className="text-muted-foreground">{label}</Label>
        <div className="mt-2">
        <div className="font-medium">{title}</div>
        {subtitle && <div className="text-sm text-muted-foreground">{subtitle}</div>}
        </div>
    </div>
    )
}


function InfoItemWithTooltip({ label, value, description }: { label: string; value: string; description: string }) {
    return (
        <div className="flex justify-between text-sm">
            <span className="text-muted-foreground">{label}:</span>
            <Tooltip>
            <TooltipTrigger asChild>
                <span className="text-sm cursor-help underline decoration-dotted">{value}</span>
            </TooltipTrigger>
            <TooltipContent>
                <p>{description}</p>
            </TooltipContent>
            </Tooltip>
        </div>
    )
}

function MemberTab({ clinicalData, overviewData }: { clinicalData: string; overviewData: OverviewData }) {
    return (
        <div className="space-y-6">
            <Card>
            <CardContent className="p-6">
                <div className="flex flex-wrap items-center gap-12">
                <MemberInfoItem label="Name" value={overviewData.memberInfo.name} />
                <MemberInfoItem label="ID" value={overviewData.memberInfo.id} />
                <MemberInfoItem label="DOB" value={overviewData.memberInfo.dob} />
                <MemberInfoItem label="Age" value={overviewData.memberInfo.age.toString()} />
                <MemberInfoItem label="Gender" value={overviewData.memberInfo.gender} />
                <MemberInfoItem label="Contact" value={overviewData.memberInfo.contact} />
                <MemberInfoItem label="Email" value={overviewData.memberInfo.email} />
                </div>
            </CardContent>
            </Card>
            <Card>
            <CardHeader>
                <CardTitle className="text-xl">Clinical Summary</CardTitle>
            </CardHeader>
            <div className="border-t" />
            <CardContent>
                <ReactMarkdown
                components={{
                    h2: ({ node, ...props }) => <h4 className="text-lg font-semibold mt-4 mb-2" {...props} />,
                    ul: ({ node, ...props }) => <ul className="list-disc list-inside space-y-1 mb-4" {...props} />,
                }}
                >
                {clinicalData}
                </ReactMarkdown>
            </CardContent>
            </Card>
        </div>
    )
}