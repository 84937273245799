import { useContext, useEffect, useState } from "react"
import { Search, UserPlus, Edit, Trash, Mail, Link2, Key } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { Badge } from "@/components/ui/badge"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Label } from "@/components/ui/label"
import { Checkbox } from "@/components/ui/checkbox"
import { createNewUser, getReviewers } from "@/libs/backend"

import { AuthContext } from "@/contexts/authContext"
import { useCustomToast } from "@/components/ui/toaster"

import { UserRole, UserTeam } from '@/contexts/authContext'
import { CircularProgress, Typography } from "@mui/material"

interface User {
    id: string
    firstName: string
    middleName: string
    lastName: string
    email: string
    role: string
    team: string
    lastLogin: string
}

interface Case {
    id: string
    reviewerId: string | null
    status: string
    receiptDate: string
    timeLeftToDecision: string
    cptCode: string
    outInpatient: string
    serviceType: string
    unitsRequested: number
    unitType: string
}

const initialUsers: User[] = [
    {
        id: "N001",
        firstName: "Sarah",
        middleName: "",
        lastName: "Johnson",
        email: "sarah.johnson@example.com",
        role: "Nurse Reviewer",
        team: "InPatient",
        lastLogin: "2024-11-03 14:30",
    },
    {
        id: "N002",
        firstName: "Michael",
        middleName: "",
        lastName: "Chen",
        email: "michael.chen@example.com",
        role: "Nurse Reviewer",
        team: "InPatient",
        lastLogin: "2024-11-04 09:15",
    },
    {
        id: "N003",
        firstName: "Emily",
        middleName: "",
        lastName: "Rodriguez",
        email: "emily.rodriguez@example.com",
        role: "Nurse Reviewer",
        team: "InPatient",
        lastLogin: "2024-10-30 11:45",
    },
    {
        id: "N004",
        firstName: "David",
        middleName: "M",
        lastName: "Kim",
        email: "david.kim@example.com",
        role: "Nurse Reviewer",
        team: "OutPatient",
        lastLogin: "2024-11-04 16:30",
    },
    {
        id: "N005",
        firstName: "Lisa",
        middleName: "",
        lastName: "Patel",
        email: "lisa.patel@example.com",
        role: "Nurse Supervisor",
        team: "OutPatient",
        lastLogin: "2024-11-03 11:20",
    },
    {
        id: "N006",
        firstName: "James",
        middleName: "",
        lastName: "Wilson",
        email: "james.wilson@example.com",
        role: "Nurse Reviewer",
        team: "OutPatient",
        lastLogin: "2024-10-28 09:45",
    },
    {
        id: "N007",
        firstName: "Maria",
        middleName: "",
        lastName: "Garcia",
        email: "maria.garcia@example.com",
        role: "Nurse Supervisor",
        team: "OutPatient",
        lastLogin: "2024-11-04 13:15",
    },
    {
        id: "N008",
        firstName: "Robert",
        middleName: "",
        lastName: "Taylor",
        email: "robert.taylor@example.com",
        role: "Nurse Reviewer",
        team: "Pharmacy",
        lastLogin: "2024-11-04 13:15",
    },
    {
        id: "N009",
        firstName: "Amanda",
        middleName: "",
        lastName: "Lee",
        email: "amanda.lee@example.com",
        role: "Nurse Reviewer",
        team: "Pharmacy",
        lastLogin: "2024-11-04 13:15",
    },
    {
        id: "N010",
        firstName: "Thomas",
        middleName: "",
        lastName: "Brown",
        email: "thomas.brown@example.com",
        role: "Nurse Reviewer",
        team: "Pharmacy",
        lastLogin: "2024-11-04 13:15",
    }
]

const initialCases: Case[] = [
    {
        id: "C001",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-01",
        timeLeftToDecision: "3 days",
        cptCode: "99213",
        outInpatient: "Outpatient",
        serviceType: "Office Visit",
        unitsRequested: 1,
        unitType: "Visit",
    },
    {
        id: "C002",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-02",
        timeLeftToDecision: "2 days",
        cptCode: "99285",
        outInpatient: "Inpatient",
        serviceType: "Emergency Department",
        unitsRequested: 1,
        unitType: "Visit",
    },
    {
        id: "C003",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-03",
        timeLeftToDecision: "4 days",
        cptCode: "99223",
        outInpatient: "Inpatient",
        serviceType: "Hospital Care",
        unitsRequested: 1,
        unitType: "Day",
    },
    {
        id: "C004",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-03",
        timeLeftToDecision: "1 day",
        cptCode: "97110",
        outInpatient: "Outpatient",
        serviceType: "Physical Therapy",
        unitsRequested: 4,
        unitType: "Units",
    },
    {
        id: "C005",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-04",
        timeLeftToDecision: "5 days",
        cptCode: "70450",
        outInpatient: "Outpatient",
        serviceType: "CT Scan Head/Brain",
        unitsRequested: 1,
        unitType: "Procedure",
    },
    {
        id: "C006",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-04",
        timeLeftToDecision: "2 days",
        cptCode: "90837",
        outInpatient: "Outpatient",
        serviceType: "Psychotherapy",
        unitsRequested: 1,
        unitType: "Session",
    },
    {
        id: "C007",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-04",
        timeLeftToDecision: "3 days",
        cptCode: "99232",
        outInpatient: "Inpatient",
        serviceType: "Subsequent Hospital Care",
        unitsRequested: 3,
        unitType: "Days",
    },
    {
        id: "C008",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-05",
        timeLeftToDecision: "4 days",
        cptCode: "29881",
        outInpatient: "Outpatient",
        serviceType: "Knee Arthroscopy",
        unitsRequested: 1,
        unitType: "Procedure",
    },
    {
        id: "C009",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-05",
        timeLeftToDecision: "2 days",
        cptCode: "96372",
        outInpatient: "Outpatient",
        serviceType: "Therapeutic Injection",
        unitsRequested: 2,
        unitType: "Injections",
    },
    {
        id: "C010",
        reviewerId: null,
        status: "Pending",
        receiptDate: "2024-11-05",
        timeLeftToDecision: "1 day",
        cptCode: "93000",
        outInpatient: "Outpatient",
        serviceType: "ECG Complete",
        unitsRequested: 1,
        unitType: "Procedure",
    }
]

const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};


export default function Component() {
    const auth = useContext(AuthContext);
    const { showToast } = useCustomToast();

    const [users, setUsers] = useState<User[]>(initialUsers)
    const [userLoading, setUserLoading] = useState(true)

    const [cases, setCases] = useState<Case[]>(initialCases)
    const [searchQuery, setSearchQuery] = useState("")
    const [editingUser, setEditingUser] = useState<User | null>(null)
    const [assigningCases, setAssigningCases] = useState<string | null>(null)
    const [selectedCases, setSelectedCases] = useState<string[]>([])
    const [isAddingUser, setIsAddingUser] = useState(false)
    const [newUser, setNewUser] = useState<Omit<User, 'id' | 'status' | 'lastLogin'>>({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        role: '',
        team: '',
    })
    const [newUserPassword, setNewUserPassword] = useState('')
    const [newUserVerificationCode, setNewUserVerificationCode] = useState('')

    const filteredUsers = users.filter((user) =>
        Object.values(user).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    )

    const handleDeleteUser = (userId: string) => {
        setUsers(users.filter((user) => user.id !== userId))
    }

    const handleEditUser = (user: User) => {
        setEditingUser({ ...user })
    }

    const handleUpdateUser = () => {
        if (editingUser) {
        setUsers(users.map((u) => (u.id === editingUser.id ? editingUser : u)))
        setEditingUser(null)
        }
    }

    const handleUpdateLogin = (userId: string) => {
        alert(`Password reset email sent to user with ID: ${userId}`)
    }

    const handleAssignCases = (userId: string) => {
        setAssigningCases(userId)
        setSelectedCases([])
    }

    const handleCaseSelection = (caseId: string, isChecked: boolean) => {
        setSelectedCases(prev =>
        isChecked
            ? [...prev, caseId]
            : prev.filter(id => id !== caseId)
        )
    }

    const handleCaseAssignment = () => {
        if (assigningCases) {
        setCases(cases.map(c => 
            selectedCases.includes(c.id) ? { ...c, reviewerId: assigningCases } : c
        ))
        setAssigningCases(null)
        setSelectedCases([])
        }
    }

    const handleAddUser = async () => {
        try {
            if (!auth.sessionInfo?.accessToken) {
                console.error("No access token available");
                return;
            }

            const response = await createNewUser(newUser, auth.sessionInfo.accessToken)
            if (!response.data.success) {
                throw new Error(response.data.message)
            }
            setIsAddingUser(false)
            showToast({
                title: "User created successfully",
                description: "A password reset email has been sent to the user.",
                type: "success",
            })
        } catch (error : any) {
            console.error("Error adding user:", error);
            setIsAddingUser(false)
            showToast({
                title: "Error adding user",
                description: error.message,
                type: "error",
            })
        }
    }

    const isAddUserFormValid = () => {
        return (
            newUser.firstName.trim() !== '' &&
            newUser.lastName.trim() !== '' &&
            newUser.email.trim() !== '' &&
            validateEmail(newUser.email) &&
            newUser.role.trim() !== '' &&
            newUser.team.trim() !== ''
        );
    };

    const fetchReviewers = async () => {
        try {
            if (!auth.sessionInfo?.accessToken) {
                console.error("No access token available");
                return;
            }

            setUserLoading(true);
            const response = await getReviewers(auth.sessionInfo.accessToken);
            const cleanedUsers = response.data.map((user: User) => ({
                ...user,
                firstName: user.firstName ?? '',
                middleName: user.middleName ?? '',
                lastName: user.lastName ?? '',
                email: user.email ?? '',
                role: user.role ?? '',
                team: user.team ?? '',
                lastLogin: user.lastLogin ?? ''
            }));
            
            setUsers(cleanedUsers);
        } catch (error: any) {
            console.error("Error fetching reviewers:", error);
            showToast({
                title: "Error fetching reviewers",
                description: error.message,
                type: "error",
            });
        } finally {
            setUserLoading(false);
        }
    };

    useEffect(() => {
        fetchReviewers();
    }, [auth.sessionInfo?.accessToken]);
    
    return (
        <div className="container mx-auto py-10">
            <div className="mb-8">
                <h1 className="text-3xl font-bold tracking-tight">User Management</h1>
            </div>
            <div className="flex items-center justify-between mb-6">
                <div className="relative w-72">
                    <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                        placeholder="Search users..."
                        className="pl-8"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <Button onClick={() => setIsAddingUser(true)}>
                    <UserPlus className="mr-2 h-4 w-4" />
                    Add New User
                </Button>
            </div>
            <div className="relative rounded-md border">
                {userLoading ? (
                    <div 
                        className="fixed inset-0 flex items-center justify-center z-50"
                        style={{ 
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        pointerEvents: 'none'
                        }}
                    >
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center" style={{ pointerEvents: 'auto' }}>
                        <CircularProgress className="h-12 w-12 mb-4" />
                        <Typography variant="h6" className="text-gray-700 dark:text-gray-200">
                            Loading reviewers...
                        </Typography>
                        <Typography variant="body2" className="text-gray-500 dark:text-gray-400 mt-2">
                            Please wait while we load the reviewers
                        </Typography>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="min-w-full inline-block align-middle">
                        <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="text-center whitespace-nowrap">Name</TableHead>
                                <TableHead className="text-center whitespace-nowrap">Email</TableHead>
                                <TableHead className="text-center whitespace-nowrap">Role</TableHead>
                                <TableHead className="text-center whitespace-nowrap">Team</TableHead>
                                <TableHead className="text-center whitespace-nowrap">Last Login</TableHead>
                                <TableHead className="text-center whitespace-nowrap">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredUsers.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell className="text-center">{`${user.firstName} ${user.middleName} ${user.lastName}`}</TableCell>
                                <TableCell className="text-center">{user.email}</TableCell>
                                <TableCell className="text-center">{user.role}</TableCell>
                                <TableCell className="text-center">{user.team}</TableCell>
                                <TableCell className="text-center">{user.lastLogin}</TableCell>
                                <TableCell className="text-center">
                                <TooltipProvider>
                                    <div className="flex justify-center gap-2">
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleEditUser(user)}
                                        >
                                            <Edit className="h-4 w-4" />
                                            <span className="sr-only">Edit user</span>
                                        </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                        <p>Edit user</p>
                                        </TooltipContent>
                                    </Tooltip>

                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                        <Dialog>
                                            <DialogTrigger asChild>
                                            <Button variant="ghost" size="icon">
                                                <Trash className="h-4 w-4" />
                                                <span className="sr-only">Delete user</span>
                                            </Button>
                                            </DialogTrigger>
                                            <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle>Confirm Deletion</DialogTitle>
                                                <DialogDescription>
                                                Are you sure you want to delete this user? This action cannot be undone.
                                                </DialogDescription>
                                            </DialogHeader>
                                            <DialogFooter>
                                                <Button variant="outline" onClick={() => {}}>Cancel</Button>
                                                <Button variant="destructive" onClick={() => handleDeleteUser(user.id)}>Delete</Button>
                                            </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                        <p>Delete user</p>
                                        </TooltipContent>
                                    </Tooltip>

                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                        <Dialog>
                                            <DialogTrigger asChild>
                                            <Button variant="ghost" size="icon">
                                                <Key className="h-4 w-4" />
                                                <span className="sr-only">Update login</span>
                                            </Button>
                                            </DialogTrigger>
                                            <DialogContent>
                                            <DialogHeader>
                                                <DialogTitle>Update User Login</DialogTitle>
                                                <DialogDescription>
                                                This will send a password reset email to the user.
                                                </DialogDescription>
                                            </DialogHeader>
                                            <DialogFooter>
                                                <Button variant="outline" onClick={() => {}}>Cancel</Button>
                                                <Button onClick={() => handleUpdateLogin(user.id)}>Send Reset Email</Button>
                                            </DialogFooter>
                                            </DialogContent>
                                        </Dialog>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                        <p>Update login</p>
                                        </TooltipContent>
                                    </Tooltip>

                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleAssignCases(user.id)}
                                        >
                                            <Link2 className="h-4 w-4" />
                                            <span className="sr-only">Assign cases</span>
                                        </Button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                        <p>Assign cases from queue</p>
                                        </TooltipContent>
                                    </Tooltip>
                                    </div>
                                </TooltipProvider>
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </div>
                    <div className="absolute bottom-0 w-full h-1 bg-gradient-to-r from-transparent via-gray-200 to-transparent opacity-0 transition-opacity duration-300 hover:opacity-100" />

                </>
                )}
            </div>

            {/* Edit User Dialog */}
            <Dialog open={!!editingUser} onOpenChange={() => setEditingUser(null)}>
                <DialogContent>
                <DialogHeader>
                    <DialogTitle>Edit User</DialogTitle>
                </DialogHeader>
                {editingUser && (
                    <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="firstName" className="text-right">
                        First Name
                        </Label>
                        <Input
                        id="firstName"
                        value={editingUser.firstName}
                        onChange={(e) =>
                            setEditingUser({ ...editingUser, firstName: e.target.value })
                        }
                        className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="middleName" className="text-right">
                        Middle Name
                        </Label>
                        <Input
                        id="middleName"
                        value={editingUser.middleName}
                        onChange={(e) =>
                            setEditingUser({ ...editingUser, middleName: e.target.value })
                        }
                        className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="lastName" className="text-right">
                        Last Name
                        </Label>
                        <Input
                        id="lastName"
                        value={editingUser.lastName}
                        onChange={(e) =>
                            setEditingUser({ ...editingUser, lastName: e.target.value })
                        }
                        className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="email" className="text-right">
                        Email
                        </Label>
                        <Input
                        id="email"
                        value={editingUser.email}
                        onChange={(e) =>
                            setEditingUser({ ...editingUser, email: e.target.value })
                        }
                        className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4  items-center gap-4">
                        <Label htmlFor="role" className="text-right">
                        Role
                        </Label>
                        <Input
                        id="role"
                        value={editingUser.role}
                        onChange={(e) =>
                            setEditingUser({ ...editingUser, role: e.target.value })
                        }
                        className="col-span-3"
                        />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="team" className="text-right">
                        Team
                        </Label>
                        <Input
                        id="team"
                        value={editingUser.team}
                        onChange={(e) =>
                            setEditingUser({ ...editingUser, team: e.target.value })
                        }
                        className="col-span-3"
                        />
                    </div>
                    </div>
                )}
                <DialogFooter>
                    <Button variant="outline" onClick={() => setEditingUser(null)}>
                    Cancel
                    </Button>
                    <Button onClick={handleUpdateUser}>Save Changes</Button>
                </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Assign Cases Dialog */}
            <Dialog open={!!assigningCases} onOpenChange={() => setAssigningCases(null)}>
                <DialogContent className="max-w-4xl">
                <DialogHeader>
                    <DialogTitle>Assign Cases</DialogTitle>
                    <DialogDescription>
                    Assign cases from the queue to {users.find(u => u.id === assigningCases)?.firstName} {users.find(u => u.id === assigningCases)?.lastName}
                    </DialogDescription>
                </DialogHeader>
                <div className="max-h-[60vh] overflow-y-auto">
                    <Table>
                    <TableHeader>
                        <TableRow>
                        <TableHead className="w-[50px]">Select</TableHead>
                        <TableHead>Case ID</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Receipt Date</TableHead>
                        <TableHead>Time Left</TableHead>
                        <TableHead>CPT Code</TableHead>
                        <TableHead>Out/Inpatient</TableHead>
                        <TableHead>Service Type</TableHead>
                        <TableHead>Units</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {cases.filter(c => !c.reviewerId).map((case_) => (
                        <TableRow key={case_.id}>
                            <TableCell>
                            <Checkbox
                                checked={selectedCases.includes(case_.id)}
                                onCheckedChange={(checked) => 
                                handleCaseSelection(case_.id, checked as boolean)
                                }
                            />
                            </TableCell>
                            <TableCell>{case_.id}</TableCell>
                            <TableCell>{case_.status}</TableCell>
                            <TableCell>{case_.receiptDate}</TableCell>
                            <TableCell>{case_.timeLeftToDecision}</TableCell>
                            <TableCell>{case_.cptCode}</TableCell>
                            <TableCell>{case_.outInpatient}</TableCell>
                            <TableCell>{case_.serviceType}</TableCell>
                            <TableCell>{`${case_.unitsRequested} ${case_.unitType}`}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </div>
                <DialogFooter>
                    <Button variant="outline" onClick={() => setAssigningCases(null)}>
                    Cancel
                    </Button>
                    <Button 
                    onClick={handleCaseAssignment}
                    disabled={selectedCases.length === 0}
                    >
                    Assign Selected Cases
                    </Button>
                </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Add New User Dialog */}
            <Dialog open={isAddingUser} onOpenChange={setIsAddingUser}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            Add New User
                        </DialogTitle>
                        
                        <DialogDescription>
                            Enter the details for the new user.
                        </DialogDescription>
                    </DialogHeader>

                    <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="firstName" className="text-right">
                            First Name
                        </Label>
                        <Input
                            id="firstName"
                            value={newUser.firstName}
                            onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                            className="col-span-3"
                            required
                        />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="middleName" className="text-right">
                            Middle Name
                        </Label>
                        <Input
                            id="middleName"
                            value={newUser.middleName}
                            onChange={(e) => setNewUser({ ...newUser, middleName: e.target.value })}
                            className="col-span-3"
                        />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="lastName" className="text-right">
                            Last Name
                        </Label>
                        <Input
                            id="lastName"
                            value={newUser.lastName}
                            onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                            className="col-span-3"
                            required
                        />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="email" className="text-right">
                                Email
                            </Label>
                            <div className="col-span-3">
                                <Input
                                    id="email"
                                    type="email"
                                    value={newUser.email}
                                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                    required
                                />
                                {newUser.email && !validateEmail(newUser.email) && (
                                    <p className="text-sm text-red-500">Please enter a valid email address</p>
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="role" className="text-right">
                                Role
                            </Label>
                            <div className="col-span-3">
                                <Select
                                    value={newUser.role}
                                    onValueChange={(value) => setNewUser({ ...newUser, role: value as UserRole })}
                                    required
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Role" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {Object.values(UserRole).map((role) => (
                                            <SelectItem key={role} value={role}>
                                                {role.charAt(0).toUpperCase() + role.slice(1)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="team" className="text-right">
                                Team
                            </Label>
                            <div className="col-span-3">
                                <Select 
                                    value={newUser.team}
                                    onValueChange={(value) => setNewUser({ ...newUser, team: value as UserTeam })}
                                    required
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Team" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {Object.values(UserTeam).map((team) => (
                                            <SelectItem key={team} value={team}>
                                                {team.charAt(0).toUpperCase() + team.slice(1)}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                <DialogFooter>
                    <Button variant="outline" onClick={() => setIsAddingUser(false)}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleAddUser} 
                        disabled={!isAddUserFormValid()}
                    >
                        Add User
                    </Button>
                </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}