import { AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoUserSession, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import { UserRole } from '../contexts/authContext'

const userPoolId = process.env.REACT_APP_USER_POOL_ID || 'us-east-1_MokZCp6HR'
const clientId = process.env.REACT_APP_CLIENT_ID || 'gdkqo94mkmo4g4ml39ks99ijq'


const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
}

const userPool: CognitoUserPool = new CognitoUserPool(poolData)

let currentUser: any = userPool.getCurrentUser()

export function getCurrentUser() {
  currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    throw new Error('No current user found');
  }
  return currentUser;
}

function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export async function getSession(currentUser: CognitoUser) {

  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signUpUserWithEmail(username: string, email: string, password: string, role: UserRole, names: { givenName: string, familyName: string, middleName?: string }) {
  console.log("cognito signing up with role", role)
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: 'custom:role',
        Value: role.toString(),
      }),
      new CognitoUserAttribute({
        Name: 'given_name',
        Value: names.givenName,
      }),
      new CognitoUserAttribute({
        Name: 'family_name',
        Value: names.familyName,
      }),
    ]

    // Only add middle name if it exists
    if (names.middleName?.trim()) {
      attributeList.push(
        new CognitoUserAttribute({
          Name: 'middle_name',
          Value: names.middleName,
        })
      );
    }
    userPool.signUp(username, password, attributeList, [], function (err, res) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function verifyCode(username: string, code: string) {
  console.log("cognito verifying code", code)
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)

    currentUser = getCognitoUser(username)

    const cognitoUser = currentUser

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: (res: any) => {
        // console.log("cognito authentication success", res)
        resolve({status: 'SUCCESS', session: res})
      },
      onFailure: (err: any) => {
        console.log("cognito authentication failure", err)
        reject({status: 'FAILURE', error: err})
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        const cogntioSession = (cognitoUser as any).Session;
        resolve({
          status: 'NEW_PASSWORD_REQUIRED',
          userAttributes,
          requiredAttributes,
          session: cogntioSession
        });
      }
    })
  })
}

export async function completeNewPasswordChallenge(challengeSession: any, newPassword: string) {
  
  const userData = {
    Username: challengeSession.userAttributes.email,
    Pool: userPool 
  };
  currentUser = new CognitoUser(userData);

  if (challengeSession.session) {
    currentUser.Session = challengeSession.session;
  }
    
  return new Promise(function (resolve, reject) {
    currentUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: function(result: any) {
          resolve({status: 'SUCCESS', session: result})
        },
        onFailure: function(err: any) {
          console.error("Failed to complete password challenge:", err);
          reject({status: 'FAILURE', error: err})
        }
      }
    )
  })
}

export function signOut() {
  if (currentUser) {
    currentUser.signOut()
  }
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err)
      } else {
        const roleAttr = attributes.find((attr: any) => attr.Name === 'custom:role');
        if (roleAttr) {
          roleAttr.Value = roleAttr.Value as UserRole;
        }
        resolve(attributes)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList = []
    const res = new CognitoUserAttribute(attribute)
    attributeList.push(res)

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res)
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  }).catch((err) => {
    throw err
  })
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username)

    if (!cognitoUser) {
      reject(`could not find ${username}`)
      return
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated')
      },
      onFailure: function (err) {
        reject(err)
      },
    })
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err)
      } else {
        resolve(res)
      }
    })
  })
}
