import React from 'react';
import { useHistory } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Container, 
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import { styled } from '@mui/system';
import { Gradient, Person, HealthAndSafety } from '@mui/icons-material';

import logoImage from './../logo.png';

const GradientBackground = styled('div')(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const LogoImage = styled('img')({
  maxWidth: '300px',
  height: 'auto',
  marginBottom: '2rem',
});

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '3.5rem', // Slightly reduced from 4rem to fit better in a row
  color: theme.palette.primary.contrastText,
  marginBottom: '0.5rem',
}));

const FeatureItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0.5rem', // Added horizontal padding for spacing between items
});

const ButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1.2rem',
  padding: '12px 24px',
  margin: '0 10px',
  [theme.breakpoints.down('sm')]: {
    margin: '10px 0',
  },
}));

const Landing: React.FC = () => {
  const history = useHistory();

  const signIn = () => {
    history.push('/signin');
  };

  const signUp = () => {
    history.push('/signup');
  };

  return (
    <GradientBackground>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid size={{ xs: 12, md: 4 }}>
            <Box textAlign="center">
              <Typography variant="h4" color="primary.contrastText" gutterBottom>
                AI-Powered Healthcare Management
              </Typography>
              <Box mt={4}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid size={{ md: 4}}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Gradient fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Smart Analytics
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ md: 4}}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Person fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        AI Co-pilot
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ md: 4}}>
                    <FeatureItem>
                      <FeatureIcon>
                        <HealthAndSafety fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Improved Care
                      </Typography>
                    </FeatureItem>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }} textAlign="center">
            <Typography variant="h1"color="primary.contrastText" gutterBottom>
              Welcome to Electra
            </Typography>
            <Typography variant="h6" color="black" gutterBottom>
              By
            </Typography>
            <LogoImage src={logoImage} alt="Electra Logo" sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
            <Typography variant="h5" color="primary.contrastText" paragraph>
              Empowering UM nurses with AI-driven insights
            </Typography>
            <ButtonContainer mt={4}>
              <Typography 
                variant="h4" 
                color="primary.contrastText" 
                gutterBottom 
                fontWeight="bold"
              >
                Get Started Today
              </Typography>
              <Box>
                <StyledButton 
                  onClick={signIn} 
                  variant="contained" 
                  color="secondary" 
                  size="large" 
                >
                  Sign In
                </StyledButton>
                <StyledButton 
                  onClick={signUp} 
                  variant="outlined" 
                  color="secondary" 
                  size="large"
                  sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
                >
                  Sign Up
                </StyledButton>
              </Box>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Container>
    </GradientBackground>
  );
};

export default Landing;