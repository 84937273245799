import { Gradient, HealthAndSafety, Person } from '@mui/icons-material';
import {
  Box,
  Container,
  Paper,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import React from 'react';

import logoImage from '../../logo.png';

const GradientBackground = styled('div')(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const LogoImage = styled('img')({
  width: '200px',
  height: 'auto',
  marginBottom: '2rem',
});

const FeatureIcon = styled(Box)(({ theme }) => ({
  fontSize: '3.5rem',
  color: theme.palette.primary.contrastText,
  marginBottom: '0.5rem',
}));

const FeatureItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 0.5rem',
});

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title }) => {
  return (
    <GradientBackground>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid size={{ xs: 12, md: 4 }} >
            <Box textAlign="center">
              <Typography variant="h4" color="primary.contrastText" gutterBottom>
                AI-Powered Healthcare Management
              </Typography>
              <Box mt={4}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Gradient fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Smart Analytics
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <Person fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        AI Co-pilot
                      </Typography>
                    </FeatureItem>
                  </Grid>
                  <Grid size={{ xs: 4 }}>
                    <FeatureItem>
                      <FeatureIcon>
                        <HealthAndSafety fontSize="inherit" />
                      </FeatureIcon>
                      <Typography variant="body1" color="primary.contrastText">
                        Improved Care
                      </Typography>
                    </FeatureItem>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <Paper style={{ padding: 32, backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <LogoImage src={logoImage} alt="Electra Logo" />
                <Typography variant="h3" gutterBottom>{title}</Typography>
                {children}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </GradientBackground>
  );
};

export default AuthLayout;