import React, { useState, useEffect, useContext } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  CircularProgress,
  Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { getCaseCreatedTime, getHistoricalViews, getMedicalRecord, getReviewerProcessStartTime } from '../libs/backend';
import { AuthContext } from '../contexts/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import { getSampleJson, getSamplePdf } from '../libs/backend';


interface HistoricalViewItem {
  case_id: string;
  created_time: string;
  reviewer_decision: string;
  reviewer_rationale: string;
  number_of_pages: number;
  mcg_guideline: string;
  processing_time: number;
  medical_record_filename: string;
  response: any;
  matches_count: number; // Added matches_count
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTop: '1px solid white', // Add white top border
    },
    '&.MuiTableCell-body': {
      fontSize: 14,
    },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  cursor: 'pointer',
}));



const HistoricalView: React.FC = () => {
  const [historicalData, setHistoricalData] = useState<HistoricalViewItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { sessionInfo } = useContext(AuthContext);
  const history = useHistory();
  const newSubmission = useLocation<{ newSubmission?: boolean }>();

  const fetchHistoricalViews = async () => {
    if (sessionInfo && sessionInfo.accessToken) {
      try {
        const responseDB = await getHistoricalViews(sessionInfo.accessToken);
        if (responseDB.data) {
          setHistoricalData(responseDB.data);
        }
      } catch (error) {
        setError('No Cases to show');
        console.error('Error fetching historical views:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDemoCase = async (item: HistoricalViewItem) => {
    try {

      const randomNumber = Math.floor(Math.random() * 3);
      const [jsonResponse, pdfResponse] = await Promise.all([
        getSampleJson(randomNumber),
        getSamplePdf(randomNumber)
      ]);

      history.push('/results', { 
        json_data: jsonResponse.data.json_data, 
        pdf_data: pdfResponse.data,
        coming_from: "demo"
      });
    } catch (error) {
      console.error('Error fetching demo data:', error);
      setError('Failed to process demo data. Please try again.');
    }
  }

  useEffect(() => {
    fetchHistoricalViews();
  }, [sessionInfo]);

  useEffect(() => {
    if (newSubmission.state?.newSubmission) {
      fetchHistoricalViews();

      history.replace({ ...newSubmission, state: {} });
    }
  }, [newSubmission.state]);

  const handleRowClick = async (item: HistoricalViewItem) => {

    if (item.medical_record_filename === "demo") {
      handleDemoCase(item);
      return;
    }

    try {
      const pdfResponse = await getMedicalRecord(item.case_id, item.medical_record_filename, sessionInfo?.accessToken || '');

      let reviewerProcessStartTime = item.processing_time;
      if (item.reviewer_decision === null) { 
        const responseDB = await getCaseCreatedTime(item.case_id, sessionInfo?.accessToken || '');
        const reviwerTime = responseDB.data.result;

        const startTime = new Date(reviwerTime).getTime();
        const currentTime = new Date().getTime();
        let elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
        reviewerProcessStartTime = elapsedSeconds;
      }
      history.push('/results', {
        json_data: item.response,
        pdf_data: {
          blob: pdfResponse.data,
          width: item.response.width, 
          height: item.response.height, 
        },
        reviewer_process_start_time: reviewerProcessStartTime,
        coming_from: "historical_views",
        reviewer_decision: item.reviewer_decision,
        reviewer_rationale: item.reviewer_rationale,
      });
    } catch (error) {
      console.error('Error fetching medical record:', error);
      setError('Failed to fetch medical record. Please try again.');
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  return (
    <Box sx={{ mb: 10 }}> {/* Add margin bottom to prevent footer overlap */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Date Processed</StyledTableCell>
              <StyledTableCell>Guideline selected</StyledTableCell>
              <StyledTableCell>Decision</StyledTableCell>
              <StyledTableCell>Attachment</StyledTableCell>
              <StyledTableCell>Pages</StyledTableCell>
              <StyledTableCell>Matches</StyledTableCell>
              <StyledTableCell>Processing Time</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historicalData.map((item) => (
              <StyledTableRow 
                key={item.case_id} 
                onClick={() => handleRowClick(item)}
              >
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                    {new Date(item.created_time).toLocaleString()}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{item.mcg_guideline}</StyledTableCell>
                <StyledTableCell>{item.reviewer_decision}</StyledTableCell>
                <StyledTableCell>{item.medical_record_filename}</StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    <DescriptionIcon fontSize="small" sx={{ mr: 1 }} />
                    {item.number_of_pages}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    <FindInPageIcon fontSize="small" sx={{ mr: 1 }} />
                    {item.matches_count}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{formatTime(item.processing_time)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HistoricalView;