import React, { useState } from "react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { CheckCircle, ChevronRight, Edit, Eye, XCircle, HelpCircle, SearchX } from "lucide-react"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import ReactMarkdown from 'react-markdown'
import { Typography } from "@mui/material"

interface FinalOutcomeProps {
    rationaleText: string;
    rationaleLabel: string;
    handleOpenEditModal: () => void;
    handleOpenViewModal: () => void;
    editModalDisabled: boolean;
    decisionValue: string;
    handleDecisionDropdownChange: (value: string) => void;
    dropdownBackgroundColor: string;
    dropdownDisabled: boolean;
    handleSubmitCase: () => void;
    editSubmitDisabled: boolean;

}

const markdownStyles = {
    ul: {
        listStyleType: 'none',
        padding: 0,
        margin: 0
    },
    li: {
        marginBottom: '1rem'
    },
    p: {
        margin: '0.5rem 0'
    },
    strong: {
        color: '#054d5e'
    }
}
function MarkdownContent({ text }: { text: string }) {
    return (
        <ReactMarkdown
            components={{
            ul: ({ children }) => (
                <ul style={markdownStyles.ul}>{children}</ul>
            ),
            li: ({ children }) => (
                <li style={markdownStyles.li}>{children}</li>
            ),
            p: ({ children }) => (
                <p style={markdownStyles.p}>{children}</p>
            ),
            strong: ({ children }) => (
                <strong style={markdownStyles.strong}>{children}</strong>
            ),
            }}
        >
            {text}
        </ReactMarkdown>
    )
}

export default function Component({ rationaleText, rationaleLabel, handleOpenEditModal, handleOpenViewModal, editModalDisabled, decisionValue, handleDecisionDropdownChange, dropdownBackgroundColor, dropdownDisabled, handleSubmitCase, editSubmitDisabled }: FinalOutcomeProps) {

    return (
        // <Card className="w-full max-w-4xl">
        <Card className="w-full max-w-9xl mx-auto mb-1">
            <CardHeader>
                {/* <CardTitle className="text-xl font-semibold">Final Outcome</CardTitle> */}
                <Typography variant="h6" color="primary">
                    Final Outcome
                </Typography>
            </CardHeader>
            <CardContent className="space-y-2">
                <div className="space-y-2">
                    <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium text-muted-foreground">{rationaleLabel}</h3>
                    <div className="flex gap-2">
                        <Button variant="outline" size="sm" onClick={handleOpenEditModal} disabled={editModalDisabled}>
                            <Edit className="mr-2 h-4 w-4" />
                            Edit
                        </Button>
                        <Button variant="outline" size="sm" onClick={handleOpenViewModal}>
                            <Eye className="mr-2 h-4 w-4" />
                            View
                        </Button>
                    </div>
                </div>
                <div className="rounded-lg border p-4 max-h-[85px] overflow-y-auto">
                    <MarkdownContent text={rationaleText} />
                </div>
                </div>
                <div className="space-y-2">
                    <h3 className="text-sm font-medium text-muted-foreground">Decision</h3>
                    <div className="flex gap-4 items-center">
                        <div className="flex-1">
                            <Select value={decisionValue} onValueChange={handleDecisionDropdownChange}>
                                <SelectTrigger style={{ backgroundColor: dropdownBackgroundColor }} className="w-full" disabled={dropdownDisabled}>
                                    <SelectValue placeholder="Select a decision">
                                        {decisionValue && (
                                            <div className="flex items-center gap-2">
                                                {decisionValue === 'Approved' && <CheckCircle className="h-4 w-4 text-black" />}
                                                {decisionValue === 'Denied' && <XCircle className="h-4 w-4 text-black" />}
                                                {decisionValue === 'Inconclusive' && <HelpCircle className="h-4 w-4 text-black" />}
                                                {decisionValue === 'No Info Found' && <SearchX className="h-4 w-4 text-black" />}
                                                {decisionValue}
                                            </div>
                                        )}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                <SelectItem value="Approved">
                                        <div className="flex items-center gap-2">
                                            <CheckCircle className="h-4 w-4" style={{ color: '#388e3c' }} />
                                            Approved
                                        </div>
                                    </SelectItem>
                                    <SelectItem value="Denied">
                                        <div className="flex items-center gap-2">
                                            <XCircle className="h-4 w-4" style={{ color: '#d32f2f' }} />
                                            Denied
                                        </div>
                                    </SelectItem>
                                    <SelectItem value="Inconclusive">
                                        <div className="flex items-center gap-2">
                                            <HelpCircle className="h-4 w-4" style={{ color: '#f4b400' }} />
                                            Inconclusive
                                        </div>
                                    </SelectItem>
                                    <SelectItem value="No Info Found">
                                        <div className="flex items-center gap-2">
                                            <SearchX className="h-4 w-4" style={{ color: 'grey' }} />
                                            No Info Found
                                        </div>
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <Button onClick={handleSubmitCase} disabled={editSubmitDisabled} variant="default"
                            className="bg-[#054d5e] hover:bg-[#054d5e] text-white whitespace-nowrap">
                            Submit Case
                            <ChevronRight className="ml-2 h-4 w-4" />
                        </Button>
                    </div>
                </div>
            </CardContent>
            {/* <CardFooter className="flex justify-end border-t bg-muted/50 p-4">
                
            </CardFooter> */}
        </Card>
    )
}